<div class="container">
    <section class="toolbar">
        <div class="search">
            <input
                class="search-input"
                type="text"
                placeholder="Search users..."
                [(ngModel)]="searchQuery"
                (keyup.enter)="onSearch()"
            />
            <mat-icon class="search-btn" (click)="onSearch()">search</mat-icon>
        </div>
        <button mat-raised-button class="save-btn" (click)="goToAddUser()" *ngIf="hasPermission">
            <mat-icon>add</mat-icon>
            ADD USER
        </button>
    </section>

    <section class="admin-list">
        <app-data-grid
            [tableColumns]="columns"
            [actions]="actions"
            [config]="config"
            (pageChange)="onPageChange($event)"
            (toggleChange)="onToggleChange($event)"
        ></app-data-grid>
    </section>
</div>
