import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function indianBankAccountValidator(minLength: number = 9, maxLength: number = 18): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const accountNumber = control.value;

        if (!accountNumber) {
            return null; // Return null if the field is empty, allowing required validation to handle it
        }

        // Check if the account number is defined and contains only digits
        const isNumeric = /^\d+$/.test(accountNumber);

        // Check if the account number length is within the specified range
        const isValidLength = accountNumber.length >= minLength && accountNumber.length <= maxLength;

        // Return null if valid, or an error object if invalid
        return isNumeric && isValidLength ? null : { invalidAccountNumber: true };
    };
}
