<div class="container">
    <h1>Terms & Conditions</h1>
    <hr class="line" />
    <br />
    <div class="raw block1">
        <p>
            This document details all terms and conditions that apply to an individual or entity buying the products
            from UDM/FunFigz. In the context of this agreement, <b>“you”</b> refers to the applicant and <b>“we”</b> or
            <b>“us”</b> refers to UDM Global Solution Private Limited (“UDM”) which is the licensed owner of the brand
            FunFigz and its associated website(<b class="site">www.funfigz.com or www.udmglobal.com</b>), it’s mobile
            website and mobile application (the “Site”). By visiting this Site or by accessing or subscribing to the
            services provided by KMAway you are accepting and consenting to these terms and conditions. Please read them
            carefully. In addition, when you use any current or future UDM’s service or business, you also will be
            subject to the guidelines, terms and conditions applicable to such service or business.
        </p>
        <p>
            These terms and conditions constitute an electronic record within the meaning of the applicable law. This
            being an electronic record generated by a computer system does not require any physical or digital
            signatures.
        </p>
    </div>

    <div class="raw block2">
        <h2>Privacy Policy</h2>
        <p>
            Please review our Privacy Policy, which also governs your visit to this Site, to understand our practices.
        </p>
    </div>

    <div>
        <h2>Communications Electronically</h2>
        <p>
            When you visit the Site or send emails to us, you are communicating with us electronically and you consent
            to receive communications from us electronically. You agree that all agreements, notices, disclosures and
            other communications that we provide to you electronically satisfy any legal requirement that such
            communications be in writing.
        </p>
    </div>

    <div class="raw block3">
        <h2>Copyright</h2>
        <p>
            The compilation of all the contents included on this Site, such as text, graphics, logos, button icons,
            images, audio clips, digital downloads, data compilations, and software, is the exclusive property of UDM
            and protected by copyright laws of India.
        </p>
    </div>

    <div class="raw block4">
        <h2>Trademark(s)</h2>
        <p>
            UDM, the UDM logo, the Site, the Site logo and other marks indicated on our Site are trademarks of UDM.
            Other UDM graphics, logos, page headers, button icons, scripts, and service names are trademarks of UDM. All
            other trademarks not owned by UDM or the Site that appear on this site are the property of their respective
            owners, who may or may not be affiliated with, connected to, or sponsored by UDM.
        </p>
    </div>

    <div class="raw block5">
        <h2>License and site access</h2>
        <p>
            UDM grants you a limited license to access and make personal use of this Site and not to download (other
            than page caching) or modify it, or any portion of it, except with express written consent of UDM, as may be
            applicable. This license does not include any resale or commercial use of this site or its contents; any
            derivative use of this site or its contents; or any use of data mining, robots, or similar data gathering
            and extraction tools. This site or any portion of this site (including but not limited to any copyrighted
            material, trademarks, or other proprietary information) may not be reproduced, duplicated, copied, sold,
            resold, visited, or otherwise exploited for any commercial purpose without express written consent of UDM
            and / or its affiliates, as may be applicable. You may not frame or utilize framing techniques to enclose
            any trademark, logo, or other proprietary information (including images, text, page layout, or form) of UDM
            without their express written consent. You may not use any meta tags or any other “hidden text” utilizing
            UDM’s name or trademarks without their express written consent. Any unauthorized use terminates the
            permission or license granted by UDM. You may not use any logo or other proprietary graphic or trademark of
            UDM or its affiliates as part of the link without their express written permission.
        </p>
    </div>

    <div class="raw block6">
        <h2>Account and Registration Obligation</h2>
        <p>
            You as a shopper have to login, give consent to your current location for accessing the items and then
            provide your Address and Phone number for placing an order on UDM/FunFigz.
        </p>
    </div>

    <div class="ra block7">
        <h2>Your activity on the UDM/FunFigz site</h2>
        <p>
            Site is not intended for use by children. If you are a minor i.e. under the age of 18 years, you may use the
            Site only with involvement of a parent or guardian. UDM reserves the right to refuse service, terminate
            accounts, or remove or edit content in their sole discretion
        </p>
        <p>
            You must not use the Site in any way that causes, or is likely to cause, the website or access to it to be
            interrupted, damaged or impaired in any way. You understand that you, and not UDM, are responsible for all
            electronic communications and content sent from your computer to us and you must use the Site for lawful
            purposes only and only in accordance with the applicable law of the country.
        </p>
        <p>You must not use the Site for any of the following:</p>

        <p>
            for fraudulent purposes, or in connection with a criminal offence or other unlawful activity; to send, use
            or reuse any material that is illegal, offensive, (including but not limited to material that is sexually
            explicit or which promotes racism, bigotry, hatred or physical harm), abusive, harassing, misleading,
            indecent, defamatory, disparaging, obscene or menacing; or in breach of copyright, trademark,
            confidentiality, privacy or any other proprietary information or right; or is otherwise injurious to third
            parties; or objectionable or otherwise unlawful in any manner whatsoever; or which consists of or contains
            software viruses, political campaigning, commercial solicitation, chain letters, mass mailings or any
            “spam”; to cause annoyance, inconvenience or needless anxiety;
        </p>
    </div>
    <div class="raw block8">
        <h2>Pricing</h2>
        <p>
            All the products listed on the Site will be sold at Selling Price which is always equal to or lesser than
            the MRP. The prices mentioned at the time of ordering will be the prices charged on the date of the
            delivery. Although prices of most of the products do not fluctuate on a daily basis but some of the
            commodities prices do change on a daily basis. Self-Pickup Orders will be free of Delivery charge if any.
        </p>
    </div>
    <div class="raw block9">
        <h2>Cancellation by Seller/Shopper and Refunds</h2>
        <p>You as a shopper can cancel item(s) or entire order before the order is on the transit for delivery.</p>
        <p>
            <b>Your item(s) or order can also be cancelled by the seller under the following circumstances</b>
        </p>
        <ol>
            <li>The package is damaged in transit.</li>
            <li>Delivery is refused by the recipient.</li>
            <li>Address given is incorrect.</li>
            <li>No recipient was available during delivery.</li>
            <li>Sudden out of stock of the items.</li>
        </ol>
        <p>
            In either case, you as a shopper will get a 100% refund to your registered bank account for the cancelled
            item(s) or order if you have already made the payment online within 2-5 working days.
        </p>
    </div>

    <div class="raw block10">
        <h2>Reviews and Rating</h2>
        <p>
            A product's average rating will appear alongside their name in the list. If you're tempted to leave a
            critical rating, make sure that you've given the seller every chance to resolve the issue first.
        </p>
        <p>
            While you are encouraged to share either positive or critical feedback, yet we have the right to remove
            feedback that is not directly related to the buying experience or violates one of our guidelines. Your
            comments should not include the following:
        </p>

        <p>
            <b>Promotional content:</b> This includes anything of a promotional nature such as comments about or links
            to other merchants or websites.
        </p>
        <p><b>Objectionable Content:</b> Please use helpful and appropriate language.</p>
        <p>
            <b>Personal information or attack:</b> You should not include personal information and should not include
            personal attack.
        </p>
    </div>

    <div class="raw block11">
        <h2>Copyright Complaints</h2>
        <p>
            UDM respects the intellectual property of others and ensures that your intellectual property is not
            infringed.
        </p>
    </div>

    <div class="raw block12">
        <h2>Services Description</h2>
        <p>
            UDM attempts to be as accurate as possible. However, UDM does not warrant that descriptions of or other
            content of this site is accurate, complete, reliable, current, or error-free. Also, your access to the Site
            may also be occasionally suspended or restricted to allow for repairs, maintenance, or the introduction of
            new facilities or at any time without prior notice. We will attempt to limit the frequency and duration of
            any such suspension or restriction. UDM is not responsible for the availability of any third-party sites or
            resources.
        </p>
    </div>

    <div class="raw block13">
        <h2>Disclaimer of warranties and limitation of liabilities</h2>
        <p>
            This site is provided by UDM on an “as is” and “as available” basis. UDM makes no representations or
            warranties of any kind, express or implied, as to the operation of this site or the information, content,
            materials, or products included on this site. you expressly agree that your use of this site is at your sole
            risk. UDM reserves the right to withdraw or delete any information from this site at any time in its
            discretion.
        </p>
        <p>
            To the full extent permissible by applicable law, UDM disclaims all warranties, express or implied,
            including, but not limited to, implied warranties of merchantability and fitness for a particular purpose.
            UDM does not warrant that this site, its servers, or e-mail sent from UDM are free of viruses or other
            harmful components. UDM will not be liable for any damages of any kind arising from the use of this site,
            including, but not limited to direct, indirect, incidental, punitive, and consequential damages
        </p>
    </div>

    <div class="raw block14">
        <h2>Applicable Law</h2>
        <p>
            By visiting this Site, you agree that the laws of India will govern these Terms and Conditions and any
            dispute of any sort that might arise between you and UDM.
        </p>
    </div>

    <div class="raw block15">
        <h2>Disputes</h2>
        <p>
            Any dispute relating in any way to your visit to this Site shall be submitted to the exclusive jurisdiction
            of the courts at Bangalore, India.
        </p>
    </div>

    <div class="raw block16">
        <h2>Site Policies, Modification and Severability</h2>
        <p>
            Please review our other policies on this Site. These policies also govern your visit to the Site. We reserve
            the right to make changes to our Site, policies, and these Terms and Conditions at any time without any
            notice. If any of these conditions shall be deemed invalid, void, or for any reason unenforceable, that
            condition shall be deemed severable and shall not affect the validity and enforceability of any remaining
            condition.
        </p>
    </div>
    <div class="raw block17">
        <h2>Delivery Policy</h2>
        <p>We will deliver your item within 3-7 days depending on your pincode.</p>
    </div>

    <div class="raw block18">
        <h2>Return Policy</h2>
        <p>Items can be returned within 7 days after delivery.</p>
    </div>

    <div class="raw block19">
        <h2>Our Address</h2>
        <p>This Site is owned and operated by UDM Global Solution Private Limited</p>
        <p># 55/3 & 55/4,</p>
        <p>8th Floor, Silpitha tech Park</p>
        <p>Devarabisanahalli Bellandur</p>
        <p>Bangalore – 560103, Karnataka, INDIA</p>
        <p>Phone: +9173386177665</p>
        <p class="email">{{ email }}</p>
    </div>
</div>
