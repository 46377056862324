import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function ifscValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const ifscCode = control.value;

        if (!ifscCode) {
            return null; // Return null if the field is empty, allowing required validation to handle it
        }

        // Check if the length is 11
        const isValidLength = ifscCode.length === 11;

        // Check the format using a regular expression
        const isValidFormat = isValidLength && /^[A-Z]{4}0[A-Z0-9]{6}$/.test(ifscCode);

        return isValidFormat ? null : { invalidIfsc: true };
    };
}
