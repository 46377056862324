export const appConfig = {
    webUrlBasePath: "http://localhost:4200",
    apiUrlBasePath: "http://localhost:3000/api",
    awsUrlBasePath: "https://funfigz-assets-public.s3.ap-south-1.amazonaws.com",
    productLogo: "https://funfigz-assets-public.s3.ap-south-1.amazonaws.com/product-logo.png",
    payment: {
        mode: "phonepe",
        paytmConfig: {
            flow: "DEFAULT",
            merchant: {
                mid: "tkwcnC23205164649042",
                name: "UDM Global Test",
                logo: "https://material.angular.io/assets/img/examples/shiba2.jpg",
                callbackUrl: "/payment/callback",
                redirect: false,
            },
            style: {},
            jsFile: "",
            mapClientMessage: {},
            labels: {},
            payMode: {
                labels: {},
                filter: {
                    exclude: [],
                },
                order: ["NB", "CARD", "LOGIN"],
            },
        },
    },
    defaultLocation: {
        latitude: 12.9716,
        longitude: 77.5946,
    },
    dynamicPagination: {
        pageIndex: 0,
        pageSize: 5,
        pageSizeOptions: [5, 10, 15, 20],
    },
    currency: {
        indianRupee: "₹",
        dollar: "$",
    },
    fileSize: {
        imageFileSize: "2097152", //2MB
        profileImageSize: "5242880", //5MB
        videoFileSize: "20971520", //20MB
    },
    gstRates: [0, 5, 12, 18, 28],
    minProductQuantity: 1,
    maxProductQuantity: 99,
    minOrderValue: 200,
};
