export function transformPropsToLowerCase<T>(obj: T): T {
    const lowerCaseObj: Partial<T> = {};

    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            const lowerCaseKey = key.toLowerCase() as keyof T;
            lowerCaseObj[lowerCaseKey] = obj[key];
        }
    }

    return lowerCaseObj as T;
}

export function transformPropsToUpperCase<T>(obj: T): T {
    const upperCaseObj: Partial<T> = {};

    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            const upperCaseKey = key.toUpperCase() as keyof T;
            upperCaseObj[upperCaseKey] = obj[key];
        }
    }

    return upperCaseObj as T;
}
