import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AdvertisementInstance } from "@models/advertisement.model";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";

@Injectable({
    providedIn: "root",
})
export class AdvertisementService {
    constructor(private http: HttpClient) {}

    public getAllActiveBanners(): Observable<AdvertisementInstance[]> {
        return this.http.get<AdvertisementInstance[]>(
            `${environment.apiUrlBasePath}/advertisement/getAllActiveBanners`,
        );
    }
}
