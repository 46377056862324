import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
    NullableStructureInstance,
    StructureBaseModel,
    StructureInstance,
    StructureUpdateModel,
} from "@models/structure.model";
import { Observable, Subject } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: "root",
})
export class StructureService {
    private allCapturedStructureImages: File[] = [];
    public capturedStructureGlbUrl = "";

    private structureImageSubject = new Subject<{ action: string; image?: File }>();
    public structureImage$ = this.structureImageSubject.asObservable();

    constructor(private http: HttpClient) {}

    public saveStructure(structure: StructureBaseModel): Observable<{ message: string; structureId: number }> {
        return this.http.post<{ message: string; structureId: number }>(
            `${environment.apiUrlBasePath}/structures/save`,
            structure,
        );
    }

    public getSavedStructures(
        pageIndex: number = environment.dynamicPagination.pageIndex,
        pageSize: number = environment.dynamicPagination.pageSize,
        searchQuery?: string,
    ): Observable<{ count: number; rows: StructureInstance[] }> {
        let url = "";
        if (searchQuery) {
            const encodedSearchQuery = encodeURIComponent(searchQuery!);
            url = `${environment.apiUrlBasePath}/structures/get?pageIndex=${pageIndex}&pageSize=${pageSize}&searchQuery=${encodedSearchQuery}`;
        } else {
            url = `${environment.apiUrlBasePath}/structures/get?pageIndex=${pageIndex}&pageSize=${pageSize}`;
        }
        return this.http.get<{ count: number; rows: StructureInstance[] }>(url);
    }

    public updateStructure(structureId: number, structure: StructureUpdateModel): Observable<{ message: string }> {
        return this.http.patch<{ message: string }>(
            `${environment.apiUrlBasePath}/structures/${structureId}/update`,
            structure,
        );
    }

    public saveAndPublishStructure(structure: StructureBaseModel): Observable<{ message: string }> {
        return this.http.post<{ message: string }>(
            `${environment.apiUrlBasePath}/structures/saveAndPublish`,
            structure,
        );
    }

    public updateAndPublishStructure(
        structureId: number,
        structure: StructureUpdateModel,
    ): Observable<{ message: string }> {
        return this.http.patch<{ message: string }>(
            `${environment.apiUrlBasePath}/structures/${structureId}/updateAndPublish`,
            structure,
        );
    }

    public getStructureDetails(id: number): Observable<{ structureData: NullableStructureInstance }> {
        return this.http.get<{ structureData: NullableStructureInstance }>(
            `${environment.apiUrlBasePath}/structures/getStructureDetails?id=${id}`,
        );
    }

    public publishStructures(structureIdArray: number[]): Observable<{ message: string }> {
        return this.http.patch<{ message: string }>(
            `${environment.apiUrlBasePath}/structures/publish`,
            structureIdArray,
        );
    }

    public deleteStructures(structureIdArray: number[]): Observable<{ message: string }> {
        return this.http.post<{ message: string }>(`${environment.apiUrlBasePath}/structures/delete`, structureIdArray);
    }

    public fetchStructureData(url: string): Observable<string> {
        return this.http.get<string>(`${environment.apiUrlBasePath}/structures/fetchData?url=${url}`);
    }

    public addImage(image: File): void {
        if (this.allCapturedStructureImages.length >= 10) {
            return;
        }

        this.allCapturedStructureImages.push(image);
        this.structureImageSubject.next({ action: "add", image });
    }

    public removeImage(index: number): void {
        if (index >= 0 && index < this.allCapturedStructureImages.length) {
            this.allCapturedStructureImages.splice(index, 1);
            this.structureImageSubject.next({ action: "remove" }); // Emit only the action without an image
        }
    }

    public removeAllImages(): void {
        this.allCapturedStructureImages = [];
        this.structureImageSubject.next({ action: "remove" });
    }

    public getImagesCount(): number {
        return this.allCapturedStructureImages.length;
    }

    public captureGlbUrl(glbUrl: string): void {
        this.capturedStructureGlbUrl = glbUrl;
    }
}
