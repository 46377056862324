<div class="rewards-wrapper" *ngIf="rewards?.length; else noRewardTemplate">
    <div class="rewards-head">
        <h1 class="coin">
            Coin Balance <span *ngIf="totalBalance > 0">{{ "(" + totalBalance + ")" }}</span>
        </h1>
        <mat-icon svgIcon="rewards" class="rewards-icon" aria-hidden="false" aria-label="rewards"></mat-icon>
    </div>
    <mat-spinner *ngIf="loading" color="warn" class="loading-spinner" [diameter]="70"></mat-spinner>
    <div class="card">
        <div *ngFor="let reward of rewards" class="reward-card">
            <div class="name">
                <div class="structure-name">
                    {{ getDisplayName(reward) }}
                </div>
                <div class="date">
                    {{ reward.type === "debited" ? RewardType.Debited : RewardType.Credited }} on
                    {{ reward.createdAt | date: "yyyy-MM-dd HH:mm:ss" }}
                </div>
            </div>
            <div class="amount">
                <span
                    [ngClass]="{
                        positive: reward.coinsEarned > 0,
                        negative: reward.coinsSpent > 0
                    }"
                >
                    {{ reward.coinsSpent ? "-" + reward.coinsSpent : "+" + reward.coinsEarned }}
                </span>
            </div>
        </div>
    </div>
    <button class="view" *ngIf="showViewMoreButton()" mat-raised-button (click)="loadMoreRewards()">View More</button>
</div>

<ng-template #noRewardTemplate>
    <div class="no-rewards" *ngIf="!loading">
        <mat-icon svgIcon="noReward" class="no-reward" aria-hidden="false" aria-label="rewards"></mat-icon>
        <h2>No Rewards Found</h2>
        <p>Oops! There's no rewards yet...</p>
        <button mat-raised-button class="save-btn" (click)="goToHomePage()">Back To Home</button>
    </div>
</ng-template>
