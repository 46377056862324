import { ProductDetails } from "./product.model";

export interface CartResponse {
    id: number;
    name: string;
    price: number;
    discountPrice?: number;
    image?: object;
    quantity: number;
    singleBlockInfo?: object;
    cartUUID: string;
}

export interface CartItem {
    id: number;
    quantity: number;
    product?: ProductDetails;
    singleBlockInfo?: {
        color: string | number;
    };
    cartUUID?: string;
}

export interface WishListItem {
    id?: number;
    product?: ProductDetails;
}

export enum ResponseMessage {
    Delete = "Remove Successfully",
    UpdateSuccess = "Updated Successfully",
    FailRemoveCart = "Fail to remove cart",
    Cannotfind = "Cannot find productID",
    QuantityUpdateFailed = "Failed to update quantity",
    AddSuccess = "Added Successfully",
    AddFailed = "Unable to add to cart",
    GetFailed = "Failed to get cart items",
}

export interface QuantityResponse {
    id?: number;
    quantity?: number;
    cartUUID?: string;
}
