<mat-spinner *ngIf="loading" class="loading-spinner" [diameter]="70"></mat-spinner>
<div class="container" *ngIf="!loading && order">
    <div class="heading-container">
        <h3 class="title">Order Details</h3>
    </div>
    <app-order-timeline *ngIf="showTimeline" [statusHistory]="filteredStatusHistory!"></app-order-timeline>

    <div class="subtitle">
        <span class="orderedOn">Ordered on {{ order.createdAt | date: "dd MMMM y" }}</span>
        <span>|</span>
        <span class="orderId">Order# {{ order.id }}</span>
    </div>

    <div class="card main">
        <div class="shippingAddress">
            <p class="main-title">Shipping Address</p>

            <p>{{ order.recipientName }}</p>

            <p>
                {{ order.recipientHouseNumber }}, {{ order.recipientAddress }}, {{ order.recipientCity }},
                {{ order.recipientState }},
                {{ order.recipientPincode }}
            </p>
            <p>{{ order.recipientMobileNumber }}</p>
        </div>

        <div class="status">
            <p class="main-title">Status</p>

            <span class="status-pair">
                <p class="label">Order Status:</p>
                <p class="value" [ngClass]="getOrderStatusClasses()">
                    {{ order.orderStatus }}
                </p>
            </span>

            <span class="status-pair">
                <p class="label">Payment Status:</p>
                <p class="value" [ngClass]="getPaymentStatusClasses()">
                    {{ order.paymentStatus }}
                </p>
            </span>

            <span class="status-pair" *ngIf="this.isAllRefundSuccessful">
                <p class="label">Refund Status:</p>
                <p class="refund-successful">
                    {{ refundStatusMessage }}
                </p>
            </span>
        </div>

        <div class="summary">
            <p class="main-title">Summary</p>

            <span class="pair">
                <p class="label">Item(s) Subtotal:</p>
                <p class="value">{{ currency }} {{ order.totalAmount - order.deliveryCharge }}</p>
            </span>
            <span class="pair">
                <p class="label">Shipping:</p>
                <p class="value">{{ currency }} {{ order.deliveryCharge }}</p>
            </span>
            <span class="pair">
                <p class="label">Total:</p>
                <p class="value">{{ currency }} {{ order.totalAmount }}</p>
            </span>
        </div>
    </div>

    <div class="card items" *ngIf="nonCancelledItems.length">
        <h2>Your Items</h2>
        <div *ngIf="(!hasReturnedItems || !hasExchangeItems) && !isExceedMoreThan7days(order)" class="btn-content">
            <div
                class="content"
                *ngIf="
                    order.orderStatus === OrderStatus.Delivered ||
                    order.orderStatus === OrderStatus.Returned ||
                    order.orderStatus === OrderStatus.Ordered ||
                    order.orderStatus === OrderStatus.Packed
                "
            >
                <div
                    *ngIf="
                        (!hasCancelledItems && (!hasReturnedItems || !hasExchangeItems) && this.selectedItemIds) ||
                        isCancellableOrReturnOrder(order)
                    "
                    class="checkbox-content"
                >
                    <mat-checkbox
                        *ngIf="nonCancelledItems.length > 1 || exchangeItems.length > 1"
                        [indeterminate]="isSomeSelected()"
                        (change)="toggleSelectAll($event)"
                        [checked]="isAllSelected()"
                    >
                        Select All
                    </mat-checkbox>
                </div>
                &nbsp;&nbsp;
                <button
                    class="exchange-btn"
                    mat-raised-button
                    *ngIf="!hasExchangeItems && order.orderStatus === OrderStatus.Delivered"
                    (click)="openExchangeDialog(order)"
                    [disabled]="!this.selectedItemIds.length"
                >
                    Exchange
                </button>
            </div>
            &nbsp;&nbsp;
            <button
                class="save-btn"
                mat-raised-button
                *ngIf="isCancellableOrReturnOrder(order) && !isExceedMoreThan7days(order)"
                (click)="cancelOrReturn(order)"
                [disabled]="!this.selectedItemIds.length"
            >
                {{ isCancellableOrReturnOrder(order) }}
            </button>
        </div>

        <div class="product-card" *ngFor="let item of nonCancelledItems">
            <mat-checkbox
                *ngIf="
                    (!hasCancelledItems && (!hasReturnedItems || !hasExchangeItems)) ||
                    isCancellableOrReturnOrder(order)
                "
                (change)="toggleItemSelection(item.id!, $event.checked)"
                [checked]="isSelected(item.id!)"
            ></mat-checkbox>
            <div class="prod-img-container">
                <img
                    *ngIf="item.product?.images?.imagesUrl?.[0]"
                    [src]="item.product?.images?.imagesUrl?.[0]"
                    class="image"
                />
                <div *ngIf="!item.product?.images?.imagesUrl?.[0]" class="image"></div>
            </div>

            <div class="details">
                <div class="product-name">
                    <p
                        *ngIf="item.product?.name"
                        [matTooltip]="item.product?.name || ''"
                        [matTooltipShowDelay]="500"
                        [matTooltipHideDelay]="500"
                        [matTooltipDisabled]="!isOverflow(productName)"
                        #productName
                    >
                        {{ item.product?.name }}
                    </p>
                </div>

                <div class="quantity">
                    <p>Quantity: {{ item.quantity }}</p>
                </div>

                <div class="price">
                    <p>₹{{ item.discountPrice || item.price }}</p>
                </div>

                <div class="returned-status">
                    <p>
                        Order Status:
                        <span [ngClass]="getOrderStatusClasses(item)">{{ item.itemStatus }}</span>
                    </p>
                </div>
            </div>

            <div class="ratingAndReview">
                <button
                    *ngIf="showReviewBtn(item)"
                    mat-raised-button
                    class="save-btn"
                    [disabled]="isReviewDisabled(item.product)"
                    (click)="openRatingPopup(item.product, order)"
                >
                    Add Review
                </button>
            </div>
        </div>
    </div>

    <div class="card items" *ngIf="cancelledItems.length">
        <h2>Cancelled Items</h2>
        <div class="product-card" *ngFor="let item of cancelledItems">
            <div class="prod-img-container">
                <img
                    *ngIf="item.product?.images?.imagesUrl?.[0]"
                    [src]="item.product?.images?.imagesUrl?.[0]"
                    class="image"
                />
                <div *ngIf="!item.product?.images?.imagesUrl?.[0]" class="image"></div>
            </div>

            <div class="details">
                <div class="product-name">
                    <p
                        *ngIf="item.product?.name"
                        [matTooltip]="item.product?.name || ''"
                        [matTooltipShowDelay]="500"
                        [matTooltipHideDelay]="500"
                        [matTooltipDisabled]="!isOverflow(productName)"
                        #productName
                    >
                        {{ item.product?.name }}
                    </p>
                </div>

                <div class="quantity">
                    <p>Quantity: {{ item.quantity }}</p>
                </div>

                <div class="price">
                    <p>₹{{ item.discountPrice || item.price }}</p>
                </div>

                <div class="refund-status" *ngIf="order.paymentStatus !== paymentType.CashOnDelivery">
                    <p>
                        Refund Status:
                        <span [ngClass]="getRefundStatusClass(item.refund?.refundStatus)">{{
                            item.refund?.refundStatus
                        }}</span>
                    </p>
                </div>
            </div>
        </div>
    </div>

    <div class="card items" *ngIf="returnedItems.length">
        <h2>Returned Items</h2>
        <div class="product-card" *ngFor="let item of returnedItems">
            <div class="prod-img-container">
                <img
                    *ngIf="item.product?.images?.imagesUrl?.[0]"
                    [src]="item.product?.images?.imagesUrl?.[0]"
                    class="image"
                />
                <div *ngIf="!item.product?.images?.imagesUrl?.[0]" class="image"></div>
            </div>

            <div class="details">
                <div class="product-name">
                    <p
                        *ngIf="item.product?.name"
                        [matTooltip]="item.product?.name || ''"
                        [matTooltipShowDelay]="500"
                        [matTooltipHideDelay]="500"
                        [matTooltipDisabled]="!isOverflow(productName)"
                        #productName
                    >
                        {{ item.product?.name }}
                    </p>
                </div>

                <div class="quantity">
                    <p>Quantity: {{ item.quantity }}</p>
                </div>

                <div class="price">
                    <p>₹{{ item.discountPrice || item.price }}</p>
                </div>

                <div class="status">
                    <div class="refund-status" *ngIf="item.refund?.refundStatus">
                        <p>
                            Refund Status:
                            <span [ngClass]="getRefundStatusClass(item.refund?.refundStatus)">{{
                                item.refund?.refundStatus
                            }}</span>
                        </p>
                    </div>

                    <div class="returned-status">
                        <p>
                            Order Status:
                            <span [ngClass]="getOrderStatusClasses(item)">{{ item.itemStatus }}</span>
                        </p>
                    </div>
                </div>
            </div>

            <div class="ratingAndReview">
                <button
                    *ngIf="
                        item.itemStatus === OrderStatus.Delivered ||
                        item.itemStatus === OrderStatus.ReturnInitiated ||
                        item.itemStatus === OrderStatus.PickupScheduled ||
                        item.itemStatus === OrderStatus.Returned
                    "
                    mat-raised-button
                    class="save-btn"
                    [disabled]="isReviewDisabled(item.product)"
                    (click)="openRatingPopup(item.product, order)"
                >
                    Add Review
                </button>
            </div>
        </div>
    </div>

    <div class="card items" *ngIf="exchangeItems.length">
        <h2>Exchanged Items</h2>
        <div
            *ngIf="!nonCancelledItems.length && !hasReturnedItems && !isExceedMoreThan7days(order) && isItemExchanged()"
            class="btn-content"
        >
            <div class="checkbox-content">
                <mat-checkbox
                    [indeterminate]="isSomeSelected()"
                    (change)="toggleSelectAll($event)"
                    [checked]="isAllSelected()"
                ></mat-checkbox>
                <span class="s-text">Select All</span>
            </div>
            &nbsp;&nbsp;
            <button
                class="save-btn"
                mat-raised-button
                (click)="openDialogReturn()"
                [disabled]="!this.selectedItemIds.length"
            >
                Return
            </button>
        </div>
        <div class="product-card" *ngFor="let item of exchangeItems">
            <mat-checkbox
                *ngIf="isItemExchanged() && !hasReturnedItems"
                (change)="toggleItemSelection(item.id!, $event.checked)"
                [checked]="isSelected(item.id!)"
            ></mat-checkbox>
            <div class="prod-img-container">
                <img
                    *ngIf="item.product?.images?.imagesUrl?.[0]"
                    [src]="item.product?.images?.imagesUrl?.[0]"
                    class="image"
                />
                <div *ngIf="!item.product?.images?.imagesUrl?.[0]" class="image"></div>
            </div>

            <div class="details">
                <div class="product-name">
                    <p
                        *ngIf="item.product?.name"
                        [matTooltip]="item.product?.name || ''"
                        [matTooltipShowDelay]="500"
                        [matTooltipHideDelay]="500"
                        [matTooltipDisabled]="!isOverflow(productName)"
                        #productName
                    >
                        {{ item.product?.name }}
                    </p>
                </div>

                <div class="quantity">
                    <p>Quantity: {{ item.quantity }}</p>
                </div>

                <div class="price">
                    <p>₹{{ item.discountPrice || item.price }}</p>
                </div>

                <div class="status">
                    <div class="refund-status" *ngIf="item.refund?.refundStatus">
                        <p>
                            Refund Status:
                            <span [ngClass]="getRefundStatusClass(item.refund?.refundStatus)">{{
                                item.refund?.refundStatus
                            }}</span>
                        </p>
                    </div>

                    <div class="returned-status">
                        <p>
                            Order Status:
                            <span [ngClass]="getOrderStatusClasses(item)">{{ item.itemStatus }}</span>
                        </p>
                    </div>
                </div>
            </div>

            <div class="ratingAndReview">
                <button
                    *ngIf="item.itemStatus === OrderStatus.Exchanged"
                    mat-raised-button
                    class="save-btn"
                    [disabled]="isReviewDisabled(item.product)"
                    (click)="openRatingPopup(item.product, order)"
                >
                    Add Review
                </button>
            </div>
        </div>
    </div>
</div>
