import { Component, ViewChild } from "@angular/core";
import { DataGridComponent } from "../../shared/data-grid/data-grid.component";
import { AdminModel, AdminPageListColumns, ToggleChangeEvent } from "@models/admin.model";
import { Action, DataGridConfig } from "@models/data-grid.model";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";
import { PageEvent } from "@angular/material/paginator";
import { AdminService } from "../../services/admin/admin.service";
import { AuthService } from "../../services/auth/auth.service";
import { UserPermissions } from "@models/permission.model";

@Component({
    selector: "app-dashboard-admin-page",
    templateUrl: "./dashboard-admin-page.component.html",
    styleUrls: ["./dashboard-admin-page.component.scss"],
})
export class DashboardAdminPageComponent {
    @ViewChild(DataGridComponent, { static: true })
    dataGridComponent!: DataGridComponent;

    public searchQuery = "";
    public admins?: AdminModel[] = [];
    public columns = AdminPageListColumns;
    public userPermissions: string[] = [];
    public hasPermission = false;

    public actions: Action[] = [];

    public config: DataGridConfig = {
        currency: environment.currency.indianRupee,
        pageIndex: environment.dynamicPagination.pageIndex,
        pageSize: environment.dynamicPagination.pageSize,
        pageSizeOptions: [environment.dynamicPagination.pageSize, 10, 15, 20],
        isSearchMode: false,
    };

    constructor(
        private router: Router,
        private adminService: AdminService,
        private authService: AuthService,
    ) {}

    ngOnInit() {
        this.loadUsers();
        this.adminService.permissions$.subscribe({
            next: (response) => {
                this.userPermissions = this.authService.permissionData = response;
                if (this.userPermissions.includes(UserPermissions.UserEdit)) {
                    this.actions = [
                        {
                            label: "Edit",
                            icon: "edit",
                            callback: this.onEditUser.bind(this),
                        },
                    ];
                } else if (this.userPermissions.includes(UserPermissions.UserView)) {
                    this.actions = [
                        {
                            label: "Details",
                            icon: "info",
                            callback: this.onViewInfo.bind(this),
                        },
                    ];
                }

                if (this.userPermissions.find((permission) => permission === UserPermissions.UserEdit)) {
                    this.hasPermission = true;
                }
            },
        });
    }

    private loadUsers(): void {
        this.dataGridComponent.loadData(this.adminService.getAllUsers(this.config.pageIndex, this.config.pageSize));
    }

    public onEditUser(id: number): void {
        this.router.navigate(["admin/users", id, "edit"]);
    }

    public onViewInfo(id: number): void {
        this.router.navigate(["admin/users", id, "view"]);
    }

    public onSearch(): void {
        this.config.pageIndex = 0;
        this.config.isSearchMode = true;
        if (!this.searchQuery) {
            this.config.isSearchMode = false;
        }
        this.loadSearchUsers();
    }

    private loadSearchUsers(): void {
        this.dataGridComponent.loadData(
            this.adminService.getAllUsers(this.config.pageIndex, this.config.pageSize, this.searchQuery),
        );
    }

    public onPageChange(event: PageEvent): void {
        this.config.pageIndex = event.pageIndex;
        this.config.pageSize = event.pageSize;
        this.config.isSearchMode ? this.loadSearchUsers() : this.loadUsers();
    }

    public onToggleChange(event: ToggleChangeEvent): void {
        const updatedStatus = event.event.checked;
        const id = event.row.id;
        const data = { id: id as number, status: updatedStatus };
        this.adminService.updateStatus(data).subscribe();
    }

    public goToAddUser(): void {
        this.router.navigate(["/admin/users/add"]);
    }
}
