<div class="wrapper">
    <mat-spinner *ngIf="loading" class="loading-spinner" [diameter]="70"></mat-spinner>
    <div class="container" *ngIf="!loading && products.length; else emptyCart">
        <div class="left-section">
            <mat-card *ngFor="let product of products">
                <mat-card-content class="item-content">
                    <div class="item">
                        <div class="item-description">
                            <div class="item-image-container">
                                <div class="image" (click)="onClickProduct(product.product.id)">
                                    <img
                                        *ngIf="product.product.images; else placeholder"
                                        [src]="product.product.images.imagesUrl[0]"
                                        alt="Item Image"
                                    />

                                    <ng-template #placeholder>
                                        <div class="image-placeholder"></div>
                                    </ng-template>
                                </div>
                            </div>

                            <div class="item-detail">
                                <b>
                                    {{ product.product.name }}
                                </b>
                                <p class="description">{{ product.product.description }}</p>
                                <span *ngIf="product.product.discountPrice; else noDiscount">
                                    <span>
                                        <s>₹ {{ product.product.price }}</s>
                                    </span>
                                    <span class="discount-price">&nbsp;₹ {{ product.product.discountPrice }} </span>
                                </span>

                                <ng-template #noDiscount>
                                    <span>₹ {{ product.product.price }}</span>
                                </ng-template>
                                <br />
                                <div *ngIf="product.product.category?.includes(categories.BuyBlocks)">
                                    <!-- Plane Section -->
                                    <div *ngIf="getFacesByType(product, 'Plane').length > 0">
                                        <span class="block-face-label">Plane: </span>
                                        <span class="block-face-value">{{ getFacesByType(product, "Plane") }}</span>
                                    </div>

                                    <!-- Hole Section -->
                                    <div *ngIf="getFacesByType(product, 'Hole').length > 0">
                                        <span class="block-face-label">Hole: </span>
                                        <span class="block-face-value">{{ getFacesByType(product, "Hole") }}</span>
                                    </div>

                                    <!-- Node Section -->
                                    <div *ngIf="getFacesByType(product, 'Node').length > 0">
                                        <span class="block-face-label">Node: </span>
                                        <span class="block-face-value">{{ getFacesByType(product, "Node") }}</span>
                                    </div>
                                    <!-- Shape Color -->
                                    <div>
                                        <span class="block-face-label">Color: </span>
                                        <span class="color-shape" [style.color]="getShapeColorValue(product)">
                                            {{ getShapeColorName(product) }}
                                        </span>
                                    </div>
                                </div>
                                <p class="return">7 days return available<br /><br /></p>
                                <div class="unavailable" *ngIf="product.product.isActive === false">
                                    <p>Product is currently unavailable.</p>
                                </div>
                            </div>
                        </div>
                        <div class="btn-quantity">
                            <div>
                                <mat-icon (click)="onDeleteCartItem(product)" class="delete-btn"> delete </mat-icon>
                            </div>
                            <div class="quantity-count">
                                <span
                                    ><button
                                        class="decrement"
                                        (click)="onClick(-1, product.product.id, product.cartUUID!)"
                                        [disabled]="getProductQtyInCart(product.product.id, product.cartUUID!) <= 1"
                                    >
                                        -
                                    </button></span
                                >
                                <input
                                    type="number"
                                    class="quantity"
                                    [value]="getProductQtyInCart(product.product.id, product.cartUUID!)"
                                    (input)="onInputChange($event, product.product.id, product.cartUUID!)"
                                    (blur)="onBlur($event, product.product.id, product.cartUUID!)"
                                />
                                <span
                                    ><button
                                        class="increment"
                                        (click)="onClick(1, product.product.id, product.cartUUID!)"
                                        [disabled]="getProductQtyInCart(product.product.id, product.cartUUID!) >= 99"
                                    >
                                        +
                                    </button></span
                                >
                            </div>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>

        <div class="right-section" *ngIf="products.length > 0">
            <div class="item-price">
                <mat-card>
                    <mat-card-content class="item-price-content">
                        <p class="price-detail">PRICE DETAILS</p>
                        <br />
                        <p class="mrp-value">
                            Total MRP <span>₹ {{ totalMRP }}</span>
                        </p>
                        <p class="discount-mrp">
                            Discount on MRP <span>- ₹ {{ totalDiscount }}</span>
                        </p>
                        <hr class="line" />
                        <p class="totl-amount">
                            Total Amount <span>₹ {{ totalAmount }}</span>
                        </p>
                    </mat-card-content>
                </mat-card>
            </div>

            <div class="checkout-btn-cont">
                <button mat-flat-button class="save-btn" (click)="onCheckout()" [disabled]="!isCheckoutEnabled">
                    Checkout</button
                ><br /><br />
                <div *ngIf="!isCheckoutEnabled" class="min-purchase-message">
                    The minimum purchase amount is Rs {{ minOrderValue }}. Please add more items to your cart.
                </div>
            </div>
        </div>
    </div>
    <ng-template #emptyCart>
        <div class="empty-cart" *ngIf="!loading">
            <div class="image-container">
                <mat-icon svgIcon="emptyCart" class="emptyCartIcon" aria-hidden="false" aria-label="Empty Cart" />
            </div>
            <div class="message">
                <div class="message-title">Empty Cart</div>
                <div class="message-body">Looks like you haven't made your choice yet...</div>
            </div>
            <div class="button">
                <button mat-flat-button (click)="backToHome()" class="save-btn">Back to Home</button>
            </div>
            <div class="button-info">Check what we've got for you and get it swished!</div>
        </div>
    </ng-template>
</div>
