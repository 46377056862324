export const Sides = {
    Right: "Right",
    Left: "Left",
    Back: "Back",
    Front: "Front",
    Top: "Top",
    Bottom: "Bottom",
};

export type faceLabel = "front" | "back" | "left" | "right" | "top" | "bottom";

type ChoiceType = "Node" | "Hole" | "Plane";

interface ChoiceOptions {
    choices: ChoiceType[];
}

export const myChoices: ChoiceOptions = {
    choices: ["Node", "Hole", "Plane"],
};

export const faceDetails: { [key in faceLabel]: string } = {
    front: "Plane",
    back: "Plane",
    left: "Plane",
    right: "Plane",
    top: "Plane",
    bottom: "Plane",
};
