<div fxLayout="row" fxLayoutAlign="start center">
    <h3 class="title">Select Color</h3>
</div>
<div class="customiseColorPicker">
    <div *ngFor="let inputConf of inputConfs">
        <input
            type="radio"
            name="color"
            [id]="inputConf.accessbility"
            [value]="inputConf.value"
            (click)="changeColor($event)"
        />
        <label [for]="inputConf.accessbility" [style.background-color]="inputConf.value"></label>
    </div>
</div>
