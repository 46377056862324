import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { tap, switchMap, catchError, of, Observable, forkJoin, finalize, map } from "rxjs";
import { OrderService } from "src/app/services/order/order.service";
import { PaymentService } from "src/app/payment/services/payment/payment.service";
import { CheckoutService } from "src/app/services/checkout-page/checkout.service";
import { environment } from "src/environments/environment";
import { OrderAttributes, OrderErrorMessage, OrderInput } from "@models/order.model";
import { InitiatePaymentInput, InitiatePaymentResponse } from "@models/payment.model";
import { AuthService } from "src/app/services/auth/auth.service";
import { UserService } from "src/app/services/user/user.service";
import {
    AddressDetailsCreationAttributes,
    NullableAddress,
    NullableAddresses,
    NullableUser,
    UserDetailStatus,
    UserDetailsCreationAttributes,
} from "@models/user.model";
import { DialogService } from "src/app/services/dialog/dialog.service";
import { v4 as uuidv4 } from "uuid";
import { CartDetails, ProductCategories, ProductDetails } from "src/models/product.model";
import { InitiatePhonePePaymentInput } from "@models/phonepe.model";
import { CartItemService } from "../../services/cart-item/cart-item.service";
import { CartItem } from "@models/cart.model";
import { DialogInvokingComponents } from "@models/generic-dialog.model";
import { appConfig } from "../../../environments/app.config";
import { RewardsService } from "../../rewards/rewards.service";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { Order, RewardDetail, rewardCoins } from "@models/reward.model";
import { PaymentType, PaymentOption, PaymentMode } from "@models/order.model";
import { CouponsService } from "./coupons.service";
import { CouponsMessage } from "@models/coupon.model";

@Component({
    selector: "app-checkout-page",
    templateUrl: "./checkout-page.component.html",
    styleUrls: ["./checkout-page.component.scss"],
})
export class CheckoutPageComponent implements OnInit {
    public couponForm: FormGroup;
    public isLoggedIn = false;
    public currentUser?: NullableUser;
    public currentUserAddresses?: NullableAddresses;
    public selectedAddress?: NullableAddress;
    public cartItems: CartItem[] = [];
    public cartProducts: ProductDetails[] = [];
    public loading = false; // State to control spinner
    public orderData!: OrderInput;
    public subtotal: number = 0;
    public discount: number = 0;
    public deliveryCharge: number = 0;
    public total: number = 0;
    public paymentMode = environment.payment.mode;
    public title!: string;
    public isPayNowEnabled = false;
    public minOrderValue = appConfig.minOrderValue;
    public totalCoinBalance: number = 0;
    public coinsUsed: number = 0;
    public userId!: number;
    public PaymentType = PaymentType;
    public selectedPaymentOption: string = "online";
    public isUsingCoins!: boolean;
    public paymentOption = PaymentOption;
    public totalRewardsCount!: number;
    public pageIndex = environment.dynamicPagination.pageIndex;
    public pageSize = environment.dynamicPagination.pageSize;
    public rewards: RewardDetail[] = [];
    public isCouponUsed: boolean = false;
    public appliedCoupon: string | null = null;
    public maxAmount: number = 0;
    public couponDiscountPercentage: number = 0;
    public couponsValue: number = 0;
    public availableCoupons: any[] = [];
    public showCouponSidebar: boolean = false;
    public totalCouponsCount: number = 0;
    public appliedCouponCodes: string[] = [];
    public isCouponInvalid: boolean = false;
    public cartDetails: CartDetails[] = [];
    public mobileNumber!: string;
    public categories = ProductCategories;
    public isDirectBuy: boolean = false;
    public productIds!: number[];

    constructor(
        private fb: FormBuilder,
        private paymentService: PaymentService,
        private snackBar: MatSnackBar,
        private orderService: OrderService,
        private authService: AuthService,
        private userService: UserService,
        private checkoutService: CheckoutService,
        private cdr: ChangeDetectorRef,
        private dialogService: DialogService,
        private cartService: CartItemService,
        private rewardsService: RewardsService,
        private router: Router,
        private couponsService: CouponsService,
    ) {
        this.couponForm = this.fb.group({
            couponCode: ["", Validators.required],
        });
    }

    ngOnInit() {
        // Check whether it is logged in or not
        this.isLoggedIn = this.authService.isUserLoggedIn;
        this.isLoggedIn ? this.getRegisteredUserDetails() : this.getGuestUserDetails();

        //Retrieve cart products
        const product = history.state.productData;

        if (product) {
            this.loadPassedItem();
        } else {
            this.loadCartItems();
        }

        this.title = product ? "Item(s)" : "Items in Cart";
        this.getRewardsData();
        this.userService.getUserDetails().subscribe((response) => {
            if (response) {
                this.userId = response.id;
                this.mobileNumber = response.mobileNumber;
                this.fetchAvailableCoupons(this.mobileNumber as unknown as number);
            }
        });
    }

    public handlePayment(): void {
        if (this.selectedPaymentOption === this.paymentOption.Online) {
            this.paymentMode === PaymentMode.Paytm ? this.makePaytmPayment() : this.makePhonepePayment();
        } else if (this.selectedPaymentOption === this.paymentOption.Cod) {
            this.proceedWithCOD();
        } else if (this.selectedPaymentOption === this.paymentOption.Reward) {
            this.handlePlaceOrder();
        }
    }
    //user wants to do payment with Rewards
    public handlePlaceOrder() {
        this.selectedPaymentOption = PaymentType.Reward;
        this.loading = true;

        this.prepareOrderData()
            .then(() => {
                this.orderService
                    .createOrder(this.orderData, this.selectedPaymentOption)
                    .pipe(
                        finalize(() => {
                            this.loading = false;
                        }),
                    )
                    .subscribe({
                        next: (response) => {
                            this.router.navigate(["order/success"], {
                                queryParams: { orderId: response.id },
                            });
                            this.cartService.removeAllCartItems().subscribe();
                            if (this.coinsUsed > 0 && this.userId) {
                                const productId = this.orderData.products?.[0]?.id;
                                if (productId) {
                                    this.rewardsService
                                        .updateCoinSpent(this.userId, response.id, productId, this.coinsUsed)
                                        .subscribe({
                                            next: () => {
                                                this.snackBar.open(rewardCoins.coinsSpentSucessfull, "Close", {
                                                    duration: 3000,
                                                });
                                            },
                                            error: (err) => {
                                                this.snackBar.open(rewardCoins.coinSpentFailed, "Close", {
                                                    duration: 3000,
                                                });
                                                console.error("Coin spending failed", err);
                                            },
                                        });
                                }
                            }
                        },
                        error: (error) => {
                            this.snackBar.open("Failed to create order", "Close", { duration: 3000 });
                            console.error("Error creating order", error);
                        },
                    });
            })
            .catch((error) => {
                // Handle errors during order data preparation
                this.loading = false;
                this.snackBar.open("Failed to prepare order data", "Close", { duration: 3000 });
                console.error("Error preparing order data", error);
            });
    }

    private loadPassedItem(): void {
        const productData = history.state.productData;
        if (productData && productData.length > 0) {
            this.cartItems = productData;
            this.isDirectBuy = true;
            this.cartDetails = this.cartItems.map((item) => ({
                product: item.product!,
                singleBlockInfo: item.singleBlockInfo!,
            }));
            this.productIds = this.cartItems.map((item) => item.product?.id!);
            this.cartProducts = this.cartItems.map((item) => item.product!);

            this.cdr.detectChanges();
        }

        // Ensure cartProducts are initialized before calculating summary
        if (this.cartProducts && this.cartProducts.length > 0) {
            this.calculateOrderSummary();
        }
    }

    private loadCartItems(): void {
        this.cartService.getCartItems().subscribe((items) => {
            this.cartItems = items;
            this.isDirectBuy = false;
            this.cartDetails = this.cartItems.map((item) => ({
                product: item.product!,
                singleBlockInfo: item.singleBlockInfo!,
            }));
            this.productIds = this.cartItems.map((item) => item.product?.id!);
            this.cartProducts = this.cartItems.map((item) => item.product!);

            this.cdr.detectChanges();
            this.calculateOrderSummary();
        });
    }

    private calculateOrderSummary(): void {
        this.cartService.calculateTotalMRP(this.cartItems);
        this.subtotal = this.cartService.totalMRP;
        this.discount = this.cartService.totalDiscount;
        this.total = this.cartService.totalAmount;
        this.isPayNowEnabled = this.total >= appConfig.minOrderValue;
    }

    private getGuestUserDetails(): void {
        const address = this.checkoutService.getAddressFromLocalStorage();
        if (address) this.selectedAddress = address;
        this.currentUser = this.checkoutService.getCurrentUserFromLocalStorage();
    }

    // Registered Users: Get Details from Database
    private getRegisteredUserDetails(): void {
        this.userService.getUserDetails().subscribe((response) => {
            if (response) {
                this.currentUser = response.user;
                this.currentUserAddresses = response.addresses;
                this.selectedAddress = response.addresses![response.addresses?.length! - 1];
            }
        });
    }

    private prepareOrderData(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            const products = this.cartItems.map((item) => ({
                id: item.product!.id,
                quantity: item.quantity,
                singleBlockInfo: this.isDirectBuy ? item.singleBlockInfo! : null,
            }));

            this.orderData = {
                products,
                totalAmount: this.total,
                deliveryCharge: this.deliveryCharge,
                customerName: this.currentUser!.name,
                customerEmail: this.currentUser!.email,
                customerMobile: this.currentUser!.userMobileNumber!,
                recipientName: this.selectedAddress?.name || "",
                recipientMobileNumber: this.selectedAddress?.mobileNumber || "",
                recipientAddressType: this.selectedAddress?.addressType || "",
                recipientAddress: this.selectedAddress?.address || "",
                recipientCity: this.selectedAddress?.city || "",
                recipientState: this.selectedAddress?.state || "",
                recipientPincode: this.selectedAddress?.pincode || 0,
                recipientHouseNumber: this.selectedAddress?.houseNumber || "",
                isFromCart: !this.isDirectBuy,
            };
            resolve();
        });
    }

    // Create Order Record and inititate payment
    public makePaytmPayment(): void {
        this.loading = true;
        this.prepareOrderData().then(() => {
            this.orderService
                .createOrder(this.orderData)
                .pipe(
                    tap(this.validateOrderCreation),
                    switchMap((newOrder) => this.preparePaymentData(newOrder)),
                    switchMap((paymentData) => this.paymentService.initiatePayment(paymentData)),
                    tap(this.handleInitiatePaymentResponse),
                    catchError(this.handleError),
                )
                .subscribe({
                    next: () => {
                        this.loading = false;
                    },
                    error: () => {
                        this.loading = false; // Stop spinner in case of subscription error
                    },
                });
        });
    }

    public makePhonepePayment(): void {
        this.loading = true;

        // First, get user details
        this.userService
            .getUserDetails()
            .pipe(
                tap((response) => {
                    if (response) {
                        this.userId = response.id;
                    }
                }),
                // Once user details are fetched, prepare order data
                switchMap(() => this.prepareOrderData()),
                // Create order after preparing order data and capture the order response
                switchMap(() =>
                    this.orderService.createOrder(this.orderData).pipe(
                        map((newOrder) => {
                            return { newOrder }; // Return newOrder for further use in the pipeline
                        }),
                    ),
                ),
                // Prepare PhonePe payment data
                switchMap(({ newOrder }) =>
                    this.preparePhonePePaymentData(newOrder).pipe(
                        map((paymentData) => ({ newOrder, paymentData })), // Pass both newOrder and paymentData
                    ),
                ),
                // Initiate PhonePe payment
                switchMap(({ newOrder, paymentData }) =>
                    this.paymentService.initiatePhonePePayment(paymentData).pipe(
                        map((paymentResponse) => ({ newOrder, paymentResponse })), // Pass both newOrder and paymentResponse
                    ),
                ),
                tap(({ newOrder, paymentResponse }) => {
                    if (this.coinsUsed > 0 && this.userId) {
                        const productId = this.orderData.products?.[0].id;

                        if (productId) {
                            this.rewardsService
                                .updateCoinSpent(this.userId, newOrder.id, productId, this.coinsUsed)
                                .subscribe({
                                    next: () => {
                                        this.snackBar.open(rewardCoins.coinsSpentSucessfully, "Close", {
                                            duration: 3000,
                                        });
                                    },
                                    error: (err) => {
                                        this.snackBar.open(rewardCoins.coinSpentFailed, "Close", { duration: 3000 });
                                    },
                                });
                        }
                    }
                }),
            )
            .subscribe({
                next: (response) => {
                    this.loading = false;
                    const payPageUrl = response.paymentResponse.data!.instrumentResponse.redirectInfo.url;

                    // Handle coupon usage after payment success
                    if (this.appliedCoupon) {
                        const userId = this.mobileNumber;
                        this.couponsService.markCouponAsUsed(this.appliedCoupon, userId).subscribe({
                            next: () => {
                                this.snackBar.open(CouponsMessage.couponApplied, "Close", { duration: 3000 });
                                window.location.href = payPageUrl!;
                            },
                            error: (error) => {
                                this.snackBar.open(error.message, "Close", { duration: 3000 });
                            },
                        });
                    } else {
                        window.location.href = payPageUrl!;
                    }
                },
                error: (error) => {
                    alert(error.error);
                    this.loading = false;
                },
            });
    }

    public proceedWithCOD(): void {
        this.selectedPaymentOption = PaymentType.CashOnDelivery;
        this.loading = true;

        this.prepareOrderData().then(() => {
            this.orderService
                .createOrder(this.orderData, this.selectedPaymentOption)
                .pipe(
                    finalize(() => {
                        this.loading = false;
                    }),
                )
                .subscribe({
                    next: (response) => {
                        if (this.coinsUsed > 0 && this.userId) {
                            const productId = this.orderData.products?.[0].id;

                            if (productId) {
                                this.rewardsService
                                    .updateCoinSpent(this.userId, response.id, productId, this.coinsUsed)
                                    .subscribe({
                                        next: () => {
                                            this.snackBar.open(rewardCoins.coinsSpentSucessfully, "Close", {
                                                duration: 3000,
                                            });
                                        },
                                        error: (err) => {
                                            this.snackBar.open(rewardCoins.coinSpentFailed, "Close", {
                                                duration: 3000,
                                            });
                                        },
                                    });
                            }
                        }

                        if (this.appliedCoupon && this.mobileNumber) {
                            this.couponsService.markCouponAsUsed(this.appliedCoupon, this.mobileNumber).subscribe({
                                next: () => {
                                    this.snackBar.open(CouponsMessage.couponApplied, "Close", { duration: 3000 });
                                },
                                error: (error) => {
                                    this.snackBar.open(error.message, "Close", { duration: 3000 });
                                },
                            });
                        }

                        this.router.navigate(["order/success"], {
                            queryParams: { orderId: response.id },
                        });
                    },
                    error: (error) => {
                        alert(error.error);
                    },
                });
        });
    }

    private validateOrderCreation = (response: OrderAttributes): void => {
        if (!response.id) {
            this.loading = false;
            throw new Error(OrderErrorMessage.OrderNotCreated); // Custom error message
        }
    };

    preparePhonePePaymentData(newOrder: OrderAttributes): Observable<InitiatePhonePePaymentInput> {
        const paymentData: InitiatePhonePePaymentInput = {
            orderId: newOrder.id.toString(),
            mobileNumber: this.currentUser?.userMobileNumber!,
            amount: this.total,
        };

        return of(paymentData);
    }

    private preparePaymentData(newOrder: OrderAttributes): Observable<InitiatePaymentInput> {
        const generatedGuid: string = uuidv4();
        const paymentData: InitiatePaymentInput = {
            orderId: `${environment.name}-${newOrder.id.toString()}-${generatedGuid}`,
            userInfo: {
                custId: newOrder.customerMobile,
            },
            callbackUrl: environment.apiUrlBasePath + environment.payment.paytmConfig.merchant.callbackUrl,
            txnAmount: {
                value: newOrder.totalAmount.toString(),
                currency: "INR",
            },
        };

        return of(paymentData);
    }

    private handleInitiatePaymentResponse = (response: InitiatePaymentResponse): void => {
        if (response.body.txnToken) {
            this.navigateToCheckout(response);
        } else {
            this.loading = false; // Stop spinner
            throw new Error(response.body.resultInfo.resultMsg); // Display error message
        }
    };

    private navigateToCheckout(response: InitiatePaymentResponse): void {
        this.router.navigate(["payment"], {
            queryParams: {
                orderId: response.body.orderId,
                txnToken: response.body.txnToken,
                totalAmount: response.body.amount,
            },
        });
    }

    private handleError = (error: Error): Observable<never> => {
        this.loading = false;
        this.openSnackBar(error.message, "Okay");
        return of();
    };

    private openSnackBar(message: string, action: string): void {
        this.snackBar.open(message, action, {
            horizontalPosition: "center",
            verticalPosition: "top",
            duration: 5000,
        });
    }

    public numericOnly(event: KeyboardEvent): boolean {
        let patt = /^([0-9])$/;
        let result = patt.test(event.key);
        return result;
    }

    public onLogin(): void {
        this.router.navigate(["login"]);
    }

    public onManageAddress(): void {
        if (!this.isLoggedIn) {
            this.openDialogForGuestUser();
            return;
        }

        if (this.selectedAddress) {
            this.openChangeAddressDialog();
        } else {
            this.openAddAddressDialog(!this.currentUser);
        }
    }

    private openChangeAddressDialog(): void {
        const data = {
            componentName: DialogInvokingComponents.ChangeAddress,
            title: "Select Delivery Address",
            addresses: this.currentUserAddresses,
            selectedAddressId: this.selectedAddress?.id,
        };
        this.dialogService
            .openDialog(data)
            .afterClosed()
            .subscribe((response) => this.handleDialogResponse(response));
    }

    private openAddAddressDialog(withUserProfileDetails: boolean): void {
        const data = {
            componentName: DialogInvokingComponents.AddAddress,
            title: "Add Delivery Address",
            withUserProfileDetails,
            hideAgeAndShowMobile: false,
        };
        this.dialogService
            .openDialog(data)
            .afterClosed()
            .subscribe((response) => this.handleDialogResponse(response));
    }

    private openDialogForGuestUser(): void {
        const data = {
            componentName: DialogInvokingComponents.AddAddress,
            title: "Add Delivery Address",
            withUserProfileDetails: true,
            hideAgeAndShowMobile: true,
        };
        this.dialogService
            .openDialog(data)
            .afterClosed()
            .subscribe((response) => {
                if (response) {
                    this.checkoutService.setUserInLocalStorage(response.userDetails);
                    this.checkoutService.setAddressInLocalStorage(response.addressDetails);
                    this.getGuestUserDetails();
                }
            });
    }

    private handleDialogResponse(
        response:
            | number
            | {
                  userDetails?: UserDetailsCreationAttributes;
                  addressDetails: AddressDetailsCreationAttributes;
              },
    ): void {
        if (!response) return;

        if (typeof response === "number") {
            this.selectAddressById(response);
        } else if (response.userDetails && response.addressDetails) {
            this.addUserDetailsAndSelectNewAddress(response.userDetails, response.addressDetails);
        } else {
            this.addAddressAndSelectNewAddress(response.addressDetails);
        }
    }

    private selectAddressById(id: number): void {
        const address = this.currentUserAddresses!.find((address) => address!.id === id);
        if (address) {
            this.selectedAddress = address;
        }
    }

    private addUserDetailsAndSelectNewAddress(
        profile: UserDetailsCreationAttributes,
        newAddress: AddressDetailsCreationAttributes,
    ): void {
        this.userService
            .addUserDetails({
                userDetails: profile,
                addressDetails: newAddress,
            })
            .subscribe((response) => {
                if (response) {
                    this.openSnackBar(UserDetailStatus.Add, "Close");
                }
                this.getRegisteredUserDetails();
            });
    }

    private addAddressAndSelectNewAddress(newAddress: AddressDetailsCreationAttributes): void {
        this.userService.addAddress(newAddress).subscribe((newAddress) => {
            if (newAddress) {
                this.openSnackBar(UserDetailStatus.Add, "Close");
            }
            this.getRegisteredUserDetails();
        });
    }

    public isOverflow(el: HTMLElement): boolean {
        return el.clientWidth < el.scrollWidth || el.clientHeight < el.scrollHeight;
    }

    //For future use
    public onTermsAndCondition(): void {
        this.router.navigate(["/termCondition"]);
    }

    //For future use
    public onPrivacyPolicy(): void {
        this.router.navigate(["/privacy"]);
    }

    public getRewardsData(): void {
        this.userService
            .getUserDetails()
            .pipe(
                switchMap((response) => {
                    if (response) {
                        const userId = response.id;
                        return this.rewardsService.getRewardsWithTotalCountForUser(
                            userId,
                            this.pageIndex,
                            this.pageSize,
                        );
                    }
                    return of(null);
                }),
            )
            .subscribe((data) => {
                if (data) {
                    this.rewards = data.rows;
                    this.totalCoinBalance = this.calculateTotalBalance(data.totalCoinsEarned, data.totalCoinsSpent);
                    this.totalRewardsCount = data.totalCount;
                    this.updateTotal();
                }
            });
    }

    public calculateTotalBalance(totalCoinsEarned: number, totalCoinsSpent: number): number {
        return totalCoinsEarned - totalCoinsSpent;
    }

    public updateTotal(): void {
        const afterDiscount = this.subtotal - this.discount;
        const couponDiscountValue = (afterDiscount * this.couponDiscountPercentage) / 100;
        this.couponsValue = Math.min(couponDiscountValue, this.maxAmount);
        const effectiveSubtotal = Math.max(0, afterDiscount - this.couponsValue);
        const deliveryChargeValue = this.deliveryCharge || 0;
        this.coinsUsed = this.isUsingCoins ? Math.min(this.totalCoinBalance, effectiveSubtotal) : 0;
        const totalCalculated = afterDiscount - this.coinsUsed - this.couponsValue + deliveryChargeValue;
        this.total = Math.max(0, totalCalculated);
    }

    public onUseCoinsChange(event: MatCheckboxChange): void {
        //this.isCheckboxChecked = event.checked;
        this.isUsingCoins = event.checked;
        const effectiveSubtotal = Math.max(0, this.subtotal - this.discount - (this.maxAmount || 0));
        this.coinsUsed = this.isUsingCoins ? Math.min(this.totalCoinBalance, effectiveSubtotal) : 0;
        this.updateTotal();
    }

    public updateRewardData(newOrder: Order): void {
        this.userService.getUserDetails().subscribe((response) => {
            if (response) {
                if (this.coinsUsed > 0 && newOrder?.id && response.id) {
                    const userId = response.id;
                    const coinsSpent = this.coinsUsed;
                    const orderId = newOrder.id;
                    const productId = newOrder.productId || null;

                    this.rewardsService.updateCoinSpent(userId, orderId, productId, coinsSpent).subscribe({
                        next: () => {
                            this.getRewardsData();
                        },
                    });
                }
            }
        });
    }

    public removeCoupon(): void {
        this.appliedCoupon = null;
        this.maxAmount = 0;
        this.updateTotal();
        this.couponForm.get("couponCode")?.setValue("");
        this.couponForm.markAsPristine();
    }

    public fetchAvailableCoupons(mobileNumber: number): void {
        this.couponsService.getCoupons(this.pageIndex, this.pageSize, mobileNumber).subscribe({
            next: (response) => {
                this.availableCoupons = response.rows;
                this.totalCouponsCount = response.count;
            },
        });
    }

    public openCouponSidebar(): void {
        this.showCouponSidebar = !this.showCouponSidebar;
    }

    public closeCouponSidebar(): void {
        this.showCouponSidebar = false;
    }

    public copyCouponCode(couponCode: string): void {
        navigator.clipboard.writeText(couponCode).then(() => {
            this.snackBar.open(CouponsMessage.couponsSnackBar, "close", {
                duration: 2000,
            });
        });
    }

    public applyCoupon(): void {
        const couponCode = this.couponForm.get("couponCode")?.value;
        const userId = this.mobileNumber;

        if (couponCode && userId) {
            this.couponsService.checkAndUseCoupon(couponCode, userId, this.productIds).subscribe({
                next: (response) => {
                    if (response.used) {
                        this.snackBar.open(CouponsMessage.couponUsed, "Close", { duration: 3000 });
                        this.isCouponUsed = true;
                    } else {
                        this.appliedCoupon = couponCode;
                        this.maxAmount = response.maxAmount;
                        this.couponDiscountPercentage = response.discountPercentage;
                        this.isCouponUsed = false;
                        this.isCouponInvalid = false;
                        this.snackBar.open(CouponsMessage.couponApplied, "Close", { duration: 3000 });
                        this.updateTotal();
                    }
                },
                error: (error) => {
                    this.snackBar.open(CouponsMessage.couponNotFound, "Close", { duration: 3000 });
                    this.isCouponInvalid = true;
                },
            });
        }
    }

    public applyCouponFromSidebar(couponCode: string): void {
        this.couponForm.get("couponCode")?.setValue(couponCode);
        this.applyCoupon();
        this.closeCouponSidebar();

        if (!this.appliedCouponCodes.includes(couponCode)) {
            this.appliedCouponCodes.push(couponCode);
        }
    }

    public getFacesByType(data: any, type: string): string[] {
        return this.cartService.getFacesByType(data, type);
    }

    public getShapeColorName(data: any): string {
        return this.cartService.getColorName(data);
    }

    public getShapeColorValue(data: any): string {
        return this.cartService.getColorValue(data);
    }
    public minOrder(): boolean {
        return this.subtotal < this.minOrderValue - 1;
    }
}
