import { Component, NgZone, OnInit } from "@angular/core";
import { CheckoutService } from "paytm-blink-checkout-angular";
import { Subscription } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { PaymentTransactionStatus, PaytmCallbackData, PaytmCheckoutConfig } from "src/models/payment.model";
import { PaymentService } from "../../services/payment/payment.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { transformPropsToLowerCase } from "@utils/jsonObject.helper";
import { CartItemService } from "src/app/services/cart-item/cart-item.service";
import { AuthService } from "src/app/services/auth/auth.service";

@Component({
    selector: "app-paytm-payment",
    templateUrl: "./paytm-payment.component.html",
    styleUrls: ["./paytm-payment.component.scss"],
})
export class PaytmPaymentComponent implements OnInit {
    private subs: Subscription;
    private orderId!: string;
    private txnToken!: string;
    private totalAmount!: string;
    private openInPopup = false;

    constructor(
        private checkoutService: CheckoutService,
        private paymentService: PaymentService,
        private cartService: CartItemService,
        private route: ActivatedRoute,
        private router: Router,
        private ngZone: NgZone,
        private _snackBar: MatSnackBar,
        private authService: AuthService,
    ) {
        this.subs = this.checkoutService.checkoutJsInstance$.subscribe();
    }

    ngOnInit(): void {
        this.route.queryParams.subscribe((params) => {
            this.orderId = params["orderId"];
            this.txnToken = params["txnToken"];
            this.totalAmount = params["totalAmount"];
        });
        this.initCheckoutService();
    }

    initCheckoutService(): void {
        const config = this.appendHandler(
            environment.payment.paytmConfig,
            this.orderId,
            this.txnToken,
            this.totalAmount,
        );
        this.checkoutService.init(config, {
            env: "STAGE",
            openInPopup: this.openInPopup,
        });
    }

    appendHandler(
        config: PaytmCheckoutConfig,
        orderId: string,
        txnToken: string,
        totalAmount: string,
    ): PaytmCheckoutConfig {
        const newConfig = { ...config };

        newConfig.data = {
            orderId: orderId,
            token: txnToken,
            tokenType: "TXN_TOKEN",
            amount: totalAmount,
        };

        newConfig.handler = {
            notifyMerchant: this.notifyMerchantHandler,
            transactionStatus: this.transactionStatusHandler,
        };

        return newConfig;
    }

    notifyMerchantHandler = (eventType: string, data: string | {}): void => {
        console.log("MERCHANT NOTIFY LOG", eventType, data);
    };

    transactionStatusHandler = (data: PaytmCallbackData): void => {
        this.ngZone.run(() => {
            // Verify Callback Response and Update Order
            const processedData = transformPropsToLowerCase(data);
            this.paymentService.verifyCallbackResponse(processedData).subscribe((isVerifySignature) => {
                if (isVerifySignature) {
                    processedData.orderid = processedData.orderid.split("-")[1];
                    if (processedData.status === PaymentTransactionStatus.Failed) {
                        this.openSnackBar(processedData.respmsg, "Okay");
                        this.router.navigate(["checkout"]);
                    } else if (processedData.status === PaymentTransactionStatus.Success) {
                        // if payment is successful, for both registered and guest users, remove cart items from localstorage
                        localStorage.removeItem("productDetail");

                        // if payment is successful and for those registered users, remove cart items from  db.
                        if (this.authService.isUserLoggedIn) {
                            this.cartService.removeAllCartItems().subscribe();
                        }

                        this.router.navigate(["order/success"], { state: processedData });
                    } else if (processedData.status === PaymentTransactionStatus.Pending) {
                        this.openSnackBar(processedData.respmsg, "Okay");
                        this.router.navigate(["trackOrder"]);
                    }
                }
                //if checksum mismatched
                else {
                    this.openSnackBar("Something went wrong !!", "Okay");
                    this.router.navigate(["checkout"]);
                }
            });
        });
    };

    private openSnackBar(message: string, action: string): void {
        this._snackBar.open(message, action, {
            horizontalPosition: "center",
            verticalPosition: "top",
            duration: 5000,
        });
    }

    ngOnDestroy(): void {
        if (this.subs) {
            this.subs.unsubscribe();
        }
    }
}
