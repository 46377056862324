import * as THREE from "three";
import { ColorRepresentation } from "three";
import { CanvasConfig } from "../shape-selector/shape-selector.model";
import { createShapeConfig } from "./shape.config.model";
import { ShapeProperty } from "./shape.model";

export const shapeProperty = (shapeType: string, color: ColorRepresentation, isForSelector: boolean): ShapeProperty => {
    const shapeConfigs = createShapeConfig(color, isForSelector);

    const commonProperties = {
        material: isForSelector
            ? new THREE.MeshStandardMaterial({
                  color: shapeConfigs[shapeType].color,
                  transparent: true,
                  opacity: CanvasConfig.transparentOpacity,
                  side: THREE.DoubleSide, // we do this only for selector
              })
            : new THREE.MeshBasicMaterial({ color: color as ColorRepresentation, side: THREE.DoubleSide }),
        offSetCoordinates: shapeConfigs[shapeType].offSetCoordinates,
    };

    if (
        shapeConfigs[shapeType].width !== undefined &&
        shapeConfigs[shapeType].height !== undefined &&
        shapeConfigs[shapeType].depth !== undefined
    ) {
        return {
            geometry: new THREE.BoxGeometry(
                shapeConfigs[shapeType].width,
                shapeConfigs[shapeType].height,
                shapeConfigs[shapeType].depth,
            ),
            ...commonProperties,
        };
    } else if (shapeConfigs[shapeType].extrudeSetting !== undefined) {
        let geometry = new THREE.ExtrudeGeometry(
            getCustomShape(shapeConfigs[shapeType]),
            shapeConfigs[shapeType].extrudeSetting,
        );
        geometry.center();
        return {
            geometry,
            ...commonProperties,
        };
    } else if (
        shapeConfigs[shapeType].radius !== undefined &&
        shapeConfigs[shapeType].height !== undefined &&
        shapeConfigs[shapeType].radialSegments !== undefined &&
        shapeConfigs[shapeType].heightSegments !== undefined
    ) {
        return {
            geometry: new THREE.ConeGeometry(
                shapeConfigs[shapeType].radius,
                shapeConfigs[shapeType].height,
                shapeConfigs[shapeType].radialSegments,
                shapeConfigs[shapeType].heightSegments,
                false,
                shapeConfigs[shapeType].thetaStart,
            ),
            ...commonProperties,
        };
    } else if (
        shapeConfigs[shapeType].radiusTop !== undefined &&
        shapeConfigs[shapeType].radiusBottom !== undefined &&
        shapeConfigs[shapeType].height !== undefined &&
        shapeConfigs[shapeType].radialSegments !== undefined &&
        shapeConfigs[shapeType].heightSegments !== undefined
    ) {
        return {
            geometry: new THREE.CylinderGeometry(
                shapeConfigs[shapeType].radiusTop,
                shapeConfigs[shapeType].radiusBottom,
                shapeConfigs[shapeType].height,
                shapeConfigs[shapeType].radialSegments,
                shapeConfigs[shapeType].heightSegments,
            ),
            ...commonProperties,
        };
    } else if (
        shapeConfigs[shapeType].radius !== undefined &&
        shapeConfigs[shapeType].widthSegments !== undefined &&
        shapeConfigs[shapeType].heightSegments !== undefined &&
        shapeConfigs[shapeType].phiStart !== undefined &&
        shapeConfigs[shapeType].phiEnd !== undefined &&
        shapeConfigs[shapeType].thetaStart !== undefined &&
        shapeConfigs[shapeType].thetaEnd !== undefined &&
        shapeConfigs[shapeType].capRadius !== undefined &&
        shapeConfigs[shapeType].capRadiusSegments !== undefined
    ) {
        const sphereGeom = new THREE.SphereGeometry(shapeConfigs[shapeType].radius, 30, 30);

        const positionAttribute = sphereGeom.attributes["position"] as THREE.BufferAttribute;

        if (positionAttribute && positionAttribute.array) {
            const verts = positionAttribute.array as Float32Array;
            for (let i = 0; i < verts.length; i += 3) {
                if (verts[i + 1] < 0) {
                    verts[i + 1] = 0;
                }
            }
        }

        return {
            geometry: sphereGeom,
            ...commonProperties,
        };
    } else {
        throw new Error(`Invalid shape configuration: ${JSON.stringify(shapeConfigs[shapeType])}`);
    }
};

/*
    For any addition in the custom shape, we must add the config and then modify here.
*/
const getCustomShape = (shapeConfig: any): THREE.Shape => {
    const shape = new THREE.Shape();
    if (shapeConfig.firstLine && shapeConfig.lastLine) {
        shape.lineTo(shapeConfig?.firstLine.x, shapeConfig?.firstLine.y);
        shape.absarc(
            shapeConfig?.arc.x,
            shapeConfig?.arc.y,
            shapeConfig?.arc.radius,
            shapeConfig?.arc.startAngle,
            shapeConfig?.arc.endAngle,
            shapeConfig?.arc.isClockwise,
        );
        shape.lineTo(shapeConfig.lastLine.x, shapeConfig.lastLine.x);
    } else if (shapeConfig.arc) {
        shape.absarc(
            shapeConfig?.arc.x,
            shapeConfig?.arc.y,
            shapeConfig?.arc.radius,
            shapeConfig?.arc.startAngle,
            shapeConfig?.arc.endAngle,
            shapeConfig?.arc.isClockwise,
        );
    } else if ((shapeConfig.moveTo && shapeConfig.firstLine && shapeConfig.secondLine) || shapeConfig.thirdLine) {
        shape.moveTo(shapeConfig.moveTo.x, shapeConfig.moveTo.y);
        shape.lineTo(shapeConfig?.firstLine.x, shapeConfig?.firstLine.y);
        shape.lineTo(shapeConfig?.secondLine.x, shapeConfig?.secondLine.y);
        shape.lineTo(shapeConfig?.thirdLine?.x, shapeConfig?.thirdLine?.y);
        shape.lineTo(shapeConfig?.fourthLine?.x, shapeConfig?.fourthLine?.y);
    }

    return shape;
};
