import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FooterComponent } from "../footer/footer.component";
import { DataGridComponent } from "../data-grid/data-grid.component";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatTableModule } from "@angular/material/table";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";

@NgModule({
    declarations: [FooterComponent, DataGridComponent],
    imports: [
        CommonModule,
        MatProgressSpinnerModule,
        MatPaginatorModule,
        MatTableModule,
        MatTooltipModule,
        MatIconModule,
        MatMenuModule,
        MatSlideToggleModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatButtonModule,
        MatCheckboxModule,
    ],
    exports: [FooterComponent, DataGridComponent],
})
export class SharedModule {}
