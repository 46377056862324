import * as i0 from '@angular/core';
import { InjectionToken, Injectable, Inject, Component, NgModule } from '@angular/core';
import { DOCUMENT, CommonModule } from '@angular/common';
import { ReplaySubject, Subscription } from 'rxjs';
const CONSTANTS = {
  PROJECT_NAME: 'Paytm Blink Checkout JS',
  ENV: {
    PROD: 'PROD',
    STAGE: 'STAGE'
  },
  HOSTS: {
    PROD: 'https://securegw.paytm.in',
    STAGE: 'https://securegw-stage.paytm.in'
  },
  LINKS: {
    CHECKOUT_JS_URL: '/merchantpgpui/checkoutjs/merchants/'
  },
  ERRORS: {
    INIT: 'An error during initialization!',
    INVOKE: 'An error during invoking!',
    MERCHANT_ID_NOT_FOUND: 'Please provide merchant id!',
    CHECKOUT_NOT_AVAILABLE: 'Checkout JS library not found. Please make sure you have included checkout js!',
    INVALID_CHECKOUT_JS_INSTANCE: 'Invalid instance provided!'
  },
  IDS: {
    CHECKOUT_ELEMENT: 'checkout-wrapper-'
  }
};
// Prefix error with project name
Object.keys(CONSTANTS.ERRORS).forEach(errorCode => {
  CONSTANTS.ERRORS[errorCode] = `${CONSTANTS.PROJECT_NAME}: ${CONSTANTS.ERRORS[errorCode]}`;
});
const WINDOW_TOKEN = new InjectionToken('Window', {
  providedIn: 'root',
  factory: () => window
});
class ICheckoutOptions {}
class CheckoutOptions {
  constructor(env, openInPopup, checkoutJsInstance) {
    this.env = env;
    this.openInPopup = openInPopup;
    this.checkoutJsInstance = checkoutJsInstance;
  }
  static from(options) {
    options = options || {};
    const env = options.env || CONSTANTS.ENV.PROD;
    const openInPopup = typeof options.openInPopup === 'boolean' ? options.openInPopup : true;
    return new CheckoutOptions(env, openInPopup, options.checkoutJsInstance);
  }
}
class CheckoutService {
  constructor(window, document) {
    this.window = window;
    this.document = document;
    this.openInPopup = true;
    this.isScriptLoaded = false;
    this.isScriptLoading = false;
    this.checkoutJsContainerId = CONSTANTS.IDS.CHECKOUT_ELEMENT + new Date().getTime();
    this.checkoutJsInstanceSource$ = new ReplaySubject(1);
    this.checkoutJsInstance$ = this.checkoutJsInstanceSource$.asObservable();
    this.setupCheckoutJs = () => {
      const checkoutJsInstance = this.getCheckoutJsObj();
      if (checkoutJsInstance && checkoutJsInstance.onLoad) {
        checkoutJsInstance.onLoad(() => {
          this.isScriptLoaded = true;
          this.isScriptLoading = false;
          this.initializeCheckout();
        });
      }
    };
    this.initializeCheckout = () => {
      const checkoutJsInstance = this.getCheckoutJsObj();
      if (checkoutJsInstance && checkoutJsInstance.init && checkoutJsInstance.invoke) {
        checkoutJsInstance.init({
          ...this.config,
          root: this.openInPopup ? '' : `#${this.checkoutJsContainerId}`
        }).then(_ => {
          this.checkoutJsInstanceSource$.next(checkoutJsInstance);
        }).catch(error => {
          console.error(CONSTANTS.ERRORS.INIT, error);
        });
      } else {
        console.error(CONSTANTS.ERRORS.INVALID_CHECKOUT_JS_INSTANCE);
      }
    };
  }
  init(config, options) {
    options = CheckoutOptions.from(options);
    const merchantId = config && config.merchant && config.merchant.mid;
    if (merchantId) {
      const prevMerchantId = this.config && this.config.merchant && this.config.merchant.mid;
      this.config = config;
      this.openInPopup = options.openInPopup;
      if (options.checkoutJsInstance) {
        this.receivedCheckoutJsInstance = options.checkoutJsInstance;
        this.checkoutJsInstanceSource$.next(options.checkoutJsInstance);
      }
      if ((options.checkoutJsInstance || this.isScriptLoaded) && merchantId === prevMerchantId) {
        this.initializeCheckout();
      } else if (!this.isScriptLoading || prevMerchantId && merchantId !== prevMerchantId) {
        this.loadCheckoutScript(merchantId, options.env);
      }
    } else {
      console.error(CONSTANTS.ERRORS.MERCHANT_ID_NOT_FOUND);
    }
  }
  loadCheckoutScript(merchantId, env) {
    this.isScriptLoaded = false;
    this.isScriptLoading = true;
    const scriptElement = this.document.createElement('script');
    scriptElement.async = true;
    scriptElement.src = CONSTANTS.HOSTS[env] + CONSTANTS.LINKS.CHECKOUT_JS_URL.concat(merchantId);
    scriptElement.type = 'application/javascript';
    scriptElement.onload = this.setupCheckoutJs;
    scriptElement.onError = () => {
      this.isScriptLoading = false;
    };
    this.document.body.appendChild(scriptElement);
  }
  getCheckoutJsObj() {
    const window = this.window;
    if (this.receivedCheckoutJsInstance) {
      return this.receivedCheckoutJsInstance;
    }
    if (window && window.Paytm && window.Paytm.CheckoutJS) {
      return window.Paytm.CheckoutJS;
    }
    console.warn(CONSTANTS.ERRORS.CHECKOUT_NOT_AVAILABLE);
    return null;
  }
  ngOnDestroy() {
    this.checkoutJsInstanceSource$.complete();
  }
}
CheckoutService.ɵfac = function CheckoutService_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || CheckoutService)(i0.ɵɵinject(WINDOW_TOKEN), i0.ɵɵinject(DOCUMENT));
};
CheckoutService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: CheckoutService,
  factory: CheckoutService.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CheckoutService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: Inject,
        args: [WINDOW_TOKEN]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [DOCUMENT]
      }]
    }];
  }, null);
})();
class CheckoutComponent {
  constructor(checkoutService) {
    this.checkoutService = checkoutService;
    this.subs = new Subscription();
  }
  ngAfterViewInit() {
    this.subs = this.checkoutService.checkoutJsInstance$.subscribe(this.invoke);
  }
  invoke(checkoutJsInstance) {
    if (checkoutJsInstance && checkoutJsInstance.invoke) {
      try {
        checkoutJsInstance.invoke();
      } catch (error) {
        console.error(CONSTANTS.ERRORS.INVOKE, error);
      }
    }
  }
  ngOnDestroy() {
    if (this.subs) {
      this.subs.unsubscribe();
    }
  }
}
CheckoutComponent.ɵfac = function CheckoutComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || CheckoutComponent)(i0.ɵɵdirectiveInject(CheckoutService));
};
CheckoutComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: CheckoutComponent,
  selectors: [["paytm-checkout"]],
  decls: 2,
  vars: 1,
  consts: [[3, "id"]],
  template: function CheckoutComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "div", 0);
      i0.ɵɵelement(1, "div");
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵproperty("id", ctx.checkoutService.checkoutJsContainerId);
    }
  },
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CheckoutComponent, [{
    type: Component,
    args: [{
      selector: 'paytm-checkout',
      template: `<div [id]="checkoutService.checkoutJsContainerId"><div>`
    }]
  }], function () {
    return [{
      type: CheckoutService
    }];
  }, null);
})();
class CheckoutModule {}
CheckoutModule.ɵfac = function CheckoutModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || CheckoutModule)();
};
CheckoutModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: CheckoutModule
});
CheckoutModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [CommonModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CheckoutModule, [{
    type: NgModule,
    args: [{
      declarations: [CheckoutComponent],
      imports: [CommonModule],
      exports: [CheckoutComponent]
    }]
  }], null, null);
})();

/*
 * Public API Surface of paytm-blink-checkout-angular
 */

/**
 * Generated bundle index. Do not edit.
 */

export { CheckoutComponent, CheckoutModule, CheckoutService };
