import { Component, OnInit } from "@angular/core";
import { RewardsService } from "./rewards.service";
import { UserService } from "../services/user/user.service";
import { Router } from "@angular/router";
import { RewardDetail, RewardType } from "@models/reward.model";
import { environment } from "../../environments/environment";

@Component({
    selector: "app-rewards",
    templateUrl: "./rewards.component.html",
    styleUrls: ["./rewards.component.scss"],
})
export class RewardsComponent implements OnInit {
    public rewards: RewardDetail[] = [];
    public totalBalance: number = 0;
    public totalRewards = 0;
    public userId!: number;
    public loading: boolean = true;
    public pageIndex = environment.dynamicPagination.pageIndex;
    public pageSize = environment.dynamicPagination.pageSize;
    public totalRewardsCount!: number;
    public RewardType = RewardType;

    constructor(
        private rewardsService: RewardsService,
        private userService: UserService,
        private router: Router,
    ) {}

    ngOnInit() {
        this.userService.getUserDetails().subscribe((response) => {
            if (response) {
                this.userId = response.id;
                this.getRewardsData(this.userId);
            }
        });
    }

    public getRewardsData(userId: number): void {
        this.loading = true;
        this.rewardsService.getRewardsWithTotalCountForUser(userId, this.pageIndex, this.pageSize).subscribe(
            (data) => {
                this.rewards = [...this.rewards, ...data.rows];
                this.totalRewardsCount = data.totalCount;
                this.totalBalance = this.calculateTotalBalance(data.totalCoinsEarned, data.totalCoinsSpent);
                this.loading = false;
            },
            (error) => {
                this.loading = false;
            },
        );
    }

    public calculateTotalBalance(totalCoinsEarned: number, totalCoinsSpent: number): number {
        return totalCoinsEarned - totalCoinsSpent;
    }

    public showViewMoreButton(): boolean {
        return this.rewards.length < this.totalRewardsCount && this.rewards.length >= this.pageSize;
    }

    public loadMoreRewards(): void {
        if (this.pageIndex * this.pageSize < this.totalRewardsCount) {
            this.pageIndex++;
            this.getRewardsData(this.userId);
        }
    }

    public goToHomePage(): void {
        this.router.navigate(["/home"]);
    }

    public getDisplayName(reward: RewardDetail): string {
        return reward.name || "Unknown Product";
    }
}
