import { TableColumn } from "./data-grid.model";

export const CouponListColumns: TableColumn[] = [
    { key: "action", value: "Action", type: "action" },
    { key: "couponCode", value: "Coupon Code", type: "text" },
    { key: "discountPercentage", value: "Discount Percentage", type: "percentage" },
    { key: "discountType", value: "Discount Type", type: "text" },
    { key: "description", value: "Description", type: "text" },
    { key: "createdAt", value: "Created At", type: "datetime" },
    { key: "updatedAt", value: "Updated At", type: "datetime" },
    { key: "isActive", value: "Active", type: "toggle" },
];

export interface Coupon {
    id?: number;
    couponCode: string;
    discountPercentage: number;
    discountType: object;
    userId?: object;
    isActive: boolean;
    maxAmount?: number;
    isUsed?: { userIds: string[] };
    description?: string;
    createdBy?: string;
    updatedBy?: string;
}

export enum CouponsMessage {
    couponsSnackBar = "Coupon code copied to clipboard!",
    couponNotFound = "Coupon not found!",
    couponUsed = "Coupon has already been used",
    couponFailed = "Failed to fetch notifications",
    couponFailedToUse = "Failed to mark coupon as used. Please try again.",
    Copied = "Copied Successfully",
    couponApplied = "Coupon applied successfully",
    couponUsedByUser = "Coupon already used by this user",
    couponMarkasUse = "Coupon successfully marked as used",
    failedToUse = "Failed to mark coupon as used",
    useIdrequired = "couponCode and userId are required",
    notActive = "Coupon is not active",
    notAssinged = "This coupon is not assigned to the current user.",
    failedToUseCheck = "Failed to check and use coupon",
    noUserFound = "No users found with the given mobile numbers.",
    errorSendingNotification = "Error sending coupon notification",
    noActionRequired = "This coupon is not user-specific.",
}

export interface couponEmailContent {
    couponCode: string | undefined;
    discountPercentage: number | undefined;
    maxAmount?: number;
    description?: string;
    message: string;
    creditedOn?: string | undefined;
    imageUrl?: string;
}
