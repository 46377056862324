<div class="container">
    <nav class="navigation-bar">
        <span class="nav-left">
            <!-- Burger button -->
            <button mat-icon-button color="accent" aria-label="Menu" class="menu-button" (click)="toggleMenu()">
                <mat-icon class="menu-icon">menu</mat-icon>
            </button>

            <!-- Logo -->
            <span class="company-logo">
                <a routerLink="/home">
                    <!-- <mat-icon svgIcon="funFigz" class="funFigz-logo" aria-hidden="false" aria-label="funFigz"></mat-icon> -->
                    <img class="funFigz-logo" [src]="productLogo" alt="Image preview" />
                </a>
            </span>

            <!-- Navbar links -->
            <span class="navbar-links-left" #navbarLinks>
                <ul class="navbar-left">
                    <li *ngFor="let link of navLinks" class="navbar-item" routerLinkActive="active">
                        <a href="#" [routerLink]="link.route">{{ link.label }}</a>
                    </li>
                </ul>
            </span>
        </span>

        <div class="search-content" *ngIf="isHomePage">
            <div class="search-product">
                <input
                    placeholder=""
                    class="search-input"
                    id="searchInput"
                    autocomplete="off"
                    (keyup.enter)="onSearchClick(searchInput.value)"
                    (input)="onSearchInputChange($event)"
                    #searchInput
                /><span
                    ><mat-icon class="search-icon" (click)="onSearchClick(searchInput.value)">search</mat-icon></span
                >
            </div>
        </div>

        <span class="navbar-links-right">
            <ul class="navbar-right">
                <li *ngFor="let link of rightNavLinks" class="navbar-item" [routerLinkActive]="['active']">
                    <a [routerLink]="link.route">
                        <mat-icon class="icon"> {{ link.icon }} </mat-icon>
                        <span class="label-text">{{ link.label }}</span
                        >&nbsp;
                        <span class="label-count" *ngIf="link.count">{{ "(" + link.count + ")" }}</span>
                    </a>
                </li>

                <li *ngIf="isUserLogin" class="navbar-item">
                    <a [matTooltip]="userDetails?.user?.name || ''" [matMenuTriggerFor]="menu">
                        <mat-icon class="profile-icon" *ngIf="!showProfile; else profileImage">
                            {{ accountLink.icon }}
                        </mat-icon>
                        <ng-template #profileImage>
                            <img class="profile-image" [src]="userDetails?.user?.profileImage" alt="Image not found" />
                        </ng-template>
                    </a>
                </li>
                <mat-menu #menu="matMenu">
                    <ng-container *ngFor="let menuItem of menuItems">
                        <button mat-menu-item (click)="menuItem.action()">{{ menuItem.label }}</button>
                    </ng-container>
                </mat-menu>
            </ul>
        </span>
    </nav>

    <!-- Sidebar -->
    <div id="mySidenav" class="sidenav" [ngClass]="{ open: isMenuOpen }">
        <button mat-icon-button aria-label="Close Sidenav" class="closebtn" (click)="toggleMenu()">
            <mat-icon class="close-icon">close</mat-icon>
        </button>

        <ul class="navbar-list">
            <li *ngFor="let link of navLinks" class="navbar-item" routerLinkActive="active" (click)="toggleMenu()">
                <a [routerLink]="link.route">{{ link.label }}</a>
            </li>
        </ul>
    </div>
</div>
