import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from "@angular/router";
import { AuthService } from "./auth/auth.service";
import { Observable } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class AuthGuard implements CanActivate {
    constructor(private authService: AuthService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        // Check for admin routes
        if (state.url.startsWith("/admin")) {
            return this.authService.handleAdminAccess(route);
        }

        // Handle user routes
        return this.authService.handleUserAccess(state.url);
    }
}
