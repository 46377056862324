import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import {
    NullableOrder,
    OrderAttributes,
    OrderInput,
    OrderPageResponse,
    OrderPageDetail,
    RefundStatus,
} from "@models/order.model";
import { AdminStatusHistoryModel } from "@models/adminStatusHistory.model";
import { ItemStatusHistoryModel } from "@models/itemStatusHistory.model";
import { TableDataResponse } from "@models/data-grid.model";

@Injectable({
    providedIn: "root",
})
export class OrderService {
    constructor(private readonly http: HttpClient) {}

    public createOrder(orderData: OrderInput, paymentOption?: string): Observable<OrderAttributes> {
        const data = { orderData, paymentOption };
        return this.http.post(`${environment.apiUrlBasePath}/orders/create`, data) as Observable<OrderAttributes>;
    }

    public getOrderById(orderId: string): Observable<OrderPageDetail> {
        return this.http.get<OrderPageDetail>(`${environment.apiUrlBasePath}/orders/${orderId}`);
    }

    public getOrder(
        pageIndex: number = environment.dynamicPagination.pageIndex,
        pageSize: number = environment.dynamicPagination.pageSize,
    ): Observable<OrderPageResponse> {
        return this.http.get<OrderPageResponse>(
            `${environment.apiUrlBasePath}/orders/mobileNumber?pageIndex=${pageIndex}&pageSize=${pageSize}`,
        );
    }

    sendHelpRequest(message: string, orderId: number): Observable<string> {
        const payload = { message, orderId };
        return this.http.post<string>(`${environment.apiUrlBasePath}/orders/helpRequest`, payload);
    }

    public addOrderStatus(params: {
        id: number;
        newStatus: string;
        date: Date;
        time: string;
        awbNumber: string;
        courierName: string;
        udrNumber: string;
        order: OrderPageDetail;
        itemIds: number[];
    }): Observable<string> {
        return this.http.post<string>(`${environment.apiUrlBasePath}/admin/updateOrderStatus/`, params);
    }

    public getAllOrders(
        pageIndex: number = environment.dynamicPagination.pageIndex,
        pageSize: number = environment.dynamicPagination.pageSize,
        status?: string,
    ): Observable<TableDataResponse> {
        return this.http.get<TableDataResponse>(
            `${environment.apiUrlBasePath}/admin/allOrders?pageIndex=${pageIndex}&pageSize=${pageSize}&status=${status}`,
        );
    }

    public getAllStatus(): Observable<string[]> {
        return this.http.get<string[]>(`${environment.apiUrlBasePath}/admin/getAllStatus`);
    }

    public getOrderDetails(id: number): Observable<OrderPageDetail> {
        return this.http.get<OrderPageDetail>(`${environment.apiUrlBasePath}/admin/getOrderDetails/${id}`);
    }

    public cancelOrder(orderId: number): Observable<{ message: string }> {
        return this.http.put<{ message: string }>(`${environment.apiUrlBasePath}/orders/${orderId}/cancel`, {});
    }

    public returnOrder(
        orderId: number,
        reason: string,
        refundOption?: string,
        accountHolderName?: string,
        accountNumber?: string,
        ifscCode?: string,
        upiId?: string,
    ): Observable<{ message: string }> {
        const body: {
            reason: string;
            refundOption?: string;
            accountHolderName?: string;
            accountNumber?: string;
            ifscCode?: string;
            upiId?: string;
        } = { reason, refundOption, accountHolderName, accountNumber, ifscCode, upiId };
        return this.http.put<{ message: string }>(`${environment.apiUrlBasePath}/orders/${orderId}/return`, body);
    }

    public exchangeOrder(orderId: number, reason: string): Observable<{ message: string }> {
        const body: { reason: string } = { reason };
        return this.http.put<{ message: string }>(`${environment.apiUrlBasePath}/orders/${orderId}/exchange`, body);
    }

    public cancelSelectedItem(orderId: string, itemIds: number[]): Observable<{ message: string }> {
        return this.http.put<{ message: string }>(
            `${environment.apiUrlBasePath}/orders/${orderId}/cancelSelectedItem`,
            itemIds,
        );
    }

    public returnSelectedItem(
        orderId: string,
        itemIds: number[],
        reason: string,
        refundOption?: string,
        accountHolderName?: string,
        accountNumber?: string,
        ifscCode?: string,
        upiId?: string,
    ): Observable<{ message: string }> {
        const data = { itemIds, reason, refundOption, accountHolderName, accountNumber, ifscCode, upiId };
        return this.http.put<{ message: string }>(
            `${environment.apiUrlBasePath}/orders/${orderId}/returnSelectedItem`,
            data,
        );
    }

    public exchangeSelectedItem(orderId: string, itemIds: number[], reason: string): Observable<{ message: string }> {
        const data = { itemIds, reason };
        return this.http.put<{ message: string }>(
            `${environment.apiUrlBasePath}/orders/${orderId}/exchangeSelectedItem`,
            data,
        );
    }

    public getOrderExceed7days(): Observable<number[]> {
        return this.http.get<number[]>(`${environment.apiUrlBasePath}/orders/allOrderExceedMoreThan7Days`);
    }

    getRefundStatusClass(refundStatus: string | undefined): string {
        switch (refundStatus) {
            case RefundStatus.RefundInitiated:
                return "refund-initiated";
            case RefundStatus.RefundProcessed:
                return "refund-processed";
            case RefundStatus.RefundSuccessful:
                return "refund-successful";
            case RefundStatus.RefundFailed:
                return "refund-failed";
            default:
                return "";
        }
    }

    public checkIsFromCart(orderId: number): Observable<{ isFromCart: boolean }> {
        return this.http.get<{ isFromCart: boolean }>(
            `${environment.apiUrlBasePath}/orders/checkIsFromCart/${orderId}`,
        );
    }
}
