import {
    AfterContentChecked,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    ViewChild,
} from "@angular/core";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { AdminModel, ReviewStatus, ToggleChangeEvent, ToggleEvent } from "@models/admin.model";
import { Action, DataGridConfig, TableColumn, TableDataResponse } from "@models/data-grid.model";
import { finalize, Observable, Subscription, tap } from "rxjs";
import { GenericDialogComponent } from "../generic-dialog/generic-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { StructureInstance, StructureStatus } from "@models/structure.model";
import { Router } from "@angular/router";
import { DialogInvokingComponents } from "@models/generic-dialog.model";
import { LoadStructureService } from "../../load-structure.service";
import { SelectionModel } from "@angular/cdk/collections";

@Component({
    selector: "app-data-grid",
    templateUrl: "./data-grid.component.html",
    styleUrls: ["./data-grid.component.scss"],
})
export class DataGridComponent implements AfterContentChecked, OnChanges {
    @ViewChild(MatPaginator) paginator!: MatPaginator;

    @Input() tableColumns!: TableColumn[];
    @Input() actions: Action[] = [];
    @Input() config!: DataGridConfig;
    @Input() selectedStatus!: string;

    @Output() pageChange: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
    @Output() toggleChange: EventEmitter<ToggleChangeEvent> = new EventEmitter<ToggleChangeEvent>();
    @Output() approveRejectData: EventEmitter<any> = new EventEmitter<any>();
    @Output() RowSelected = new EventEmitter<number[]>();
    @Output() Data = new EventEmitter<any>();

    public selection = new SelectionModel<any>(true, []);
    public dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
    public dataLoading: boolean = false;
    public dataTableSize = 0;
    public showPaginator = false;
    public subscription!: Subscription;
    public imageObject: Array<object> = [];
    public reviewStatus = ReviewStatus;
    private originalColumns: TableColumn[] = [];
    private columnsInitialized = false;
    public selectedIds: number[] = [];

    constructor(
        private cdr: ChangeDetectorRef,
        private dialog: MatDialog,
        private router: Router,
        private loadStructureService: LoadStructureService,
    ) {}

    ngAfterContentChecked(): void {
        this.cdr.detectChanges();
    }

    ngOnChanges(): void {
        this.selectedStatus = this.selectedStatus;
        if (this.tableColumns && !this.columnsInitialized) {
            this.originalColumns = [...this.tableColumns];
            this.columnsInitialized = true;
        }
    }

    public loadData(tableData: Observable<TableDataResponse>): void {
        if (tableData) {
            this.dataLoading = true;
            setTimeout(() => {
                if (this.paginator) {
                    this.paginator.pageIndex = this.config!.pageIndex;
                    this.paginator.length = this.dataTableSize;
                }
            });
            this.cdr.detectChanges();
            this.dataSource.data = [];

            if (this.subscription) {
                // in case there was one API call already, let's cancel it (to alleviate API requests)
                this.subscription.unsubscribe();
            }

            this.subscription = tableData
                .pipe(
                    finalize(() => {
                        this.dataLoading = false;
                    }),
                )
                .subscribe({
                    next: (data: TableDataResponse) => {
                        this.dataSource.data = data.rows;
                        this.Data.emit(this.dataSource.data);
                        this.dataTableSize = data.count;

                        if (this.config!.pageSizeOptions) {
                            this.showPaginator = this.dataTableSize > this.config!.pageSizeOptions[0];
                        }

                        setTimeout(() => {
                            if (this.paginator) {
                                this.paginator.pageIndex = this.config.pageIndex;
                                this.paginator.length = data.count;
                            }
                        });

                        this.updateColumnHeaders();
                    },
                    error: (error) => {
                        throw error;
                    },
                });
        } else {
            this.dataLoading = false;
            this.dataSource.data = [];
            this.showPaginator = false;
        }
    }

    private updateColumnHeaders(): void {
        // Clone the original columns
        this.tableColumns = [...this.originalColumns];

        // Update the 'adminUserId' column header based on status
        const adminColumn = this.tableColumns.find((col) => col.key === "admin.name");
        if (adminColumn) {
            if (this.selectedStatus === ReviewStatus.Pending || this.selectedStatus === StructureStatus.Published) {
                this.tableColumns = this.tableColumns.filter((col) => col.key !== "admin.name");
            } else {
                adminColumn.value =
                    this.selectedStatus === ReviewStatus.Approved || this.selectedStatus === StructureStatus.Approved
                        ? "Approved By"
                        : this.selectedStatus === ReviewStatus.Rejected ||
                          this.selectedStatus === StructureStatus.Rejected
                        ? "Rejected By"
                        : "Actioned By";
            }
        }
    }

    public getColumnProps(prop: keyof TableColumn): string[] {
        if (!this.tableColumns) {
            return [];
        }
        return this.tableColumns
            .filter((col: TableColumn) => {
                // Remove column if key is 'rejectComment' and status is not 'Rejected'
                return !(col.key === "rejectComment" && this.selectedStatus !== ReviewStatus.Rejected);
            })
            .map((col: TableColumn) => col[prop])
            .filter((key: string | undefined): key is string => key !== undefined);
    }

    public onPageChange(event: PageEvent): void {
        this.pageChange.emit(event);
    }

    public onActionClick(action: Action, id: number) {
        if (action.callback) {
            action.callback(id);
        }
    }

    public onToggleChange(event: ToggleEvent, row: AdminModel): void {
        this.toggleChange.emit({ event, row });
    }

    public isOverflow(el: HTMLElement): boolean {
        var curOverflow = el.style.overflow;
        if (!curOverflow || curOverflow === "visible") {
            el.style.overflow = "hidden";
        }
        var isOverflowing = el.clientWidth < el.scrollWidth || el.clientHeight < el.scrollHeight;
        el.style.overflow = curOverflow;
        return isOverflowing;
    }

    public getValue(item: any, key: string): any {
        return key.split(".").reduce((acc, part) => acc && acc[part], item);
    }

    public onStatusButtonClick(item: any) {
        const dialogRef = this.dialog.open(GenericDialogComponent, {
            data: {
                componentName: DialogInvokingComponents.ApproveRejectReview,
                title: "Approve or Reject the comment",
                inputs: [
                    {
                        controlName: "reason",
                        value: "",
                        placeholder: "Enter the reason for rejection",
                        type: "text",
                    },
                ],
                firstBtn: "Approve",
                secondBtn: "Reject",
            },
            autoFocus: false,
            restoreFocus: false,
        });
        dialogRef.componentInstance.saveClicked.subscribe((savedData: any) => {
            if (savedData) {
                savedData.id = item.id;
                savedData.productId = item.productId;
                this.approveRejectData.emit(savedData);
            }
        });
    }

    public onClickImage(imagesUrl: string[]): void {
        this.imageObject = imagesUrl.map((url) => ({ image: url, thumbImage: url }));
        const dialogRef = this.dialog.open(GenericDialogComponent, {
            data: { imageSlider: this.imageObject, componentName: DialogInvokingComponents.ImageSlider },
        });
    }

    public onCellClick(row: StructureInstance): void {
        const statusArray: StructureStatus[] = [
            StructureStatus.Published,
            StructureStatus.Approved,
            StructureStatus.Rejected,
        ];
        if (statusArray.includes(row.status as StructureStatus)) {
            const queryParams = new URLSearchParams({
                id: row.id?.toString()!,
            });
            this.router.navigateByUrl(`/admin/designer?${queryParams}`);
        }
    }

    public shouldDisplayUpdatedAt(row: any): boolean {
        return row.createdAt !== row.updatedAt;
    }

    public handleCheckboxChange(row: any): void {
        this.selection.toggle(row);
        const selectedIds = this.selection.selected.map((row) => row.id);
        if (selectedIds) {
            this.RowSelected.emit(selectedIds);
        }
    }

    public getSelectedRows(): any[] {
        return this.selection.selected;
    }

    public selectAllRows(isChecked: boolean): void {
        if (isChecked) {
            // Select all rows
            this.dataSource.data.forEach((row) => this.selection.select(row));
        } else {
            // Deselect all rows
            this.selection.clear();
        }

        // Emit the selected IDs
        const selectedIds = this.selection.selected.map((row) => row.id);
        this.RowSelected.emit(selectedIds);
    }
}
