import { EventEmitter, Injectable } from "@angular/core";
import * as THREE from "three";
import { ShapeType } from "../utils/shape-type";
import { ActionConfiguration, AlteredShapeConfiguration, Object3DUserData } from "../utils/shape";
import { StructureService } from "./services/structure/structure.service";
import { NullableStructureInstance, StructureResponseMessage } from "@models/structure.model";
import { ColorCodes } from "../utils/color-constants";
import { ComponentInteractionSrevice } from "./services/component-interaction/component-interaction.service";
import { InteractionService } from "./shared/helpers/interaction.service";
import { AvailableShapes } from "../utils/shape-facetype";
import { CellReference } from "@models/mesh.model";
import { HelperService } from "./shared/helpers/helper.service";

@Injectable({
    providedIn: "root",
})
export class LoadStructureService {
    private scene!: THREE.Scene;
    private object: THREE.Object3D[] = [];
    public meshCreated = new EventEmitter<THREE.Mesh>();
    public clearStructure = new EventEmitter();
    public objectLength = new EventEmitter();
    public structureDetails!: NullableStructureInstance;

    constructor(
        private structureService: StructureService,
        private helperService: HelperService,
    ) {}

    /*
  any because object has different type for different shape and also depends on how we save.
*/

    public loadStructure(
        structure: NullableStructureInstance,
        object: THREE.Object3D[] = [],
        scene: THREE.Scene,
    ): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this.object = object;
            this.scene = scene;
            this.structureDetails = structure;
            this.clearStructure.emit();

            this.structureService.fetchStructureData(structure!.structureData!).subscribe({
                next: (data) => {
                    try {
                        if (Array.isArray(data)) {
                            data.forEach((object) => {
                                if (object?.object?.type === "Mesh") {
                                    this.createMesh(object, true, "", structure!.isUpdated, false);
                                } else {
                                    throw new Error();
                                }
                            });
                            resolve(true);
                        } else {
                            throw new Error();
                        }
                    } catch (error) {
                        reject(error);
                    }
                },
                error: (error) => {
                    reject(error);
                },
            });
        });
    }

    public get structureData() {
        return this.structureDetails;
    }

    public createMesh(
        object: any,
        ishavingoutline: boolean,
        selectedColour: THREE.ColorRepresentation | string,
        isUpdated: boolean,
        isForBlock: boolean,
    ): THREE.Mesh | undefined {
        if (!object.geometries || !object.materials) {
            throw Error(StructureResponseMessage.InvalidObject);
        }
        const geometries = Array.isArray(object.geometries) ? object.geometries : [object.geometries];
        const materials = Array.isArray(object.materials) ? object.materials : [object.materials];
        const texturesData = Array.isArray(object.images) ? object.images : [object.images];

        let mesh: THREE.Mesh | undefined;
        const textureLoader = new THREE.TextureLoader();

        geometries.forEach((geometryData: any, index: number) => {
            const materialData = materials[index];
            let geometry: THREE.BufferGeometry | undefined;
            let materialArray: THREE.Material[] | THREE.Material = [];

            // Use the helper function to create materials
            materialArray = this.createMaterials(texturesData, textureLoader, selectedColour, materialData, isForBlock);

            switch (geometryData.type) {
                case ShapeType.Geometry.BoxGeometry:
                    geometry = new THREE.BoxGeometry(geometryData.width, geometryData.height, geometryData.depth);
                    geometry.center();
                    break;

                case ShapeType.Geometry.SphereGeometry:
                    geometry = new THREE.SphereGeometry(isForBlock ? 4 : 10, 30, 30);

                    const positionAttribute = geometry.attributes["position"] as THREE.BufferAttribute;

                    if (positionAttribute && positionAttribute.array) {
                        const verts = positionAttribute.array as Float32Array;
                        for (let i = 0; i < verts.length; i += 3) {
                            if (verts[i + 1] < 0) {
                                verts[i + 1] = 0;
                            }
                        }
                    }
                    if (isForBlock) {
                        geometry.center();
                    }
                    break;

                case ShapeType.Geometry.CircleGeometry:
                    geometry = new THREE.CircleGeometry(
                        geometryData.radius,
                        geometryData.segments,
                        geometryData.thetaStart,
                        geometryData.thetaLength,
                    );
                    break;

                case ShapeType.Geometry.ConeGeometry:
                    geometry = new THREE.ConeGeometry(
                        geometryData.radius,
                        geometryData.height,
                        geometryData.radialSegments,
                    );
                    break;

                case ShapeType.Geometry.CylinderGeometry:
                    geometry = new THREE.CylinderGeometry(
                        geometryData.radiusTop,
                        geometryData.radiusBottom,
                        geometryData.height,
                        geometryData.radialSegments,
                        geometryData.heightSegments,
                        false,
                        geometryData.thetaStart,
                        geometryData.thetaLength,
                    );
                    geometry.center();
                    break;

                case ShapeType.Geometry.ExtrudeGeometry:
                    const shape = new THREE.Shape();
                    const shapesData = object.shapes || [];
                    shapesData.forEach((shapeData: any) => {
                        const path = this.createPathFromCurves(shapeData.curves);
                        shape.add(path);
                    });
                    geometry = new THREE.ExtrudeGeometry(shape, geometryData.options);
                    geometry.center();
                    break;

                default:
                    return;
            }

            // Create the mesh and apply the material(s)
            if (geometry) {
                const finalMaterial = Array.isArray(materialArray) ? materialArray : materialArray;
                mesh = new THREE.Mesh(geometry, finalMaterial);

                // Set initial position, rotation, and other properties
                mesh.position.set(
                    object.object.userData?.initialPosition?.x || 0,
                    object.object.userData?.initialPosition?.y || 0,
                    object.object.userData?.initialPosition?.z || 0,
                );

                // Handle rotation if any
                if (object.object.userData?.rotation) {
                    mesh.rotation.set(
                        object.object.userData?.rotation?.x,
                        object.object.userData?.rotation?.y,
                        object.object.userData?.rotation?.z,
                    );
                }

                // Add outline if needed
                if (ishavingoutline) {
                    if (geometry instanceof THREE.BoxGeometry) {
                        const edges = new THREE.EdgesGeometry(mesh.geometry);
                        const lineMaterial = new THREE.LineBasicMaterial({ color: Number(ColorCodes.black) });
                        const lineSegments = new THREE.LineSegments(edges, lineMaterial);
                        mesh.add(lineSegments);
                    } else {
                        const edgesGeometry = new THREE.EdgesGeometry(
                            geometry,
                            AlteredShapeConfiguration.standardThreshHoldAngle,
                        );
                        const edgesMaterial = new THREE.LineBasicMaterial({ color: Number(ColorCodes.black) });
                        const edges = new THREE.LineSegments(edgesGeometry, edgesMaterial);
                        mesh.add(edges);
                    }
                }

                // Set the userData and name for the mesh
                mesh.userData = {
                    ...object.object.userData,
                    initialPosition: object.object.userData?.initialPosition
                        ? this.toVector3(object.object.userData.initialPosition)
                        : undefined,
                    initialMaterial: mesh.material,
                    rotation: object.object.userData?.rotation
                        ? this.toEuler(object.object.userData?.rotation)
                        : undefined,
                };
                mesh.name = object.object.name;
            }
        });

        // Recalculate cell reference
        if (mesh && !isUpdated) {
            const { row, column, cell } = this.helperService.calculateRowAndColumn(mesh as THREE.Mesh);
            const cellReference: CellReference = {
                row: row,
                column: column,
                cell: cell,
            };
            mesh.userData[Object3DUserData.cellReference] = cellReference;
        }

        this.object?.push(mesh!);
        this.scene?.add(mesh!);
        this.objectLength.emit(this.object.length);
        return mesh;
    }

    // Helper function for creating materials
    private createMaterials(
        texturesData: any[],
        textureLoader: THREE.TextureLoader,
        selectedColour: THREE.ColorRepresentation | string,
        materialData: any,
        isForBlock: boolean,
    ): THREE.Material[] | THREE.Material {
        const color = selectedColour || "#117A65";
        const materialArray: THREE.Material[] = [];
        let material: THREE.Material | undefined;

        if (!isForBlock) {
            material = new THREE.MeshBasicMaterial({
                color: materialData.color || "#117A65",
                side: THREE.DoubleSide,
                transparent: false,
            });
        } else {
            texturesData.forEach((textured: any) => {
                if (textured) {
                    const texture = textureLoader.load(textured.url);
                    materialArray.push(
                        new THREE.MeshBasicMaterial({
                            map: texture,
                            color: selectedColour as THREE.ColorRepresentation,
                            transparent: true,
                            side: THREE.DoubleSide,
                        }),
                    );
                } else {
                    material = new THREE.MeshStandardMaterial({
                        color: color as THREE.ColorRepresentation,
                        side: THREE.DoubleSide,
                        transparent: false,
                    });
                }
            });
        }

        return materialArray.length > 0 ? materialArray : material || new THREE.MeshBasicMaterial({ color: "#117A65" });
    }

    public getObject() {
        return this.object;
    }

    private toVector3(position: { x: number; y: number; z: number }): THREE.Vector3 {
        return new THREE.Vector3(position.x, position.y, position.z);
    }

    private toEuler(rotation: { x: number; y: number; z: number; order: THREE.EulerOrder }) {
        return new THREE.Euler(rotation.x, rotation.y, rotation.z, rotation.order);
    }

    private createPathFromCurves(curveData: any) {
        const path = new THREE.Path();
        curveData.forEach((curve: any) => {
            if (curve.type === "EllipseCurve") {
                path.absellipse(
                    curve.aX,
                    curve.aY,
                    curve.xRadius,
                    curve.yRadius,
                    curve.aStartAngle,
                    curve.aEndAngle,
                    curve.aClockwise,
                    curve.aRotation || 0,
                );
            } else if (curve.type === "LineCurve") {
                path.lineTo(curve.v2[0], curve.v2[1]);
            } else if (curve.type === "Path" && curve.curves) {
                path.add(this.createPathFromCurves(curve.curves));
            }
        });
        return path;
    }
}
