<div class="outer-content">
    <div class="container">
        <h2>Enter Profile Information</h2>
        <profile-details #profileDetailRef (profileData)="receiveProfileData($event)"></profile-details>
        <br />
        <h2>Enter Address</h2>
        <app-address #addressDetailRef (addressData)="receiveAddressData($event)"></app-address><br />
        <div class="btn-content">
            <button
                mat-raised-button
                color="primary"
                (click)="submitForm()"
                [disabled]="!isFormDataValid()"
                class="submit-btn"
            >
                Save & Continue
            </button>
        </div>
        <br />
    </div>
</div>
