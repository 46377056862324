import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function indianPhoneNumberValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const value = control.value;

        if (!value) {
            return null;
        }
        const mobileNumbers = value.split(",").map((num: string) => num.trim());
        const indianPhoneNumberPattern = /^[6-9]\d{9}$/;
        const invalidNumbers = mobileNumbers.filter((num: string) => !indianPhoneNumberPattern.test(num));

        const uniqueNumbers = new Set(mobileNumbers);
        const hasDuplicates = uniqueNumbers.size !== mobileNumbers.length;

        if (invalidNumbers.length > 0) {
            return { indianPhoneNumber: { invalidNumbers } };
        } else if (hasDuplicates) {
            return { hasDuplicates: true };
        }
        return null;
    };
}
