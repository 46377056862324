import { Injectable } from "@angular/core";
import {
    AddressDetailsAttributes,
    AddressDetailsCreationAttributes,
    NullableAddress,
    NullableAddresses,
    NullableUser,
    UserDetailsCreationAttributes,
    UserInstance,
} from "@models/user.model";

@Injectable({
    providedIn: "root",
})
export class CheckoutService {
    constructor() {}

    public setUserInLocalStorage(user: UserDetailsCreationAttributes): void {
        localStorage.setItem("user", JSON.stringify(user));
    }

    public setAddressInLocalStorage(address: AddressDetailsCreationAttributes): void {
        localStorage.setItem("address", JSON.stringify(address));
    }

    public getAddressFromLocalStorage(): NullableAddress {
        let storedAddress = localStorage.getItem("address");
        if (!storedAddress) {
            return null;
        }
        return JSON.parse(storedAddress);
    }

    public getAddressesFromLocalStorage(): NullableAddresses {
        let storedAddresses = localStorage.getItem("address");
        if (!storedAddresses) {
            return [];
        }
        return JSON.parse(storedAddresses);
    }

    public getCurrentUserFromLocalStorage(): NullableUser {
        let storedUser = localStorage.getItem("user");
        if (!storedUser) {
            return null;
        }
        return JSON.parse(storedUser!);
    }
}
