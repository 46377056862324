import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PaymentComponent } from "./payment.component";
import { PaymentModesListComponent } from "./payment-modes/payment-modes.component";
import { PaytmPaymentComponent } from "./payment-modes/paytm-payment/paytm-payment.component";
import { CheckoutModule } from "paytm-blink-checkout-angular";
import { AppRoutingModule } from "../app-routing.module";
@NgModule({
    declarations: [PaymentComponent, PaymentModesListComponent, PaytmPaymentComponent],
    imports: [CommonModule, CheckoutModule, AppRoutingModule],
})
export class PaymentModule {}
