import { Injectable } from "@angular/core";
import { Object3DUserData } from "@utils/shape";
import * as THREE from "three";
import { Mesh } from "three";
import { Sides } from "../models/helper.model";

@Injectable({
    providedIn: "root",
})
export class SetShapeUserDataService {
    constructor() {}

    /*
    This method performs the detection and updation of the face data each time the new shape is generated.
  */
    public adjacentData(objects: THREE.Object3D[], shape: THREE.Object3D): void {
        const adjacentObjects: { side: string; uuid: string }[] = [];

        /*
      Criteria here refers to the conditions to get all the shapes if present in the nearby grids.
      We only fetch the data of nearest 6 grids to optomise.
    */
        const criteria = {
            right: shape.userData[Object3DUserData.cellReference].row - 1,
            left: shape.userData[Object3DUserData.cellReference].row + 1,
            back: shape.userData[Object3DUserData.cellReference].column - 1,
            front: shape.userData[Object3DUserData.cellReference].column + 1,
            top: shape.userData[Object3DUserData.cellReference].cell + 1,
            bottom: shape.userData[Object3DUserData.cellReference].cell - 1,
        };

        for (const side in Sides) {
            const checkCriteria: any = {
                row: shape.userData[Object3DUserData.cellReference].row,
                column: shape.userData[Object3DUserData.cellReference].column,
                cell: shape.userData[Object3DUserData.cellReference].cell,
            };

            switch (side) {
                case Sides.Right:
                    checkCriteria.row = criteria.right;
                    break;
                case Sides.Left:
                    checkCriteria.row = criteria.left;
                    break;
                case Sides.Back:
                    checkCriteria.column = criteria.back;
                    break;
                case Sides.Front:
                    checkCriteria.column = criteria.front;
                    break;
                case Sides.Top:
                    checkCriteria.cell = criteria.top;
                    break;
                case Sides.Bottom:
                    checkCriteria.cell = criteria.bottom;
                    break;
            }

            for (const object of objects) {
                if (
                    object !== shape &&
                    object.userData[Object3DUserData.cellReference] &&
                    Object.keys(checkCriteria).every(
                        (key) => object.userData[Object3DUserData.cellReference][key] === checkCriteria[key],
                    )
                ) {
                    adjacentObjects.push({
                        side: side,
                        uuid: object.uuid,
                    });
                    shape.userData[Object3DUserData.adjacentData] = shape.userData[Object3DUserData.adjacentData] || {};
                    object.userData[Object3DUserData.adjacentData] =
                        object.userData[Object3DUserData.adjacentData] || {};

                    // We update the userdata here for both the shapes.
                    shape.userData[Object3DUserData.adjacentData][side] = object.uuid;
                    object.userData[Object3DUserData.adjacentData][this.oppositeSide(side)] = shape.uuid;
                }
            }
        }
    }

    // This will get the opposite side for the stationary shapes.
    private oppositeSide(side: string): string {
        switch (side) {
            case Sides.Right:
                return Sides.Left;
            case Sides.Left:
                return Sides.Right;
            case Sides.Front:
                return Sides.Back;
            case Sides.Back:
                return Sides.Front;
            case Sides.Top:
                return Sides.Bottom;
            case Sides.Bottom:
                return Sides.Top;
            default:
                return "";
        }
    }

    public clearAdjacentDataOnDragStart(shape: THREE.Object3D, objects: THREE.Object3D[]): void {
        const adjacentShapes = this.filterObjectsByAdjacentShape(shape, objects);
        for (const adjacentShape of adjacentShapes) {
            if (adjacentShape.userData[Object3DUserData.adjacentData]) {
                for (const side in adjacentShape.userData[Object3DUserData.adjacentData]) {
                    if (adjacentShape.userData[Object3DUserData.adjacentData][side] === shape.uuid) {
                        delete adjacentShape.userData[Object3DUserData.adjacentData][side];
                    }
                }
            }
        }
        shape.userData[Object3DUserData.adjacentData] = {};
    }

    private filterObjectsByAdjacentShape(shape: THREE.Object3D, objects: THREE.Object3D[]): THREE.Object3D[] {
        if (!shape.userData[Object3DUserData.adjacentData]) {
            return [];
        }
        const shapeAdjacentData = shape.userData[Object3DUserData.adjacentData];
        const adjacentUUIDs = Object.values(shapeAdjacentData);

        return objects.filter((object) => adjacentUUIDs.includes(object.uuid));
    }
}
