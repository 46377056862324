<h1>
    Notifications
    <span *ngIf="notifications.length && getUnreadNotificationCount() > 0">{{
        "(" + getUnreadNotificationCount() + ")"
    }}</span>
</h1>
<div class="select-all" *ngIf="notifications.length; else noNotificationsTemplate">
    <mat-checkbox
        *ngIf="notifications.length > 1"
        [indeterminate]="isSomeSelected()"
        [checked]="isAllSelected()"
        (change)="toggleSelectAll($event)"
    >
        Select All
    </mat-checkbox>
    <button
        mat-raised-button
        color="warn"
        (click)="deleteSelectedNotifications()"
        [disabled]="selectedNotifications.size === 0"
    >
        Delete
    </button>
</div>
<mat-spinner *ngIf="loading" color="warn" class="loading-spinner" [diameter]="70"></mat-spinner>
<div *ngIf="!loading && notifications.length; else noNotificationsTemplate">
    <div class="notification-wrapper" *ngFor="let notification of notifications">
        <div class="notification-item">
            <mat-checkbox
                class="notification-checkbox"
                (change)="toggleSelectNotification(notification.id)"
                [checked]="selectedNotifications.has(notification.id)"
            ></mat-checkbox>
            <div
                class="notification-container"
                [ngClass]="{
                    'notification-unviewed': !notification.isMessageViewed,
                    'notification-viewed': notification.isMessageViewed
                }"
            >
                <!-- Only show the border line and main card when the notification type is "Mail" -->
                <div
                    *ngIf="notification.type === mail.Mail; else noMailTemplate"
                    class="border-line"
                    [ngClass]="{
                        'notification-unviewed': !notification.isMessageViewed,
                        'notification-viewed': notification.isMessageViewed
                    }"
                >
                    <div class="main-card">
                        <div class="left-section">
                            <p>{{ notification.message }}</p>
                            <p>{{ notification.createdAt | date: "MMM d yyyy, h:mm a" }}</p>
                        </div>
                        <div class="right-section">
                            <mat-icon class="delete-btn" (click)="deleteNotification(notification.id)">
                                delete
                            </mat-icon>
                        </div>
                    </div>
                </div>

                <!-- Template to be used when the notification type is not "Mail" -->
                <ng-template #noMailTemplate>
                    <div
                        class="main-card"
                        [ngClass]="{
                            'notification-unviewed': !notification.isMessageViewed,
                            'notification-viewed': notification.isMessageViewed
                        }"
                    >
                        <div class="left-section">
                            <p>{{ notification.message }}</p>
                            <p>{{ notification.createdAt | date: "MMM d yyyy, h:mm a" }}</p>
                        </div>
                        <div class="right-section">
                            <mat-icon class="delete-btn" (click)="deleteNotification(notification.id)">
                                delete
                            </mat-icon>
                        </div>
                    </div>
                </ng-template>

                <!-- Conditionally show the View More button if the notification type is 'Mail' -->
                <div class="sub-card" *ngIf="notification.type === mail.Mail">
                    <div class="sub-left">
                        <div class="button-container">
                            <button
                                class="help-btn"
                                mat-raised-button
                                (click)="openMailPopup(notification); markNotificationAsViewed(notification.id)"
                            >
                                view more
                            </button>
                        </div>
                    </div>
                    <div class="sub-right"></div>
                </div>
            </div>
        </div>
    </div>

    <button class="view" *ngIf="showViewMoreButton()" mat-raised-button (click)="loadMoreNotifications()">
        View More
    </button>
</div>

<ng-template #noNotificationsTemplate>
    <div class="no-notifications" *ngIf="!loading">
        <mat-icon
            svgIcon="emptyNotification"
            class="emptyNotificationIcon"
            aria-hidden="false"
            aria-label="Empty Notification"
        />
        <h2>Empty Notification</h2>
        <p>We will notify you when something arrives...</p>
        <button mat-raised-button class="save-btn" (click)="goToHomePage()">Back To Home</button>
    </div>
</ng-template>
