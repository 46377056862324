<div class="contact">
    <div class="contact-details">
        <section class="section conatct-info">
            <h3 class="section-title">Contact Info</h3>
            <p>Phone: {{ phone }}</p>
            <p>Email: {{ email }}</p>
        </section>
        <section class="section office-location">
            <h3 class="section-title">Office Location</h3>
            <p class="address">{{ address }}</p>
        </section>
        <section class="section working-hours">
            <h3 class="section-title">Working Hours</h3>
            <p>Monday - Friday - {{ workingHours.weekdays }}</p>
            <p>Saturday - {{ workingHours.weekends }}</p>
            <p>Sunday - {{ workingHours.weekends }}</p>
        </section>
    </div>
    <div class="contact-form">
        <h1 class="contact-form-title">Get In Touch</h1>
        <p class="contact-form-desc">
            Thank you for your interest in Funfigz. Please fill out the form below and we will get in touch with you
            within 24 hours.
        </p>
        <div class="form">
            <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
                <div class="fields">
                    <div class="input-field">
                        <label for="name" class="required">Name</label>
                        <input id="name" type="text" formControlName="name" placeholder="Full Name" />
                        <div *ngIf="isInvalid('name')">
                            <p class="error-text" *ngIf="isInvalid('name', 'required')">Name field is mandatory.</p>
                            <p class="error-text" *ngIf="isInvalid('name', 'forbiddenName')">
                                Name cannot contain numbers or special characters.
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="input-field email">
                            <label for="email" class="required">Email</label>
                            <input id="email" type="text" formControlName="email" placeholder="Email" />
                            <div *ngIf="isInvalid('email')">
                                <p class="error-text" *ngIf="isInvalid('email', 'required')">
                                    Email cannot be left empty
                                </p>
                                <p
                                    class="error-text"
                                    *ngIf="isInvalid('email', 'invalidEmail') && !isInvalid('email', 'required')"
                                >
                                    Please use a valid email format.
                                </p>
                            </div>
                        </div>
                        <div class="input-field subject">
                            <label for="subject" class="required">Subject</label>
                            <input id="subject" type="text" formControlName="subject" placeholder="Subject" />
                            <div *ngIf="isInvalid('subject')">
                                <p class="error-text" *ngIf="isInvalid('subject', 'required')">
                                    Please provide a subject.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="input-field">
                        <label for="message" class="required">Message</label>
                        <textarea
                            id="message"
                            type="text"
                            formControlName="message"
                            rows="5"
                            placeholder="Message"
                        ></textarea>
                        <div *ngIf="isInvalid('message')">
                            <p class="error-text" *ngIf="isInvalid('message', 'required')">
                                Please write your message.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="btn">
                    <button mat-flat-button [disabled]="this.contactForm.invalid" class="save-btn">Submit</button>
                </div>
            </form>
        </div>
    </div>
</div>
