import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { RewardDetail, RewardResponse } from "@models/reward.model";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";

@Injectable({
    providedIn: "root",
})
export class RewardsService {
    constructor(private http: HttpClient) {}

    public getRewardsWithTotalCountForUser(
        userId: number,
        pageIndex: number = environment.dynamicPagination.pageIndex,
        pageSize: number = environment.dynamicPagination.pageSize,
    ): Observable<{
        totalCount: number;
        totalCoinsEarned: number;
        totalCoinsSpent: number;
        rows: RewardDetail[];
    }> {
        return this.http.get<{
            totalCount: number;
            totalCoinsEarned: number;
            totalCoinsSpent: number;
            rows: RewardDetail[];
        }>(
            `${environment.apiUrlBasePath}/reward/getReward?pageIndex=${pageIndex}&pageSize=${pageSize}&userId=${userId}`,
        );
    }

    public updateCoinSpent(
        userId: number,
        orderId: number,
        productId: number | null,
        coinsSpent: number,
    ): Observable<RewardResponse> {
        const body = { userId, orderId, productId, coinsSpent };
        return this.http.put<RewardResponse>(`${environment.apiUrlBasePath}/reward/update`, body);
    }
}
