import { Component, Input } from "@angular/core";

@Component({
    selector: "designer",
    templateUrl: "./designer.component.html",
    styleUrls: ["./designer.component.scss"],
})
export class DesignerComponent {
    @Input() isViewMode = false;
    @Input() isGlb = false;

    constructor() {}
}
