import { Component, ElementRef, EventEmitter, Output, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Libraries, Loader } from "@googlemaps/js-api-loader";
import { GoogleMapsConfig } from "@models/map.model";
import { AddressDetailsCreationAttributes } from "@models/user.model";
import { appConfig } from "../../../environments/app.config";
import { debounceTime, fromEvent } from "rxjs";
import { UserService } from "src/app/services/user/user.service";

declare var google: any;

@Component({
    selector: "app-location-details",
    templateUrl: "./location-details.component.html",
    styleUrls: ["./location-details.component.scss"],
})
export class LocationDetailsComponent {
    @ViewChild("placesAutoComplete") placesAutoComplete!: ElementRef;
    @Output() locationData = new EventEmitter<AddressDetailsCreationAttributes>();
    public locationForm!: FormGroup;
    public geocoder!: google.maps.Geocoder;
    public searchQuery!: string;
    public latitude!: number;
    public longitude!: number;
    public marker!: google.maps.Marker;
    public map!: google.maps.Map;
    public chips: string[] = ["Home", "Work", "Other"];

    constructor(private userService: UserService) {}

    ngOnInit(): void {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    this.latitude = position.coords.latitude;
                    this.longitude = position.coords.longitude;
                    this.initMap();
                },
                (error) => {
                    console.error("Error getting user location:", error.message);
                    this.latitude = appConfig.defaultLocation.latitude;
                    this.longitude = appConfig.defaultLocation.longitude;
                    this.initMap();
                },
            );
        }
        this.registerInputChange();
    }

    private registerInputChange(): void {
        const input = document.getElementById("pac-input") as HTMLInputElement;
        fromEvent(input, "input")
            .pipe(debounceTime(2000))
            .subscribe(() => {
                if (input.value.length >= 3) {
                    const searchBox = new google.maps.places.SearchBox(input);
                    searchBox.setBounds(this.map.getBounds() as google.maps.LatLngBounds);
                    this.map.addListener("bounds_changed", (data: any) => {
                        searchBox.setBounds(this.map.getBounds() as google.maps.LatLngBounds);
                    });
                    searchBox.addListener("places_changed", (data: any) => {
                        this.handlePlacesChanged(searchBox);
                    });
                }
            });
    }

    private initMap(): void {
        this.userService.getMapSource().subscribe((data: GoogleMapsConfig) => {
            const loader = new Loader({
                apiKey: data.key,
                version: data.version,
                libraries: data.libraries as Libraries,
            });

            loader.load().then(async () => {
                const { Map } = (await google.maps.importLibrary("maps")) as google.maps.MapsLibrary;
                const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");

                this.map = new Map(document.getElementById("map") as HTMLElement, {
                    center: { lat: this.latitude, lng: this.longitude },
                    zoom: 15,
                    mapId: "funFigzMap",
                });
                this.marker = new AdvancedMarkerElement({
                    position: { lat: this.latitude, lng: this.longitude },
                    map: this.map,
                    title: "location",
                });
                this.registerCenterChanged();
                this.loadDefaultMap(this.latitude, this.longitude);
            });
        });
    }

    public loadMap(): void {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                this.latitude = position.coords.latitude;
                this.longitude = position.coords.longitude;
                this.map.setCenter({ lat: this.latitude, lng: this.longitude });
                this.marker.setPosition({ lat: this.latitude, lng: this.longitude });
                this.registerCenterChanged();
                this.patchFormWithCurrentLocation();
            });
        }
    }

    public loadDefaultMap(lat: number, lng: number): void {
        this.marker = new google.maps.Marker({
            position: { lat: lat, lng: lng },
            map: this.map,
            title: "Default Location",
        });

        this.registerCenterChanged();
    }

    private handlePlacesChanged(searchBox: google.maps.places.SearchBox): void {
        const places: google.maps.places.PlaceResult[] | undefined = searchBox.getPlaces();
        if (!places || places.length === 0 || !places[0].geometry) {
            return;
        }
        const location = places[0].geometry.location;
        if (!location) {
            return;
        }
        this.latitude = location.lat();
        this.longitude = location.lng();

        const addressComponents = places[0].address_components;
        const addressObj: {
            [key: string]: string;
        } = {};
        addressComponents?.forEach((component: google.maps.GeocoderAddressComponent) => {
            const type = component.types[0];
            addressObj[type] = component.long_name;
        });

        this.locationData.emit({
            address: places[0].formatted_address || "",
            gpsCoordinate: {
                latitude: this.latitude,
                longitude: this.longitude,
            },
            pincode: Number(addressObj["postal_code"]) || 0,
            city: addressObj["locality"] || "",
            state: addressObj["administrative_area_level_1"] || "",
        });

        this.marker.setPosition(location);
        this.map.setCenter(location);
    }

    private registerCenterChanged(): void {
        this.geocoder = new google.maps.Geocoder();
        this.patchFormWithCurrentLocation();
        this.map.addListener("center_changed", () => {
            this.marker.setPosition(this.map.getCenter());
            const center = this.map.getCenter();
            if (center) {
                this.latitude = center.lat();
                this.longitude = center.lng();
            }
            this.geocoder = new google.maps.Geocoder();
            this.patchFormWithCurrentLocation();
        });
    }

    private patchFormWithCurrentLocation(): void {
        this.geocoder.geocode(
            { location: { lat: this.latitude, lng: this.longitude } },
            (results: google.maps.GeocoderResult[] | null, status: google.maps.GeocoderStatus) => {
                if (status === "OK") {
                    if (results && results[0]) {
                        const addressComponents = results[0].address_components;
                        const addressObj: {
                            [key: string]: string;
                        } = {};
                        addressComponents?.forEach((component: google.maps.GeocoderAddressComponent) => {
                            const type = component.types[0];
                            addressObj[type] = component.long_name;
                        });

                        this.locationData.emit({
                            address: results[0].formatted_address,
                            gpsCoordinate: {
                                latitude: this.latitude,
                                longitude: this.longitude,
                            },
                            pincode: Number(addressObj["postal_code"]) || 0,
                            city: addressObj["locality"] || "",
                            state: addressObj["administrative_area_level_1"] || "",
                        });
                    }
                }
            },
        );
    }
}
