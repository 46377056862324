import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
@Component({
    selector: "app-footer",
    templateUrl: "./footer.component.html",
    styleUrls: ["./footer.component.scss"],
})
export class FooterComponent {
    currentYear: number = new Date().getFullYear();

    constructor(private router: Router) {}

    public onClickPrivacy(): void {
        this.router.navigate(["/privacy"]);
    }

    public onClickTermCondition(): void {
        this.router.navigate(["/termCondition"]);
    }
}
