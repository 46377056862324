export enum ActionsButton {
    closeIconLabel = "close",
    rotateIconlabel = "rotate_90_degrees_ccw",
    rotateIconHint = "Rotate",
    flipTopLabel = "screen_rotation_alt",
    flipTopHint = "Flip Top",
    revertBackHint = "Revert Back",
    filpLeftLabel = "undo",
    flipLeftHint = "Flip Left",
    flipRightLabel = "redo",
    flipRightHint = "Flip Right",
    flipFrontLabel = "flip_to_front",
    flipForntHint = "Flip Front",
    flipBackLabel = "flip_to_back",
    flipBackhint = "Flip Back",
}

export enum SaveButton {
    saveStructureIconLabel = "save",
    saveStructureIconHint = "Save",
    updateStructureIconLabel = "update",
    updateStructureIconHint = "Update",
    publishIconLabel = "publish",
    publishIconHint = "Publish",
}

export enum SlideInOut {
    triggerName = "slideInOut",
    trueState = "true",
    falseState = "false",
    topPos = "220px",
    rightPoswithStateTrue = "10px",
    rightPoswithStateFalse = "-350px",
    transition_true_to_false = "true => false",
    transition_false_to_true = "false => true",
    animate_duration = "500ms ease-in-out",
}

export enum ActionButtonContainer {
    panelClassName = "customise-dialog-box",
    panelWidth = "1000px",
}

export enum ColorHEX {
    greencyan = "#117A65",
    green = "#21BA45",
    malibu = "#5DADE2",
    olivetone = "#9A7D0A",
    orange = "#F2711C",
    irisblue = "#00B5AD",
    blue = "#2185D0",
    darkblue = "#3C79B7",
    defaultColour = "#F2E9E9",
    yellow = "0XFFFF00",
    white = "0xffffff",
}

export enum Events {
    contextmenu = "contextmenu",
    mousemove = "mousemove",
    click = "click",
    resize = "resize",
    doubleclick = "dblclick",
    keydown = "keydown",
    backspace = "Backspace",
    keyc = "c",
    commandKey = "Meta",
    keyup = "keyup",
    shiftKey = "Shift",
    plusKey = "+",
    minusKey = "-",
}
