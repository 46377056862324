<div class="spinner-container" *ngIf="isLoading">
    <mat-spinner></mat-spinner>
</div>

<div class="canvas-container">
    <div class="drag-to-spin" *ngIf="!isLoading">
        <mat-icon>touch_app</mat-icon>
        <span>Drag to Spin</span>
    </div>
    <canvas #canvas></canvas>
    <div class="zoom-buttons">
        <button class="zoom-btn" (click)="zoomIn()">+</button>
        <button class="zoom-btn" (click)="zoomOut()">-</button>
    </div>
</div>
