import { Component, Inject, OnInit } from "@angular/core";
import { ComponentInteractionSrevice } from "src/app/services/component-interaction/component-interaction.service";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: "get-count",
    templateUrl: "./get-count.component.html",
    styleUrls: ["./get-count.component.scss"],
})
export class GetCountComponent implements OnInit {
    public displayedColumns = ["name", "facePattern", "color", "count", "connectionDetails"];
    public dataSource: any;
    public isLoading = true;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

    ngOnInit(): void {
        if (this.data) {
            this.isLoading = false;
            this.dataSource = this.data;
        }
    }

    closeDialogBox() {}
}

export interface PeriodicElement {
    name: string;
    facePattern: string;
    count: number;
    color: string;
}
