import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    Inject,
    OnInit,
    Output,
    ViewChild,
    Input,
} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { DialogService } from "src/app/services/dialog/dialog.service";
import { AddressComponent } from "src/app/user-details/address/address.component";
import { ProfileDetailsComponent } from "src/app/user-details/profile-details/profile-details.component";
import { AddressDetailsCreationAttributes, NullableAddresses, UserDetailsCreationAttributes } from "@models/user.model";
import { OperationsModel } from "@models/order.model";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ReviewStatus } from "@models/admin.model";
import { noSpacesValidator } from "../shared/no-leading-space-validator.directive";
import { DialogInvokingComponents } from "@models/generic-dialog.model";
import { StructureStatus, StructureUpdateModel } from "@models/structure.model";
import { emailValidator } from "../email-validator.directive";
import { nameValidator } from "../name-validator.directive";
import { ifscValidator } from "../ifsc-validator.directive";
import { indianBankAccountValidator } from "../account-number-validator.directive";
import { Coupon } from "@models/coupon.model";
import { Viewer360Component } from "../../viewer360/viewer360.component";

@Component({
    selector: "app-generic-dialog",
    templateUrl: "./generic-dialog.component.html",
    styleUrls: ["./generic-dialog.component.scss"],
})
export class GenericDialogComponent implements OnInit {
    public DialogInvokingComponents = DialogInvokingComponents;
    public address!: AddressDetailsCreationAttributes;
    public profile!: UserDetailsCreationAttributes;
    public courierForm!: FormGroup;
    public saveStructureForm!: FormGroup;
    public returnOrderForm!: FormGroup;
    public todayDate = new Date();
    public sliderImageSize = { width: "100%", height: "400px" };
    public myStructureListForm: FormGroup;
    public selectedCoupons: number[] = [];
    public myStructureStatus = [
        { name: "Saved", value: StructureStatus.Saved },
        { name: "Publish", value: StructureStatus.Published },
        { name: "Approved", value: StructureStatus.Approved },
        { name: "Rejected", value: StructureStatus.Rejected },
    ];
    @Output() saveClicked: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild(AddressComponent) addressDetailsRef: AddressComponent | undefined;
    @ViewChild(ProfileDetailsComponent) profileDetailRef: ProfileDetailsComponent | undefined;
    @HostBinding("class.dialog-mode") isInDialog: boolean = true;
    @Input() glbUrl!: string;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: {
            title: string;
            componentName: string;
            content: string;
            content2: string;
            inputs: OperationsModel[];
            inputs2: OperationsModel[];
            inputs3: OperationsModel[];
            refundtype: string;
            showTextArea: boolean;
            firstBtn: string;
            secondBtn: string;
            image: string;
            addresses: NullableAddresses;
            selectedAddressId: number;
            withUserProfileDetails?: boolean;
            hideAgeAndShowMobile: boolean;
            imageSlider: Array<object>;
            structureName: string;
            structureDescription: string;
            status: string;
            message: string;
            isPublish: boolean;
            activeCoupon: Coupon[];
            glbUrl: string;
        },
        public dialogRef: MatDialogRef<GenericDialogComponent>,
        private dialogService: DialogService,
        private formBuilder: FormBuilder,
        private cdr: ChangeDetectorRef,
        private dialog: MatDialog,
    ) {
        this.data.withUserProfileDetails = this.data.withUserProfileDetails ?? true;
        this.data.hideAgeAndShowMobile = this.data.hideAgeAndShowMobile ?? false;
        this.myStructureListForm = this.formBuilder.group({
            myStructureName: ["", [Validators.required, noSpacesValidator()]],
            myStructureDescription: ["", [Validators.required, noSpacesValidator()]],
            status: [""],
        });
    }

    ngOnInit(): void {
        this.courierForm = this.formBuilder.group({});
        this.data.inputs?.forEach((input) => {
            const validators = [Validators.required];
            if (input.type !== "date") {
                validators.push(noSpacesValidator());
            }
            this.courierForm.addControl(input.controlName, this.formBuilder.control(input.value, validators));
        });

        if (this.data.componentName === DialogInvokingComponents.StructureDetails) {
            this.saveStructureForm = this.formBuilder.group({
                structureName: [
                    this.data.structureName ? this.data.structureName : "",
                    [Validators.required, noSpacesValidator()],
                ],
                structureDescription: [
                    this.data.structureDescription ? this.data.structureDescription : "",
                    [Validators.required, noSpacesValidator()],
                ],
            });
        } else if (this.data.componentName === DialogInvokingComponents.MyStructuresListProperties) {
            if (
                [StructureStatus.Published, StructureStatus.Approved, StructureStatus.Rejected].includes(
                    this.data.status as StructureStatus,
                )
            ) {
                this.myStructureListForm.disable();
            }
            this.myStructureListForm.get("myStructureName")?.setValue(this.data?.structureName);
            this.myStructureListForm.get("myStructureDescription")?.setValue(this.data?.structureDescription);
            this.myStructureListForm.get("status")?.setValue(this.data?.status);
        }

        if (this.data.componentName === DialogInvokingComponents.ReturnOrderCod) {
            this.returnOrderForm = this.formBuilder.group({
                reason: ["", [Validators.required]],
                refundOption: ["bankDetails", [Validators.required]],
                bankDetails: this.formBuilder.group({
                    accountHolderName: ["", []],
                    accountNumber: ["", []],
                    ifscCode: ["", []],
                }),
                upi: this.formBuilder.group({
                    upiId: ["", []],
                }),
            });

            this.returnOrderForm.get("refundOption")?.valueChanges.subscribe((value) => {
                if (value === "bankDetails") {
                    this.setBankDetailsValidators();
                    this.clearUpiValidators();
                    this.returnOrderForm.get("upi")?.reset();
                } else if (value === "upi") {
                    this.setUpiValidators();
                    this.clearBankDetailsValidators();
                    this.returnOrderForm.get("bankDetails")?.reset();
                }
                this.cdr.detectChanges();
            });

            // Trigger the initial setup
            this.returnOrderForm.get("refundOption")?.updateValueAndValidity();
        }
    }

    public setBankDetailsValidators(): void {
        const bankDetails = this.returnOrderForm.get("bankDetails");
        bankDetails?.get("accountHolderName")?.setValidators([Validators.required, nameValidator()]);
        bankDetails?.get("accountNumber")?.setValidators([Validators.required, indianBankAccountValidator()]);
        bankDetails?.get("ifscCode")?.setValidators([Validators.required, ifscValidator()]);
        bankDetails?.updateValueAndValidity();
    }

    public clearBankDetailsValidators(): void {
        const bankDetails = this.returnOrderForm.get("bankDetails");
        bankDetails?.get("accountHolderName")?.clearValidators();
        bankDetails?.get("accountNumber")?.clearValidators();
        bankDetails?.get("ifscCode")?.clearValidators();
        bankDetails?.updateValueAndValidity();
    }

    public setUpiValidators(): void {
        const upi = this.returnOrderForm.get("upi");
        upi?.get("upiId")?.setValidators([Validators.required, emailValidator()]);
        upi?.updateValueAndValidity();
    }

    public clearUpiValidators(): void {
        const upi = this.returnOrderForm.get("upi");
        upi?.get("upiId")?.clearValidators();
        upi?.updateValueAndValidity();
    }

    public onAddressChange(): void {
        this.dialogRef.close(this.data.selectedAddressId);
    }

    public onAddAddress(): void {
        const data = {
            componentName: DialogInvokingComponents.AddAddress,
            title: "Add Delivery Address",
            withUserProfileDetails: false,
        };
        this.dialogService.openDialog(data);
    }

    public closeDialog(): void {
        this.dialogRef.close("");
    }

    public receiveAddressData(address: AddressDetailsCreationAttributes): void {
        this.address = address;
    }

    public receiveProfileData(profile: UserDetailsCreationAttributes): void {
        this.profile = profile;
    }

    public isFormDataValid(): boolean {
        if (this.data.withUserProfileDetails) {
            return !!(this.profileDetailRef?.profileForm.valid && this.addressDetailsRef?.addressForm.valid);
        } else {
            return !!this.addressDetailsRef?.addressForm.valid;
        }
    }

    private trimSpaces(): void {
        const trimControl = (controlName: string) => {
            const control = this.courierForm.get(controlName);
            if (control) {
                control.setValue(control.value.trim());
            }
        };

        this.data.inputs.forEach((input) => {
            if (input.type !== "date") {
                trimControl(input.controlName);
            }
        });
    }

    public isInvalid(form: FormGroup, controlName: string, validatorName?: string): boolean {
        const control = form.get(controlName);
        if (validatorName) {
            return control!.errors?.[validatorName];
        }
        return control!.invalid && control!.touched;
    }

    public onApprove(): void {
        this.saveClicked.emit({ action: ReviewStatus.Approved });
        this.dialogRef.close();
    }

    public onReject(): void {
        this.trimSpaces();
        if (this.courierForm.valid) {
            const reason = this.courierForm.value.reason;
            this.saveClicked.emit({ action: ReviewStatus.Rejected, reason: reason });
            this.dialogRef.close();
        }
    }

    public save(): void {
        this.trimSpaces();
        if (this.courierForm.valid) {
            const data = this.courierForm.value;
            this.saveClicked.emit(data);
            this.dialogRef.close();
        }
    }

    public updateStructureData(): void {
        const structureData: StructureUpdateModel = {
            structureName: this.myStructureListForm.get("myStructureName")?.value.trim(),
            structureDescription: this.myStructureListForm.get("myStructureDescription")?.value.trim(),
            status: this.myStructureListForm.get("status")?.value.trim(),
        };
        this.dialogRef.close(structureData);
    }

    public disableStatus(statusValue: StructureStatus): boolean {
        if (this.data.status === StructureStatus.Saved) {
            return statusValue !== StructureStatus.Published;
        }
        return true;
    }

    public isStatusDisabled(): boolean {
        return [StructureStatus.Published, StructureStatus.Approved, StructureStatus.Rejected].includes(
            this.data.status as StructureStatus,
        );
    }

    public closeDialogPublish(): void {
        this.dialogRef.close(true);
    }

    public confirmationDialog(): void {
        if (this.data.componentName === DialogInvokingComponents.StructureDetails && this.data.isPublish) {
            const dialogRef = this.dialog.open(GenericDialogComponent, {
                disableClose: true,
                data: {
                    componentName: DialogInvokingComponents.MyStructuresList,
                    title: "Publish My Structure",
                    content:
                        "Are you sure you want to publish this structure? Once published, it will be visible to all users after admin approval.",
                    firstBtn: "Publish",
                    secondBtn: "Cancel",
                    isActionOnEnter: true,
                },
                autoFocus: false,
                restoreFocus: false,
            });

            dialogRef.afterClosed().subscribe((result) => {
                if (result) {
                    this.dialogRef.close(this.saveStructureForm.value);
                    this.saveStructureForm.reset();
                }
            });
        } else {
            this.dialogRef.close(this.saveStructureForm.value);
            this.saveStructureForm.reset();
        }
    }

    public returnOrderData(): void {
        const data = this.returnOrderForm.value;
        this.saveClicked.emit(data);
    }

    public toggleSelection(element: Coupon, event: any): void {
        if (event.checked) {
            this.selectedCoupons.push(element.id!); // Assuming element.id is a number
        } else {
            const index = this.selectedCoupons.indexOf(element.id!);
            if (index > -1) {
                this.selectedCoupons.splice(index, 1);
            }
        }
    }

    public applyCoupon(): void {
        this.dialogRef.close(this.selectedCoupons);
    }

    public isOverflow(el: HTMLElement): boolean {
        var curOverflow = el.style.overflow;
        if (!curOverflow || curOverflow === "visible") {
            el.style.overflow = "hidden";
        }
        var isOverflowing = el.clientWidth < el.scrollWidth || el.clientHeight < el.scrollHeight;
        el.style.overflow = curOverflow;
        return isOverflowing;
    }
}
