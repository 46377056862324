import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function discountPriceValidator(): ValidatorFn {
    return (formGroup: AbstractControl): ValidationErrors | null => {
        const price = formGroup.get("price")?.value ?? formGroup.get("actualPrice")?.value;

        const discountPrice = formGroup.get("discountPrice")?.value;

        if (discountPrice !== null && price !== null && discountPrice > price) {
            return { discountPriceInvalid: true };
        }
        return null;
    };
}
