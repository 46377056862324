import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-privacy-page",
    templateUrl: "./privacy-page.component.html",
    styleUrls: ["./privacy-page.component.scss"],
})
export class PrivacyPageComponent {
    public email = "contact@udmglobal.com";
}
