<h2 class="header">
    My Wishlist
    <span *ngIf="wishlistData.length">{{ "(" + wishlistData.length + ")" }}</span>
</h2>
<div class="wrapper">
    <mat-spinner *ngIf="loading" class="loading-spinner" [diameter]="70"></mat-spinner>
    <div class="container" *ngIf="!loading && wishlistData?.length; else emptyCart">
        <div class="section">
            <mat-card *ngFor="let item of wishlistData">
                <mat-card-content class="item-content">
                    <div class="item">
                        <div class="item-description">
                            <div class="item-image-container">
                                <div class="image" (click)="onClickProduct(item.id)">
                                    <img
                                        *ngIf="item.images; else placeholder"
                                        [src]="item.images.imagesUrl[0]"
                                        alt="Item Image"
                                    />
                                    <ng-template #placeholder>
                                        <div class="image-placeholder"></div>
                                    </ng-template>
                                </div>
                            </div>

                            <div class="item-detail">
                                <b>
                                    {{ item.name }}
                                </b>
                                <p class="description">{{ item.description }}</p>
                                <span
                                    ><s>₹ {{ item.price }}</s> </span
                                ><span class="discount-price">&nbsp;₹ {{ item.discountPrice }}</span>
                                <ng-template #noDiscount>
                                    <span>₹ {{ item.price }}</span>
                                </ng-template>
                                <br />
                                <p class="return">14 days return available<br /><br /></p>
                                <button
                                    mat-raised-button
                                    class="save-btn"
                                    *ngIf="item.isActive; else inActiveProduct"
                                    (click)="moveToCart(item, 1)"
                                >
                                    Move to cart
                                </button>
                                <ng-template #inActiveProduct>
                                    <div class="unavailable">
                                        <p>Product is currently unavailable.</p>
                                    </div>
                                </ng-template>
                            </div>
                            <div>
                                <mat-icon (click)="deleteWishlistItem(item)" class="delete"> delete </mat-icon>
                            </div>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
    <ng-template #emptyCart>
        <div class="empty-cart" *ngIf="!loading">
            <div class="image-container">
                <mat-icon svgIcon="emptyWishlist" class="emptyCartIcon" aria-hidden="false" aria-label="Empty Cart" />
            </div>
            <div class="message">
                <div class="message-body">Save your favorite products in one place. Add now and buy later.</div>
            </div>
            <div class="button">
                <button mat-flat-button class="save-btn" (click)="goToHomePage()">Back to Home</button>
            </div>
        </div>
    </ng-template>
</div>
