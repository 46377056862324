export const FaceType = {
    Node: "Node",
    Hole: "Hole",
    Plane: "Plane",
    Curved: "Curved",
};

export enum Face {
    Right = "Right",
    Left = "Left",
    Top = "Top",
    Bottom = "Bottom",
    Front = "Front",
    Back = "Back",
}

export interface CountInputData {
    name: string;
    facePattern: string;
    color: string;
    connectionData: {
        face: string;
        connectionType: string;
    };
}

export interface CountOutputData {
    name: string;
    facePattern: string;
    color: string;
    count: number;
    connectionData: {
        face: string;
        connectionType: string;
    };
}

export interface InputObject {
    [key: string]: string | undefined;
}

export interface OutputObject {
    Top: string;
    Bottom: string;
    Right: string;
    Left: string;
    Front: string;
    Back: string;
}
