import { Component, OnInit } from "@angular/core";
import { OrderService } from "../../services/order/order.service";
import { DialogSaveDataModel, OrderPageDetail, OrderStatus, OrderStatusItem, PaymentType } from "@models/order.model";
import { AdminStatusHistoryModel } from "@models/adminStatusHistory.model";
import { ActivatedRoute } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { GenericDialogComponent } from "../../shared/generic-dialog/generic-dialog.component";
import moment from "moment";
import { PaymentStatus, RefundStatus } from "@models/payment.model";
import { DialogInvokingComponents } from "@models/generic-dialog.model";
import { OrderItem } from "@models/orderItem.model";

@Component({
    selector: "app-admin-order-page",
    templateUrl: "./admin-order-page.component.html",
    styleUrls: ["./admin-order-page.component.scss"],
})
export class AdminOrderPageComponent implements OnInit {
    public order!: OrderPageDetail;
    public id!: number;
    public status!: string[];
    public latestStatus!: string;
    public latestStatusReturnedItems!: string;
    public latestStatusExchangeItem!: string;
    public pickUpDate!: Date;
    public pickUpTime!: string;
    public awbNumber!: string;
    public courierName!: string;
    public responseData!: AdminStatusHistoryModel;
    public dateArray!: string[];
    public orderStatus = OrderStatus;
    public paymentStatus = PaymentStatus;
    public refundStatus = RefundStatus;
    public pickupScheduleDate!: string;
    public isAllRefundSuccessful: boolean = false;
    public cancelledItems: OrderItem[] = [];
    public nonCancelledItems: OrderItem[] = [];
    public formattedDate!: string;
    public udrNumber!: string;
    public paymentType = PaymentType;
    public returnedItems: OrderItem[] = [];
    public exchangeItems: OrderItem[] = [];
    public returnItemIds!: number[];
    public exchangeItemIds!: number[];
    public statusArray = [
        {
            status: OrderStatus.Ordered,
            label: OrderStatus.Ordered,
            icon: "shopping_cart",
        },
        {
            status: OrderStatus.Packed,
            label: OrderStatus.Packed,
            icon: "inventory",
        },
        {
            status: OrderStatus.Dispatched,
            label: OrderStatus.Dispatched,
            icon: "flight_takeoff",
        },
        {
            status: OrderStatus.Delivered,
            label: OrderStatus.Delivered,
            icon: "check_circle",
        },
    ];

    public exchangeOrderStatusArray = [
        {
            status: OrderStatus.ExchangeInitiated,
            label: OrderStatus.ExchangeInitiated,
            icon: "autorenew",
        },
        {
            status: OrderStatus.ExchangePacked,
            label: OrderStatus.ExchangePacked,
            icon: "inventory",
        },
        {
            status: OrderStatus.ExchangeDispatched,
            label: OrderStatus.ExchangeDispatched,
            icon: "flight_takeoff",
        },
        {
            status: OrderStatus.Exchanged,
            label: OrderStatus.Exchanged,
            icon: "check_circle",
        },
    ];

    public returnOrderStatusArray = [
        {
            status: OrderStatus.ReturnInitiated,
            label: OrderStatus.ReturnInitiated,
            icon: "replay",
        },
        {
            status: OrderStatus.PickupScheduled,
            label: OrderStatus.PickupScheduled,
            icon: "pending_actions",
        },
        {
            status: OrderStatus.Returned,
            label: OrderStatus.Returned,
            icon: "check_circle",
        },
        {
            status: RefundStatus.RefundInitiated,
            label: RefundStatus.RefundInitiated,
            icon: "account_balance_wallet",
        },
        {
            status: RefundStatus.RefundSuccessful,
            label: RefundStatus.RefundSuccessful,
            icon: "check_circle",
        },
    ];

    constructor(
        private orderService: OrderService,
        private route: ActivatedRoute,
        private dialog: MatDialog,
    ) {}

    ngOnInit(): void {
        this.id = this.route.snapshot.params["id"];
        this.getOrderDetails();
    }

    public getOrderDetails(): void {
        this.orderService.getOrderDetails(this.id).subscribe((response) => {
            if (response) {
                this.order = response;
                if (this.order?.paymentStatus !== PaymentType.CashOnDelivery) {
                    this.returnOrderStatusArray = this.returnOrderStatusArray.filter(
                        (status) =>
                            status.status !== RefundStatus.RefundInitiated &&
                            status.status !== RefundStatus.RefundSuccessful,
                    );
                }

                this.cancelledItems = this.order?.orderItems?.filter(
                    (item) => item.itemStatus === OrderStatus.Cancelled,
                )!;
                this.nonCancelledItems = this.order?.orderItems?.filter(
                    (item) =>
                        item.itemStatus !== OrderStatus.Cancelled &&
                        item.itemStatus !== OrderStatus.ReturnInitiated &&
                        item.itemStatus !== OrderStatus.PickupScheduled &&
                        item.itemStatus !== OrderStatus.Returned &&
                        item.itemStatus !== OrderStatus.ExchangeInitiated &&
                        item.itemStatus !== OrderStatus.ExchangePacked &&
                        item.itemStatus !== OrderStatus.ExchangeDispatched &&
                        item.itemStatus !== OrderStatus.Exchanged,
                )!;

                this.isAllRefundSuccessful = this.order?.orderItems?.every(
                    (item) => item.refund !== null && item.refund?.refundStatus === RefundStatus.RefundSuccessful,
                )!;

                this.pickupScheduleDate = moment(this.order.pickupDate).format("YYYY-MM-DD");

                this.returnedItems = this.order.orderItems?.filter(
                    (item) =>
                        item.itemStatus === OrderStatus.ReturnInitiated ||
                        item.itemStatus === OrderStatus.PickupScheduled ||
                        item.itemStatus === OrderStatus.Returned,
                )!;

                this.exchangeItems = this.order.orderItems?.filter(
                    (item) =>
                        item.itemStatus === OrderStatus.ExchangeInitiated ||
                        item.itemStatus === OrderStatus.ExchangePacked ||
                        item.itemStatus === OrderStatus.ExchangeDispatched ||
                        item.itemStatus === OrderStatus.Exchanged,
                )!;

                this.latestStatus = this.nonCancelledItems?.[0]?.itemStatusHistory?.[0].status || "";

                this.latestStatusReturnedItems = this.returnedItems?.[0]?.itemStatusHistory?.[0].status || "";

                this.latestStatusExchangeItem = this.exchangeItems?.[0]?.itemStatusHistory?.[0].status || "";

                this.returnItemIds = this.returnedItems.map((item) => item.id!);

                this.exchangeItemIds = this.exchangeItems.map((item) => item.id!);

                this.status = this.order.orderItems?.[0].itemStatusHistory?.map((history) => history.status) || [];
            }
        });
    }

    public showDialogBoxCourierInfo(status: string): void {
        const dialogRef = this.dialog.open(GenericDialogComponent, {
            data: {
                componentName: DialogInvokingComponents.CourierInfo,
                title: "Enter Courier Information",
                inputs: [
                    {
                        label: "Courier Name",
                        value: "",
                        placeholder: "Enter Courier Name",
                        controlName: "courierName",
                        type: "text",
                    },
                    {
                        label: "AWB Number",
                        value: "",
                        placeholder: "Enter AWB Number",
                        controlName: "awbNumber",
                        type: "text",
                    },
                ],
                firstBtn: "Save",
                secondBtn: "Cancel",
            },
            autoFocus: false,
            restoreFocus: false,
        });
        dialogRef.componentInstance.saveClicked.subscribe((savedData) => {
            this.awbNumber = savedData.awbNumber;
            this.courierName = savedData.courierName;
            if (status === OrderStatus.Dispatched) {
                this.updateStatus(this.order, OrderStatus.Dispatched, "", "");
            } else {
                this.updateStatus(this.order, "", "", OrderStatus.ExchangeDispatched);
            }
        });
    }

    public showDialogBoxPickupScheduled(): void {
        const dialogRef = this.dialog.open(GenericDialogComponent, {
            disableClose: true,
            data: {
                componentName: DialogInvokingComponents.PickupScheduled,
                title: "Enter the Pickup Scheduled",
                inputs: [
                    {
                        label: "Date",
                        value: "",
                        placeholder: "Choose a date",
                        controlName: "date",
                        type: "date",
                    },
                    {
                        label: "Time",
                        value: "",
                        placeholder: "Select a time",
                        controlName: "time",
                        type: "time",
                    },
                ],
                firstBtn: "Save",
                secondBtn: "Cancel",
            },
            autoFocus: false,
            restoreFocus: false,
        });
        dialogRef.componentInstance.saveClicked.subscribe((savedData) => {
            this.pickUpDate = savedData.date;
            this.pickUpTime = savedData.time;
            this.updateStatus(this.order, "", OrderStatus.PickupScheduled, "");
        });
    }

    public openDialogRefundInitiated(): void {
        const dialogRef = this.dialog.open(GenericDialogComponent, {
            data: {
                componentName: DialogInvokingComponents.RefundInitiated,
                title: "Refund Initiated",
                content: "Please provide UDR number",
                inputs: [
                    {
                        controlName: "udrNumber",
                        value: "",
                        placeholder: "Insert UDR number",
                        type: "text",
                    },
                ],
                firstBtn: "Submit",
                secondBtn: "Cancel",
            },
            autoFocus: false,
            restoreFocus: false,
        });
        dialogRef.componentInstance.saveClicked.subscribe((savedData: DialogSaveDataModel) => {
            if (savedData) {
                this.udrNumber = savedData.udrNumber;
                this.updateStatus(this.order, "", RefundStatus.RefundInitiated, "");
            }
        });
    }

    public getOrderStatusClasses(item: OrderItem): { [key: string]: boolean } {
        return {
            failed: item?.itemStatus === OrderStatus.Failed,
            pending: item?.itemStatus === OrderStatus.Pending,
            ordered: item?.itemStatus === OrderStatus.Ordered,
            packed: item?.itemStatus === OrderStatus.Packed,
            cancelled: item?.itemStatus === OrderStatus.Cancelled,
            dispatched: item?.itemStatus === OrderStatus.Dispatched,
            delivered: item?.itemStatus === OrderStatus.Delivered,
            returnInitiated: item?.itemStatus === OrderStatus.ReturnInitiated,
            pickupScheduled: item?.itemStatus === OrderStatus.PickupScheduled,
            returned: item?.itemStatus === OrderStatus.Returned,
            exchangeInitiated:
                this.order.orderStatus === OrderStatus.ExchangeInitiated ||
                item?.itemStatus === OrderStatus.ExchangeInitiated,
            exchangePacked:
                this.order.orderStatus === OrderStatus.ExchangePacked ||
                item?.itemStatus === OrderStatus.ExchangePacked,
            exchangeDispatched:
                this.order.orderStatus === OrderStatus.ExchangeDispatched ||
                item?.itemStatus === OrderStatus.ExchangeDispatched,
            exchanged: this.order.orderStatus === OrderStatus.Exchanged || item?.itemStatus === OrderStatus.Exchanged,
        };
    }

    public updateStatus(order: OrderPageDetail, status: string, returnStatus: string, exchangeStatus: string): void {
        const id = this.id;
        const date = this.pickUpDate;
        const time = this.pickUpTime;
        const awbNumber = this.awbNumber;
        const courierName = this.courierName;
        const udrNumber = this.udrNumber;
        const newStatus = status || returnStatus || exchangeStatus;
        let itemIds!: number[];
        if (returnStatus) {
            itemIds = this.returnItemIds;
        }
        if (exchangeStatus) {
            itemIds = this.exchangeItemIds;
        }

        const data = { id, newStatus, date, time, order, awbNumber, courierName, udrNumber, itemIds };
        this.orderService.addOrderStatus(data).subscribe({
            next: (response) => {
                this.latestStatus = status;
                this.latestStatusReturnedItems = returnStatus;
                this.latestStatusExchangeItem = exchangeStatus;
                this.getOrderDetails();
            },
            error: (error) => {
                alert(error.error);
            },
        });
    }

    public getButtonColor(statusArray: OrderStatusItem[], buttonStatus: string): string | undefined {
        const currentIndex = statusArray.findIndex((status: { status: string }) => status.status === this.latestStatus);

        const buttonIndex = statusArray.findIndex((status: { status: string }) => status.status === buttonStatus);

        if (buttonIndex === currentIndex || buttonIndex < currentIndex) {
            return "green"; // Current status
        } else if (buttonIndex === currentIndex + 1) {
            return "yellow"; // Next status
        } else {
            return "grey"; // Other statuses
        }
    }

    public getButtonColorReturn(statusArray: OrderStatusItem[], buttonStatus: string): string | undefined {
        const currentIndex = statusArray.findIndex(
            (status: { status: string }) => status.status === this.latestStatusReturnedItems,
        );

        const buttonIndex = statusArray.findIndex((status: { status: string }) => status.status === buttonStatus);

        if (buttonIndex === currentIndex || buttonIndex < currentIndex) {
            return "green"; // Current status
        } else if (buttonIndex === currentIndex + 1) {
            return "yellow"; // Next status
        } else {
            return "grey"; // Other statuses
        }
    }

    public getButtonColorExchange(statusArray: OrderStatusItem[], buttonStatus: string): string | undefined {
        const currentIndex = statusArray.findIndex(
            (status: { status: string }) => status.status === this.latestStatusExchangeItem,
        );

        const buttonIndex = statusArray.findIndex((status: { status: string }) => status.status === buttonStatus);

        if (buttonIndex === currentIndex || buttonIndex < currentIndex) {
            return "green"; // Current status
        } else if (buttonIndex === currentIndex + 1) {
            return "yellow"; // Next status
        } else {
            return "grey"; // Other statuses
        }
    }

    public isReturnStatus(order: OrderPageDetail): boolean {
        const item = order.orderItems?.find(
            (item) =>
                item.itemStatus === OrderStatus.ReturnInitiated ||
                item.itemStatus === OrderStatus.PickupScheduled ||
                item.itemStatus === OrderStatus.Returned ||
                item.itemStatus === RefundStatus.RefundInitiated ||
                item.itemStatus === RefundStatus.RefundSuccessful,
        );
        const status = item?.itemStatus;
        return this.returnOrderStatusArray.some((s: { status: string }) => s.status === status);
    }

    public isExchangeStatus(order: OrderPageDetail): boolean {
        const item = order.orderItems?.find(
            (item) =>
                item.itemStatus === OrderStatus.ExchangeInitiated ||
                item.itemStatus === OrderStatus.ExchangePacked ||
                item.itemStatus === OrderStatus.ExchangeDispatched ||
                item.itemStatus === OrderStatus.Exchanged,
        );
        const status = item?.itemStatus;
        return this.exchangeOrderStatusArray.some((s: { status: string }) => s.status === status);
    }

    public getStatusDate(status: string): string | undefined {
        const statusExists = this.statusArray.some((item) => item.status === status);

        const returnStatusExist = this.returnOrderStatusArray.some((item) => item.status === status);

        const exchangeStatusExist = this.exchangeOrderStatusArray.some((item) => item.status === status);

        if (statusExists) {
            const historyEntry = this.nonCancelledItems?.[0]?.itemStatusHistory?.find(
                (history) => history.status === status,
            );
            return historyEntry ? moment(historyEntry.createdAt).format("DD/MM/yyyy hh:mm a") : undefined;
        } else if (returnStatusExist) {
            const historyEntry = this.returnedItems?.[0]?.itemStatusHistory?.find(
                (history) => history.status === status,
            );
            return historyEntry ? moment(historyEntry.createdAt).format("DD/MM/yyyy hh:mm a") : undefined;
        } else if (exchangeStatusExist) {
            const historyEntry = this.exchangeItems?.[0]?.itemStatusHistory?.find(
                (history) => history.status === status,
            );
            return historyEntry ? moment(historyEntry.createdAt).format("DD/MM/yyyy hh:mm a") : undefined;
        }
        return undefined;
    }

    public ispickupSchedule(): boolean {
        return this.status?.includes(OrderStatus.PickupScheduled);
    }

    public hasBeenDispatched(): boolean {
        return this.status?.includes(OrderStatus.Dispatched);
    }

    public hasBeenExchangeDispatched(): boolean {
        return this.status?.includes(OrderStatus.ExchangeDispatched);
    }

    public isRefundInitiated(): boolean {
        return this.status?.includes(RefundStatus.RefundInitiated);
    }

    public getUdrNumber(): string {
        const refund = this.order.orderItems?.find((item) => item.refund);
        return refund?.refund?.udrNumber!;
    }

    public getRefundAmount(): number {
        const refund = this.order.orderItems?.find((item) => item.refund);
        return refund?.refund?.refundAmount!;
    }

    get refundStatusMessage(): string {
        return this.order?.orderItems?.every(
            (item) => item.refund !== null && item.refund?.refundStatus === RefundStatus.RefundSuccessful,
        )
            ? RefundStatus.RefundSuccessful
            : "";
    }

    public isOverflow(el: HTMLElement): boolean {
        return el.clientWidth < el.scrollWidth || el.clientHeight < el.scrollHeight;
    }

    public getRefundStatusClass(refundStatus: string | undefined): string {
        return this.orderService.getRefundStatusClass(refundStatus);
    }

    public getExchangeAwbNumber(order: OrderPageDetail): string | undefined {
        const exchangeItem = order?.orderItems?.find((orderItem) => orderItem.exchange?.exchangeAwbNumber);
        return exchangeItem?.exchange?.exchangeAwbNumber;
    }

    public getExchangeCourierName(order: OrderPageDetail): string | undefined {
        const exchangeItem = order?.orderItems?.find((orderItem) => orderItem.exchange?.exchangeAwbNumber);
        return exchangeItem?.exchange?.exchangeCourierName;
    }

    public showReturnProcess(order: OrderPageDetail): boolean {
        return order.orderItems?.some((item) => item.itemStatus === OrderStatus.ReturnInitiated)!;
    }

    public showExchangeProcess(order: OrderPageDetail): boolean {
        return order.orderItems?.some((item) => item.itemStatus === OrderStatus.ExchangeInitiated)!;
    }
}
