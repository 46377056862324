<div class="right-navbar" [class.open]="isOpen">
    <mat-icon (click)="closeNavbar()" class="close-icon">close</mat-icon>
    <div class="wrapper">
        <div class="container" *ngIf="products.length">
            <div class="left-section">
                <mat-card *ngFor="let product of products">
                    <mat-card-content class="item-content">
                        <div class="item">
                            <div class="item-description">
                                <div class="item-image-container">
                                    <div class="image" (click)="onClickProduct(product.product.id)">
                                        <img
                                            *ngIf="product.product.images; else placeholder"
                                            [src]="product.product.images.imagesUrl[0]"
                                            alt="Item Image"
                                        />

                                        <ng-template #placeholder>
                                            <div class="image-placeholder"></div>
                                        </ng-template>
                                    </div>
                                </div>

                                <div class="item-detail">
                                    <b>
                                        {{ product.product.name }}
                                    </b>
                                    <p class="description">{{ product.product.description }}</p>
                                    <span *ngIf="product.product.discountPrice; else noDiscount"
                                        ><s>₹ {{ product.product.price }}</s> &nbsp;₹
                                        {{ product.product.discountPrice }}</span
                                    >
                                    <ng-template #noDiscount>
                                        <span>₹ {{ product.product.price }}</span>
                                    </ng-template>
                                    <br />
                                    <div *ngIf="product.product.category?.includes('Buy Blocks')">
                                        <!-- Plane Section -->
                                        <div *ngIf="extractFacesByType(product, 'Plane').length > 0">
                                            <span class="block-face-label">Plane: </span>
                                            <span class="block-face-value">{{
                                                extractFacesByType(product, "Plane")
                                            }}</span>
                                        </div>

                                        <!-- Hole Section -->
                                        <div *ngIf="extractFacesByType(product, 'Hole').length > 0">
                                            <span class="block-face-label">Hole: </span>
                                            <span class="block-face-value">{{
                                                extractFacesByType(product, "Hole")
                                            }}</span>
                                        </div>

                                        <!-- Node Section -->
                                        <div *ngIf="extractFacesByType(product, 'Node').length > 0">
                                            <span class="block-face-label">Node: </span>
                                            <span class="block-face-value">{{
                                                extractFacesByType(product, "Node")
                                            }}</span>
                                        </div>
                                        <!-- Shape Color -->
                                        <div>
                                            <span class="block-face-label">Color: </span>
                                            <span class="color-shape" [style.color]="getShapeColorValue(product)">
                                                {{ getShapeColorName(product) }}
                                            </span>
                                        </div>
                                    </div>
                                    <p class="return">7 days return available<br /><br /></p>
                                    <div class="unavailable" *ngIf="!product.product.isActive">
                                        <p>Product is currently unavailable.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="btn-quantity">
                                <div>
                                    <mat-icon (click)="onDeleteCartItem(product)" class="delete-btn"> delete </mat-icon>
                                </div>
                                <div class="quantity-count">
                                    <span
                                        ><button
                                            class="decrement"
                                            (click)="onClick(-1, product.product.id, product.cartUUID!)"
                                            [disabled]="
                                                getProductQtyInCart(product.product.id, product.cartUUID!) <=
                                                minQuantity
                                            "
                                        >
                                            -
                                        </button></span
                                    >
                                    <input
                                        type="number"
                                        class="quantity"
                                        [value]="getProductQtyInCart(product.product.id, product.cartUUID!)"
                                        (input)="onInputChange($event, product.product.id, product.cartUUID!)"
                                        (blur)="onBlur($event, product.product.id, product.cartUUID!)"
                                    />
                                    <span
                                        ><button
                                            class="increment"
                                            (click)="onClick(1, product.product.id, product.cartUUID!)"
                                            [disabled]="
                                                getProductQtyInCart(product.product.id, product.cartUUID!) >=
                                                maxQuantity
                                            "
                                        >
                                            +
                                        </button></span
                                    >
                                </div>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>

            <div class="right-section" *ngIf="products.length">
                <div class="item-price">
                    <mat-card>
                        <mat-card-content class="item-price-content">
                            <p>PRICE DETAILS</p>
                            <br />
                            <p class="mrp-value">
                                Total MRP <span>₹ {{ totalMRP }}</span>
                            </p>
                            <p class="discount-mrp">
                                Discount on MRP <span>- ₹ {{ totalDiscount }}</span>
                            </p>
                            <hr class="line" />
                            <p class="totl-amount">
                                Total Amount <span>₹ {{ totalAmount }}</span>
                            </p>
                        </mat-card-content>
                    </mat-card>
                </div>

                <div class="checkout-btn-cont">
                    <button mat-flat-button class="save-btn" (click)="onCheckout()" [disabled]="!isCheckoutEnabled">
                        Checkout</button
                    ><br /><br />
                    <div *ngIf="!isCheckoutEnabled" class="min-purchase-message">
                        The minimum purchase amount is Rs {{ minOrderValue }}. Please add more items to your cart.
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
