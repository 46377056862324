import { Injectable } from "@angular/core";
import { BehaviorSubject, debounceTime, fromEvent, Subject, Subscription } from "rxjs";
import { ComponentInteractionSrevice } from "src/app/services/component-interaction/component-interaction.service";
import { DragAndDropService } from "src/app/services/drag-and-drop/drag-and-drop.service";
import { Object3DUserData, PlaneCustomProperties } from "@utils/shape";
import * as THREE from "three";
import { Mesh } from "three";
import { SetShapeUserDataService } from "./set-shape-userdata.service";
import { ColorHEX, Events } from "@utils/action";
import { AvailableShapes } from "@utils/shape-facetype";
import { ExpandOrCompressShapesService } from "../../services/expand-or-compress-shapes/expand-or-compress-shapes.service";
import { TransformShapeOperations } from "../../../../../utils/shape";
import { MatDialog } from "@angular/material/dialog";
import { SceneManagerService } from "../../services/scene-manager/scene-manager.service";
import { CanvasConfig } from "../../../../../utils/canvas-configuration";
import { HelperService } from "./helper.service";
import { FaceIdentifierService } from "../face-identifier/face-identifier.service";
import { Face } from "../../../../../utils/count-calculator/count-calculator.model";
import { ColorCodes } from "../../../utils/color-constants";

interface selectedObject {
    uuid: string;
    color: string;
}

@Injectable({
    providedIn: "root",
})

/*
  All the @hostlistners or window events are written here and this service handles
  rendering also.
*/
export class InteractionService {
    private mouseClickPosition = new THREE.Vector2();
    private intersects: THREE.Intersection<THREE.Object3D<THREE.Event>>[] = [];
    private draggableObject: THREE.Object3D | null = null; // Because initially we wont be having any dragable shapes until generated and selected.
    private renderer!: THREE.WebGLRenderer;
    private camera!: THREE.PerspectiveCamera;
    public objects!: THREE.Object3D[];
    private mouseMovePosition = new THREE.Vector2();
    private isDoubleClick = false;
    private selectedColor: THREE.ColorRepresentation = "#FFFFFF";
    private selectedObjects: THREE.Mesh[] = [];
    private selectedObjectsUUID: string[] = [];
    private selectedObjectDetails: selectedObject[] = [];
    private isKeyCPressed: boolean = false;
    private userSeletedColor: boolean = false;
    private subscriptions: Subscription[] = [];
    private isEdittedSubject = new Subject<boolean>();
    public isEditted$ = this.isEdittedSubject.asObservable();
    public isSelectedSubject = new BehaviorSubject<boolean>(false);
    public isSelected = this.isSelectedSubject.asObservable();
    private currentScene!: THREE.Scene;

    constructor(
        private componentInteractionSrv: ComponentInteractionSrevice,
        private dragAndDropService: DragAndDropService,
        private setShapeUserDataService: SetShapeUserDataService,
        private expandOrCompressShapesService: ExpandOrCompressShapesService,
        private dialog: MatDialog,
        private sceneManagerService: SceneManagerService,
        private helperService: HelperService,
        private faceIdentifierSrv: FaceIdentifierService,
    ) {
        this.componentInteractionSrv.getSelectedColor().subscribe((color) => {
            this.userSeletedColor = true;
            this.selectedColor = color;
        });

        this.sceneManagerService.objects$.subscribe((objects) => {
            this.objects = objects;
        });
    }

    // Call this method wherever you modify `isEditted`
    private markAsEdited() {
        this.isEdittedSubject.next(true);
    }

    public subscribeToEvents(renderer: THREE.WebGLRenderer, camera: THREE.PerspectiveCamera): void {
        this.renderer = renderer;
        this.camera = camera;
        let isMetaKeyPressed = false;

        // We delay few miliseconds so that doubleclick and click wont conflict.
        this.subscriptions.push(
            fromEvent(document, Events.contextmenu)
                .pipe(debounceTime(150))
                .subscribe((event: Event) => {
                    if (!this.isDoubleClick) {
                        this.onContextMenu(event as MouseEvent);
                    }
                }),
        );

        this.subscriptions.push(
            fromEvent(document, Events.mousemove).subscribe((event: Event) => {
                this.onMouseMove(event as MouseEvent);
            }),
        );

        this.subscriptions.push(
            fromEvent(document, Events.click).subscribe((event: any) => {
                this.getObjectHit(event.clientX, event.clientY);
                this.onClick(event as MouseEvent);

                if (isMetaKeyPressed) {
                    this.handleMetaKeyPress();
                } else {
                    this.handleNonMetaKeyPress();
                }
            }),
        );

        this.subscriptions.push(
            fromEvent(document, Events.keydown).subscribe((event) => {
                if (this.intersects) {
                    if (this.objects[0] !== this.intersects[0]?.object) {
                        if ((event as KeyboardEvent).key === Events.backspace) {
                            if (this.intersects[0]?.object) {
                                this.sceneManagerService.removeMeshesFromScene(this.selectedObjects);
                                this.markAsEdited();
                            }
                        }
                    }
                }
                if ((event as KeyboardEvent).key === Events.keyc && (event as KeyboardEvent).key === Events.shiftKey) {
                    this.isKeyCPressed = true;
                } else if ((event as KeyboardEvent).key === Events.commandKey) {
                    isMetaKeyPressed = true;
                }
            }),
        );

        this.subscriptions.push(
            fromEvent(document, Events.keyup).subscribe((event) => {
                const key = (event as KeyboardEvent).key;
                const shapeToScale = this.intersects[0]?.object;
                const canScaleShape = shapeToScale && shapeToScale.name !== PlaneCustomProperties.name;

                switch (key) {
                    case Events.keyc:
                        this.isKeyCPressed = false;
                        break;
                    case Events.plusKey:
                        if (canScaleShape) {
                            this.expandOrCompressShapesService.scaleShape(
                                shapeToScale,
                                TransformShapeOperations.expand,
                            );
                        }
                        break;
                    case Events.minusKey:
                        if (canScaleShape) {
                            this.expandOrCompressShapesService.scaleShape(
                                shapeToScale,
                                TransformShapeOperations.compress,
                            );
                        }
                        break;
                    case Events.commandKey:
                        isMetaKeyPressed = false;
                        break;
                    default:
                        break;
                }
            }),
        );

        this.subscriptions.push(
            fromEvent(document, Events.doubleclick).subscribe((event: Event) => {
                this.isDoubleClick = true;
                this.onDoubleClick(event as MouseEvent);
                // This is to make the click event to wait so that it wont pick the newly generated shape.
                setTimeout(() => {
                    this.isDoubleClick = false;
                }, 300);
            }),
        );
    }

    public get intersect(): THREE.Object3D[] {
        return this.selectedObjects;
    }

    public unsubscribeToEvents(): void {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    // For selecting the shapes for drag and drop.
    private onContextMenu(event: MouseEvent): void {
        this.getObjectHit(event.clientX, event.clientY);
        if (
            this.intersects.length &&
            this.intersects[0].object.userData[Object3DUserData.isDraggable] &&
            !this.draggableObject
        ) {
            this.draggableObject = this.intersects[0].object;
            this.setShapeUserDataService.clearAdjacentDataOnDragStart(this.draggableObject as Mesh, this.objects);
        }
    }

    // For dragging the selected shape.
    private onMouseMove(event: MouseEvent): void {
        this.mouseMovePosition = new THREE.Vector2(
            (event.clientX / this.renderer.domElement.clientWidth) * 2 - 1,
            -(event.clientY / this.renderer.domElement.clientHeight) * 2 + 1,
        );
        this.dragAndDropService.dragObject(this.draggableObject, this.mouseMovePosition, this.camera, this.objects);
    }

    // For dropping the selected shape.
    private onClick(event: MouseEvent): void {
        if (this.isKeyCPressed) {
            this.componentInteractionSrv.setInterceptInfo2(this.intersects);
        }

        if (this.draggableObject) {
            this.draggableObject = this.dragAndDropService.dropObject(this.draggableObject, this.objects);
            // Mark as edited
            this.markAsEdited();
            this.draggableObject = null;
        }

        event.preventDefault(); // Prevent the default context menu from showing up
    }

    // For handeling the window resize, helps in responsive ui
    private onResize(): void {
        this.camera.aspect = window.innerWidth / innerHeight;
        this.renderer.setSize(window.innerWidth, window.innerHeight);
    }

    private onDoubleClick(event: MouseEvent): void {
        if (!this.dialog.openDialogs.length) {
            if (!this.draggableObject) {
                this.getObjectHit(event.clientX, event.clientY);
                if (this.intersects.length > 0) {
                    this.componentInteractionSrv.setInterceptInfo(this.intersects);
                }
            }
        }
    }

    // Gives the shapes that corresponds to the mouse co-ordinates.
    private getObjectHit(x: number, y: number): void {
        const raycaster = new THREE.Raycaster();
        this.mouseClickPosition.set(
            (x / this.renderer.domElement.clientWidth) * 2 - 1,
            -(y / this.renderer.domElement.clientHeight) * 2 + 1,
        );
        raycaster?.setFromCamera(this.mouseClickPosition, this.camera);
        this.intersects = raycaster?.intersectObjects(this.objects);
    }

    public getScene(): THREE.Scene {
        return this.currentScene;
    }

    private handleMetaKeyPress(): void {
        if (this.intersects.length > 0 && !this.userSeletedColor) {
            const selectedObject = this.intersects[0].object as THREE.Mesh;
            if (this.isValidObject(selectedObject)) {
                this.toggleSelection(selectedObject);
            }
        } else if (this.userSeletedColor) {
            this.applyUserSelectedColorToSelectedObjects();
        }
    }

    private handleNonMetaKeyPress(): void {
        if (this.userSeletedColor) {
            this.applyUserSelectedColorToSelectedObjects();
        }
        if (this.intersects.length > 0) {
            this.deselectAllObjects();
        }
    }

    private isValidObject(object: THREE.Mesh): boolean {
        return !!object.name && object.name !== AvailableShapes.Plane && object.name !== AvailableShapes.Outline;
    }

    private toggleSelection(object: THREE.Mesh): void {
        const isSelected = this.selectedObjectsUUID.includes(object.uuid);
        if (isSelected) {
            this.deselectObject(object);
        } else {
            this.selectObject(object);
        }
    }

    private selectObject(object: THREE.Mesh): void {
        this.isSelectedSubject.next(true);
        this.selectedColor = object.userData[Object3DUserData.colour];
        const updatedColor = new THREE.Color(ColorHEX.defaultColour);
        (object.material as THREE.MeshBasicMaterial).color = updatedColor;

        this.selectedObjects.push(object);
        this.selectedObjectsUUID.push(object.uuid);
        this.selectedObjectDetails.push({
            uuid: object.uuid,
            color: this.selectedColor.toString(),
        });
        this.componentInteractionSrv.setSelectedShapes(this.selectedObjects);
        const invalidShapes = this.componentInteractionSrv.getInvalidShapes;
        const validshapes = this.componentInteractionSrv.getValidShapes as THREE.Mesh[];

        if (invalidShapes.find((inValidShape) => inValidShape.uuid === object.uuid)) {
            validshapes.forEach((shape: THREE.Mesh) => {
                this.helperService.resetShapeProperties(shape);
            });
        } else if (validshapes.find((validShape) => validShape.uuid === object.uuid)) {
            const material = (object as THREE.Mesh).material as THREE.MeshStandardMaterial;
            material.transparent = false;
            material.opacity = 1;
        }
    }

    private deselectObject(selectedObject: THREE.Mesh): void {
        const index = this.selectedObjectsUUID.indexOf(selectedObject.uuid);
        const invalidShapes = this.componentInteractionSrv.getInvalidShapes;
        const isInvalidShapeSelected = invalidShapes.some((invalidShape) => invalidShape.uuid === selectedObject.uuid);
        (selectedObject.material as THREE.MeshBasicMaterial).color = isInvalidShapeSelected
            ? new THREE.Color(ColorCodes.red)
            : new THREE.Color(this.selectedColor);
        this.selectedObjectsUUID.splice(index, 1);
        this.selectedObjects.splice(index, 1);
        this.selectedObjectDetails.splice(index, 1);
    }

    public deselectAllObjects(): void {
        const invalidShapes = this.componentInteractionSrv.getInvalidShapes;
        this.selectedObjects.forEach((obj) => {
            const invalidDetails = invalidShapes.find((detail) => detail.uuid === obj.uuid);
            const selectedDetails = this.selectedObjectDetails.find((detail) => detail.uuid === obj.uuid);
            (obj.material as THREE.MeshBasicMaterial).color = invalidDetails
                ? new THREE.Color(ColorCodes.red)
                : new THREE.Color(selectedDetails?.color as THREE.ColorRepresentation);
        });
        this.clearSelection();
    }

    public applyUserSelectedColorToSelectedObjects(): void {
        this.selectedObjects.forEach((obj) => {
            const updatedColor = new THREE.Color(this.selectedColor);
            (obj.material as THREE.MeshBasicMaterial).color = updatedColor;
            obj.userData[Object3DUserData.colour] = this.selectedColor;
        });
        this.objects.forEach((object) => {
            const selectedObject = this.selectedObjects.find((so) => so.uuid === object.uuid);
            if (selectedObject) {
                object.userData[Object3DUserData.colour] = selectedObject.userData[Object3DUserData.colour];
            }
        });
        this.markAsEdited();
        this.clearSelection();
    }

    private clearSelection(): void {
        this.selectedObjects.length = 0;
        this.selectedObjectsUUID.length = 0;
        this.selectedObjectDetails.length = 0;
        this.userSeletedColor = false;
        this.isSelectedSubject.next(false);
    }

    // Utility method to calculate the grid index
    private calculateGridIndex(coordinate: number, cellSize: number): number {
        return coordinate / cellSize;
    }

    public checkIsGridNotAvailable(hitObject: THREE.Mesh, point: THREE.Vector3): boolean {
        if (hitObject.name === AvailableShapes.Plane) {
            const cellSize = CanvasConfig.cellSize;

            // Convert 3D coordinates to 2D grid coordinates
            const twoDCoords = new THREE.Vector2(point.x, point.z);
            const nearestGridCoords = this.helperService.getNearestGridCords(twoDCoords);

            // Calculate the grid row and column based on the nearest grid coordinates
            const row = this.calculateGridIndex(nearestGridCoords.y, cellSize);
            const column = this.calculateGridIndex(nearestGridCoords.x, cellSize);

            // Create cell reference object
            const cellReference = { row, column, cell: 0 };
            // Check if the cell is occupied
            return this.isMeshInCellReference(cellReference);
        } else {
            let cellReference: { row: number; column: number; cell: number } =
                hitObject.userData[Object3DUserData.cellReference];
            let adjacentCellReference: { row: number; column: number; cell: number } | null = null;

            if (this.faceIdentifierSrv.state.frontFace) {
                adjacentCellReference = this.getAdjacentCellForFace(Face.Front, cellReference);
            } else if (this.faceIdentifierSrv.state.rightFace) {
                adjacentCellReference = this.getAdjacentCellForFace(Face.Right, cellReference);
            } else if (this.faceIdentifierSrv.state.backFace) {
                adjacentCellReference = this.getAdjacentCellForFace(Face.Back, cellReference);
            } else if (this.faceIdentifierSrv.state.leftFace) {
                adjacentCellReference = this.getAdjacentCellForFace(Face.Left, cellReference);
            } else if (this.faceIdentifierSrv.state.topFace) {
                adjacentCellReference = this.getAdjacentCellForFace(Face.Top, cellReference);
            } else if (this.faceIdentifierSrv.state.bottomFace) {
                adjacentCellReference = this.getAdjacentCellForFace(Face.Bottom, cellReference);
            }

            return !!(adjacentCellReference && this.isMeshInCellReference(adjacentCellReference));
        }
    }

    // Method to calculate adjacent cell reference based on the current face
    private getAdjacentCellForFace(face: Face, currentCellReference: { row: number; column: number; cell: number }) {
        let adjacentCellReference = { ...currentCellReference };
        switch (face) {
            case Face.Front:
                adjacentCellReference.column += 1;
                break;
            case Face.Right:
                adjacentCellReference.row -= 1;
                break;
            case Face.Back:
                adjacentCellReference.column -= 1;
                break;
            case Face.Left:
                adjacentCellReference.row += 1;
                break;
            case Face.Top:
                adjacentCellReference.cell += 1;
                break;
            case Face.Bottom:
                adjacentCellReference.cell -= 1;
                break;
        }

        return adjacentCellReference;
    }

    public isMeshInCellReference(cellReference: { row: number; column: number; cell: number }): boolean {
        return this.objects.some(
            (obj) =>
                obj.userData?.[Object3DUserData.cellReference] &&
                obj.userData[Object3DUserData.cellReference].row === cellReference.row &&
                obj.userData[Object3DUserData.cellReference].column === cellReference.column &&
                obj.userData[Object3DUserData.cellReference].cell === cellReference.cell,
        );
    }
}
