import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AbstractControl, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { emailValidator } from "src/app/shared/email-validator.directive";
import { nameValidator } from "src/app/shared/name-validator.directive";
import { UserDetailsAttributes } from "@models/user.model";
import { noSpacesValidator } from "../../shared/shared/no-leading-space-validator.directive";
import { debounceTime } from "rxjs";

@Component({
    selector: "profile-details",
    templateUrl: "./profile-details.component.html",
    styleUrls: ["./profile-details.component.scss"],
})
export class ProfileDetailsComponent implements OnInit {
    @Output() profileData = new EventEmitter<UserDetailsAttributes>();
    @Input() hideAgeAndShowMobile: boolean = false;
    @Input() isInDialog: boolean = false;

    public profileForm!: FormGroup;

    constructor(private formBuilder: FormBuilder) {}

    ngOnInit(): void {
        this.profileForm = this.formBuilder.group({
            name: ["", [Validators.required, nameValidator(), noSpacesValidator()]],
            email: ["", [Validators.required, emailValidator()]],
            age: [{ value: "", disabled: this.hideAgeAndShowMobile }, [Validators.required, noSpacesValidator()]],
            userMobileNumber: [{ value: "", disabled: !this.hideAgeAndShowMobile }, Validators.required],
        });

        this.subscribeToFormChanges();
    }

    private subscribeToFormChanges(): void {
        this.profileForm.valueChanges.pipe(debounceTime(300)).subscribe((data) => {
            this.trimSpaces();

            if (this.profileForm.valid) {
                this.profileData.emit(data);
            }
        });
    }

    private trimSpaces(): void {
        const controlNames = ["name"];
        controlNames.forEach((controlName) => {
            const control = this.profileForm.get(controlName);
            if (control && control.value) {
                control.setValue(control.value.trim());
            }
        });
    }

    public onValidNumber(event: KeyboardEvent): boolean {
        const inp = event.key;
        if (/[0-9]/.test(inp)) {
            return true;
        } else {
            event.preventDefault();
            return false;
        }
    }

    public isInvalid(controlName: string, validatorName?: string): boolean {
        const contorl = this.profileForm.get(controlName);
        if (validatorName) {
            return contorl!.errors?.[validatorName];
        }
        return contorl!.invalid && contorl!.touched;
    }
}
