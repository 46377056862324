export interface NotificationModel {
    id?: number;
    userId?: number;
    emailBody: string;
    message: string;
    type: string;
    isMessageViewed: boolean;
    mailStatus: string;
    isDeleted: boolean;
}

export enum NotificationMessage {
    Successfull = "Notification marked as viewed successfully",
    Notificationnotfound = "Notification not found",
    Deletedsuccessfully = "Notification deleted successfully",
    SelectedNotificationsDeletedsuccessfully = "Selected notifications are deleted successfully",
    FailedToDelete = "Failed to delete notifications. Please try again",
}

export enum Mail {
    Mail = "Mail",
}
