<div class="wrapper">
    <div class="top-container">
        <!-- LeftSection -->
        <div class="left-section">
            <!-- Personal Info -->
            <div class="card" *ngIf="!isLoggedIn">
                <div class="already-registered">
                    <span>Already have an account? <span class="login-text" (click)="onLogin()"> Login </span></span>
                </div>
            </div>

            <!-- Shipping Address Card -->
            <div class="card">
                <div class="shipping-address-container">
                    <div class="row card-title">
                        <div class="address-content-heading" *ngIf="selectedAddress; else noAddress">
                            <span> Deliver to: </span>
                            &nbsp;
                            <span class="recipient-info">
                                {{ selectedAddress!.name }}, {{ selectedAddress!.pincode }}
                                <span class="address-type">{{ selectedAddress!.addressType }}</span></span
                            >
                        </div>
                        <ng-template #noAddress><p class="card-title">Shipping Address:</p> </ng-template>
                        <div class="add-address-button-container" *ngIf="selectedAddress; else addAddress">
                            <button mat-flat-button class="save-btn" (click)="onManageAddress()">Change</button>
                        </div>
                        <ng-template #addAddress>
                            <div class="change-address-button-container">
                                <button mat-flat-button class="save-btn" (click)="onManageAddress()">Add</button>
                            </div>
                        </ng-template>
                    </div>

                    <div class="row">
                        <div class="shipping-address-body" *ngIf="selectedAddress">
                            <p>
                                {{ selectedAddress.houseNumber }},
                                {{ selectedAddress.address }}
                            </p>
                            <p>{{ selectedAddress.city }}, {{ selectedAddress.state }}</p>
                            <p>{{ selectedAddress.mobileNumber }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Items Card -->
            <div class="card">
                <div class="items-container">
                    <p class="card-title">{{ title }}</p>
                    <div class="product-card-container">
                        <ng-container *ngFor="let item of cartItems; let last = last">
                            <div class="checkout-product-card">
                                <div class="product-main">
                                    <p
                                        class="product-name"
                                        [matTooltip]="item.product!.name"
                                        [matTooltipShowDelay]="500"
                                        [matTooltipHideDelay]="500"
                                        [matTooltipDisabled]="!isOverflow(productName)"
                                        #productName
                                    >
                                        {{ item.product!.name }}
                                    </p>
                                    <p>Qty: {{ item.quantity }}</p>

                                    <div *ngIf="item.product?.category?.includes(categories.BuyBlocks)">
                                        <!-- Plane Section -->
                                        <div *ngIf="getFacesByType(item, 'Plane').length > 0">
                                            <span class="block-face-label">Plane: </span>
                                            <span class="block-face-value">{{ getFacesByType(item, "Plane") }}</span>
                                        </div>

                                        <!-- Hole Section -->
                                        <div *ngIf="getFacesByType(item, 'Hole').length > 0">
                                            <span class="block-face-label">Hole: </span>
                                            <span class="block-face-value">{{ getFacesByType(item, "Hole") }}</span>
                                        </div>

                                        <!-- Node Section -->
                                        <div *ngIf="getFacesByType(item, 'Node').length > 0">
                                            <span class="block-face-label">Node: </span>
                                            <span class="block-face-value">{{ getFacesByType(item, "Node") }}</span>
                                        </div>
                                        <!-- Shape Color -->
                                        <div>
                                            <span class="block-face-label">Color: </span>
                                            <span class="color-shape" [style.color]="getShapeColorValue(item)">
                                                {{ getShapeColorName(item) }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="product-price">
                                    <p *ngIf="item.product!.discountPrice; else noDiscount">
                                        <s>₹ {{ item.product!.price }} </s>

                                        <span> &nbsp; ₹ {{ item.product!.discountPrice }}</span>
                                    </p>
                                    <ng-template #noDiscount>
                                        <p>₹ {{ item.product!.price }}</p>
                                    </ng-template>
                                </div>
                            </div>
                            <!-- Divider -->
                            <div class="divider" *ngIf="!last"></div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>

        <!-- Right Section -->
        <div class="right-section">
            <div class="card sticky">
                <div class="order-summary-container">
                    <p class="card-title">Order Summary:</p>
                    <div class="column">
                        <div class="payment-details-container">
                            <div class="payment-details-row">
                                <p class="label">Subtotal</p>
                                <p class="value">₹ {{ subtotal }}</p>
                            </div>

                            <div class="payment-details-row">
                                <p class="label">Discount</p>
                                <p class="value">- ₹ {{ discount }}</p>
                            </div>

                            <div class="payment-details-row">
                                <p class="label">Shipping</p>
                                <p class="value">
                                    {{ deliveryCharge ? "₹ " + deliveryCharge : "FREE" }}
                                </p>
                            </div>

                            <!-- Divider -->
                            <div class="divider"></div>

                            <!-- Coins Used -->
                            <div class="payment-details-row" *ngIf="isUsingCoins">
                                <p class="label">Coins Used</p>
                                <p class="value">- ₹{{ coinsUsed }}</p>
                            </div>

                            <!-- Coupons Used -->
                            <div class="payment-details-row" *ngIf="appliedCoupon">
                                <p class="label">Coupon Discount</p>
                                <p class="value">- ₹{{ couponsValue }}</p>
                            </div>

                            <!-- Estimated Total -->
                            <div class="payment-details-row total-amount">
                                <p class="label">Total</p>
                                <p class="value">₹ {{ total }}</p>
                            </div>

                            <!-- Divider -->
                            <div class="divider"></div>

                            <div class="payment-details-row savings" *ngIf="discount || appliedCoupon || isUsingCoins">
                                <p>You will save ₹ {{ discount + maxAmount + coinsUsed }} on this order</p>
                            </div>

                            <!-- Agreement -->
                            <div class="agreement">
                                <p>By placing an order, you agree to the funfigz's</p>
                                <p>
                                    <span class="link" (click)="onTermsAndCondition()">Terms and Conditions</span>
                                    and
                                    <span class="link" (click)="onPrivacyPolicy()">Privacy Policy</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="bottom-container sticky">
        <div class="left-section">
            <div class="pay-now-panel">
                <div class="payment-type">
                    <mat-radio-group [(ngModel)]="selectedPaymentOption">
                        <mat-radio-button [disabled]="total === 0" value="online">Online Payment</mat-radio-button>
                        <mat-radio-button [disabled]="total === 0" value="cod">Cash on Delivery</mat-radio-button>
                    </mat-radio-group>
                </div>
                <div class="right">
                    <div *ngIf="totalCoinBalance > 0">
                        <mat-checkbox class="check-box" (change)="onUseCoinsChange($event)">
                            Use my reward {{ totalCoinBalance }} coins.
                        </mat-checkbox>
                    </div>
                    <button
                        *ngIf="total === 0"
                        [disabled]="minOrder()"
                        mat-flat-button
                        class="place-order-button"
                        (click)="handlePlaceOrder()"
                    >
                        Place Order
                    </button>
                    <button
                        *ngIf="total !== 0"
                        mat-flat-button
                        class="pay-now-button"
                        (click)="handlePayment()"
                        [disabled]="!(currentUser && selectedAddress) || !isPayNowEnabled"
                    >
                        {{ selectedPaymentOption === "cod" ? "Proceed" : "Pay Now" }}
                    </button>
                </div>
            </div>
            <div *ngIf="!isPayNowEnabled" class="min-purchase-message">
                The minimum purchase amount is Rs {{ minOrderValue }}. Please add more items to your cart.
            </div>
        </div>
        <div class="right-section"></div>
    </div>

    <!--Coupon section-->
    <div class="bottom-container sticky">
        <div class="left-section">
            <div class="coupon">
                <div class="head">
                    <h2>Apply Coupon</h2>
                    <h3 (click)="openCouponSidebar()" class="view-all">View All Coupons</h3>
                </div>
                <form [formGroup]="couponForm" (ngSubmit)="applyCoupon()">
                    <div class="coupon-input" *ngIf="!appliedCoupon">
                        <div class="input-container">
                            <input
                                class="input"
                                required
                                type="text"
                                formControlName="couponCode"
                                placeholder="Enter Coupon Code"
                                (focus)="couponForm.get('couponCode')?.markAsTouched()"
                            />
                            <button
                                mat-raised-button
                                class="apply-button"
                                [class.disabled]="
                                    !couponForm.get('couponCode')?.valid || couponForm.get('couponCode')?.pristine
                                "
                                (click)="applyCoupon()"
                            >
                                APPLY
                            </button>
                        </div>

                        <!-- Show error message if the coupon code is empty and the field is touched -->
                        <div
                            *ngIf="couponForm.get('couponCode')?.touched && couponForm.get('couponCode')?.value === ''"
                            class="error-message"
                        >
                            Please enter coupon code
                        </div>

                        <!-- Show this error message only if coupon is invalid (and it’s not empty) -->
                        <div
                            *ngIf="isCouponInvalid && couponForm.get('couponCode')?.value !== ''"
                            class="error-message"
                        >
                            This coupon is invalid or does not exist.
                        </div>

                        <!-- Show this error message only if the coupon has been used (and it’s not empty) -->
                        <div
                            *ngIf="isCouponUsed && couponForm.get('couponCode')?.value !== '' && !isCouponInvalid"
                            class="error-message"
                        >
                            This coupon has been used or is invalid.
                        </div>
                    </div>

                    <div *ngIf="appliedCoupon" class="coupon-container">
                        <div class="applied-coupon">
                            <div class="coupon-info">
                                <mat-icon class="check-icon">check_circle</mat-icon>
                                <span>{{ appliedCoupon }} applied</span>
                            </div>
                            <mat-icon (click)="removeCoupon()" class="delete-icon">delete</mat-icon>
                        </div>
                        <div class="coupon-discount">
                            <span>₹{{ couponsValue }}</span>
                            <span class="saved-text">Saved</span>
                            <mat-icon class="info-icon">info</mat-icon>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <!-- Sidebar for displaying all coupons -->
        <div *ngIf="showCouponSidebar" class="coupon-sidebar">
            <button (click)="closeCouponSidebar()" class="close-sidebar">X</button>
            <h2 *ngIf="availableCoupons.length !== 0">Available Coupons</h2>

            <!-- Display message if no coupons are available -->
            <h2 *ngIf="availableCoupons.length === 0">No Coupons Available</h2>
            <div *ngIf="availableCoupons.length === 0" class="no-coupons-message">
                <div class="no-orders-container">
                    <h2>COUPONS NOT FOUND</h2>
                    <p class="no-orders">no coupons available at this moment.</p>
                </div>
            </div>

            <!-- Display available coupons -->
            <div *ngFor="let coupon of availableCoupons">
                <mat-card class="offer">
                    <mat-card-header class="coupon-header">{{ coupon.description }}</mat-card-header>
                    <mat-card-content>
                        <div class="coupon-content" (click)="copyCouponCode(coupon.couponCode)">
                            <mat-icon class="copy">content_copy</mat-icon>
                            <span class="coupon-code">{{ coupon.couponCode }}</span>
                            <button
                                mat-raised-button
                                class="copy-btn"
                                (click)="applyCouponFromSidebar(coupon.couponCode)"
                            >
                                APPLY
                            </button>
                        </div>
                        <p class="line">Copy this promo code and paste when you checkout at</p>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>
</div>
