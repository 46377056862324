import { TableColumn } from "./data-grid.model";

import { Permission } from "./permission.model";
import { Images, ProductAttributes } from "./product.model";

export interface AdminModel {
    id?: number;
    name: string;
    email: string;
    password?: string;
    roles?: string;
    uuid?: string;
    isActive?: boolean;
    createdBy?: string;
    updatedBy?: string;
    userPermissions?: Permission[];
}

export interface ReviewModel {
    id: number;
    productId: number;
    orderId: number;
    rating: number;
    review: string;
    status: string;
    name: string;
    images: Images;
    rejectComment?: string;
    product?: ProductAttributes;
    createdAt?: Date;
    userId: number;
}

export enum ReviewStatus {
    Rejected = "Rejected",
    Approved = "Approved",
    Pending = "Pending",
}

export interface LoginModel {
    email: string;
    password: string;
}

export interface ConfirmMailModel {
    name: string | undefined;
    imageUrl: string;
    primaryMessage: string;
    resetLink: string;
    secondaryMessage: string;
}

export interface PermissionModel {
    id: number;
    role: string;
}

export enum RoleLabel {
    ManageUser = "Manage User",
    ManageProduct = "Manage Product",
    ManageOrder = "Manage Order",
    ManageReview = "Manage Review",
    ManageStructure = "Manage Structure",
    ManageAdvertisement = "Manage Advertisement",
    ManageCoupon = "Manage Coupon",
}

export interface resetPasswordModel {
    uuid: string;
    password: string;
}

export enum AdminStatus {
    Add = "Added successfully",
    Update = "Updated successfully",
    Delete = "Deleted successfully",
    DeleteAdvertisementFailed = "Error deleting advertisement",
    FailedUpdate = "Failed to update admin user",
    FailedUpdateReviewStatus = "Failed to update review status",
    Failed = "Failed to add admin",
    PasswordUpdate = "Reset password successfully",
    PasswordUpdateFailed = "Failed to reset password",
    LoginSuccess = "Login Successfully",
    ValidationFailed = "Failed to validate admin login",
    GetPermissionDataFailed = "Failed to get the permission data",
    InvalidLink = "Invalid reset link. Please contact the Admin.",
    EmailPasswordIncorrect = "Email or password is incorrect",
    FailedFetchAdminUsers = "Failed to fetch admin users",
    FailedGetUserDetails = "Failed to get user details",
    CheckEmailExistFail = "Fail to check email is exist",
    CheckEmailErrorMessage = "EmailId already exist.",
    CouponExist = "This Coupon is already exist",
    InactiveUser = "User account is inactive. Please contact support.",
    FailFetchAllReviews = "Failed to fetch all reviews",
    AddBannersSuccess = "Banners added successfully",
    AddBannersFailed = "Error adding banners",
    UpdateBannerFailed = "Error updating banner",
    FailFetchAdvertisements = "Error fetching advertisements",
    FailFetchAdvertisement = "Error fetching advertisement",
    AdvertisementNotFound = "Advertisement not found",
    FailedPasswordChange = "Inactive user cannot change the password",
    FailedGetAdminStatus = "Failed to fetch the admin status",
    AdminNotFound = "Admin not found",
    FailedFetchCoupons = "Failed to fetch coupons",
    FailedTOFetchCouponDetails = "Failed to fetch coupon details",
}

export const AdminPageListColumns: TableColumn[] = [
    { key: "action", value: "Action", type: "action" },
    { key: "name", value: "Name", type: "text" },
    { key: "createdBy", value: "Created By", type: "text", tooltipKey: "email" },
    { key: "updatedBy", value: "Updated By", type: "text", tooltipKey: "email" },
    { key: "createdAt", value: "Created At", type: "datetime" },
    { key: "updatedAt", value: "Updated At", type: "datetime" },
    { key: "isActive", value: "Active", type: "toggle" },
];

export const ReviewPageListColumn: TableColumn[] = [
    { key: "name", value: "Name", type: "text" },
    { key: "product.name", value: "Product Name", type: "text" },
    { key: "review", value: "Review", type: "text" },
    { key: "rating", value: "Rating", type: "number" },
    { key: "images", value: "Images", type: "imagesGallery" },
    { key: "rejectComment", value: "Rejected Reason", type: "text" },
    { key: "createdAt", value: "Created At", type: "datetime" },
    { key: "updatedAt", value: "Updated At", type: "datetime" },
    { key: "status", value: "Status", type: "button" },
    { key: "admin.name", value: "Action By", type: "text" },
];

export interface ToggleEvent {
    checked: boolean;
}

export interface ToggleChangeEvent {
    event: ToggleEvent;
    row: AdminModel;
}

export interface ResponseAdminEdit {
    message: string;
    updatedPermissions?: Permission[];
    currentUser?: number;
    active?: boolean;
}
