<div class="container" [ngClass]="{ 'in-dialog': isInDialog }">
    <form [formGroup]="profileForm">
        <div class="form">
            <div class="fields">
                <div class="input-field">
                    <label for="name" class="required">Name</label>
                    <input
                        id="name"
                        type="text"
                        formControlName="name"
                        class="profile-input"
                        placeholder="Enter your name"
                        maxlength="50"
                        required
                    />
                    <div *ngIf="isInvalid('name')">
                        <div *ngIf="isInvalid('name', 'required')" class="text-red">Enter the name!</div>
                        <div *ngIf="isInvalid('name', 'forbiddenName')" class="text-red">
                            Please do not use special characters or numbers!
                        </div>
                    </div>
                </div>

                <div class="input-field">
                    <label for="email" class="required">Email ID</label>
                    <input
                        id="email"
                        type="text"
                        formControlName="email"
                        class="profile-input"
                        placeholder="Enter your email"
                        required
                    />
                    <div *ngIf="isInvalid('email')">
                        <div *ngIf="isInvalid('email', 'required')" class="text-red">Enter the email!</div>
                        <div
                            *ngIf="isInvalid('email', 'invalidEmail') && !isInvalid('email', 'required')"
                            class="text-red"
                        >
                            Please enter a valid email address!
                        </div>
                    </div>
                </div>
                <div class="input-field" *ngIf="!hideAgeAndShowMobile">
                    <label for="age" class="required">Age</label>
                    <input
                        id="age"
                        type="text"
                        formControlName="age"
                        class="profile-input"
                        placeholder="Enter your age"
                        (keypress)="onValidNumber($event)"
                        required
                    />
                    <div *ngIf="isInvalid('age')">
                        <div *ngIf="isInvalid('age', 'required')" class="text-red">Enter the age!</div>
                    </div>
                </div>

                <div class="input-field" *ngIf="hideAgeAndShowMobile">
                    <label for="userMobileNumber" class="required">Phone Number</label>
                    <input
                        id="userMobileNumber"
                        type="text"
                        class="profile-input"
                        formControlName="userMobileNumber"
                        (keypress)="onValidNumber($event)"
                        placeholder="Enter mobile number"
                        maxlength="10"
                        minlength="10"
                    />
                    <div *ngIf="isInvalid('userMobileNumber')">
                        <div *ngIf="isInvalid('userMobileNumber', 'required')" class="text-red">
                            Enter the mobile number!
                        </div>
                        <div *ngIf="isInvalid('userMobileNumber', 'minlength')" class="text-red">
                            Please enter a valid 10-digit phone number!
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
