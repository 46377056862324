export const NORMAL_EPSILON = 0.001;

export enum CubeFace {
    Front = "front",
    Back = "back",
    Left = "left",
    Right = "right",
    Top = "top",
    Bottom = "bottom",
    Curved = "curved",
}

export interface FaceState {
    frontFace: boolean;
    backFace: boolean;
    leftFace: boolean;
    rightFace: boolean;
    topFace: boolean;
    bottomFace: boolean;
    curveFace: boolean;
}
