import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { ContactFormData } from "../../../models/contact.model";

@Injectable({
    providedIn: "root",
})
export class EmailService {
    constructor(private readonly http: HttpClient) {}

    public sendContactEmail(data: { contactBody: ContactFormData; isLogin: boolean }): Observable<string> {
        return this.http.post<string>(`${environment.apiUrlBasePath}/emails/sendContactEmail`, data);
    }
}
