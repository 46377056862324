import { CountOutputData } from "../utils/count-calculator/count-calculator.model";
import { TableColumn } from "./data-grid.model";
import { ProductDetails } from "./product.model";
import { RegisteredUserDetails, UserInstance } from "./user.model";
export interface StructureBaseModel {
    structureName: string;
    structureDescription: string;
    structureData?: string;
    isUpdated: boolean;
    status: string;
    imageurl?: string;
    modelQuantityJson?: ModelQuantityJson;
    otp?: RegisteredUserDetails;
}

export interface StructureUpdateModel {
    structureName?: string;
    structureDescription?: string;
    structureData?: string;
    isUpdated?: boolean;
    status?: string;
    imageurl?: string;
    modelQuantityJson?: ModelQuantityJson;
    productId?: number;
}

export interface ModelQuantityJson {
    totalcount: number;
    faceinformation: CountOutputData[];
}

export interface StructureAttributes extends StructureBaseModel {
    id: number;
    userId: number;
    adminId?: number;
    productId?: number;
    userDetails?: UserInstance;
    Product?: ProductDetails;
}

export interface StructureCreationAttributes extends StructureBaseModel {
    id?: number;
    userId?: number;
}

export interface StructureInstance extends StructureAttributes {
    createdAt?: Date;
    updatedAt?: Date;
    isCheckbox?: boolean;
}

export type NullableStructureInstance = StructureInstance | null;

export enum StructureResponseMessage {
    NoStructureCreatedBeforePublish = "No structure created. Create a structure before publishing.",
    PublishedSuccess = "Published successfully.",
    PublishedFailed = "Publishing failed. Please try again later.",
    CannotPublishCurrentStatus = "Publishing is currently unavailable for this structure.",
    DeletedSuccess = "Deleted successfully.",
    DeletedFailed = "Deleting failed. Please try again later.",
    SaveSuccess = "Saved successfully.",
    Capture360 = "360 scene is captured successfully.",
    ApprovedSuccess = "Approved successfully.",
    SaveFailed = "Saving failed. Please try again later.",
    NoStructureFound = "No structure found. Please create a structure before saving.",
    UpdateSuccess = "Updated successfully.",
    UpdateFailed = "Oops! Update didn’t work.",
    GetStructuresFailed = "GetStructuresFailed.",
    FetchStructureDataFailed = "Failed to fetch structure data.",
    ShapeIsNotValid = "One or more shapes are not valid and cannot be saved",
    InvalidObject = "Invalid object data: Missing geometries or materials.",
    LoadFail = "Failed to load structure.",
}

export enum StructureStatus {
    Saved = "Saved",
    Published = "Published",
    Approved = "Approved",
    Rejected = "Rejected",
    Deleted = "Deleted",
}

export enum StructureOptions {
    Pending = "Pending",
    Approved = "Approved",
    Rejected = "Rejected",
}

export const structureReviewListColumns: TableColumn[] = [
    { key: "id", value: "Structure ID", type: "number" },
    { key: "structureName", value: "Structure Name", type: "text" },
    { key: "structureDescription", value: "Description", type: "text" },
    { key: "status", value: "Status", type: "text" },
    { key: "otp.user.name", value: "Created By", type: "text" },
    { key: "admin.name", value: "Actioned By", type: "text" },
];

export interface DialogData {
    name: string;
    id: number;
}

export interface Structurecanvas {
    structureName: string;
    structureDescription: string;
    structurId: number;
    structureData: string;
}

export enum MultipartErrorMessage {
    FailedToUploadPart = "Failed to upload part",
    ETagNotFound = "ETag not found",
    FailedToUpload = "Failed to upload file",
}
