import { Object3DUserData, initialOrientation } from "@utils/shape";
import { Injectable } from "@angular/core";
import { ComponentInteractionSrevice } from "src/app/services/component-interaction/component-interaction.service";
import {
    FaceType,
    Face,
    CountInputData,
    CountOutputData,
} from "../../../../utils/count-calculator/count-calculator.model";
import * as THREE from "three";
import { InteractionService } from "../shared/helpers/interaction.service";
import { SceneManagerService } from "./scene-manager/scene-manager.service";

@Injectable({
    providedIn: "root",
})
export class CountCalculatorService {
    constructor(
        private compIntSrv: ComponentInteractionSrevice,
        private sceneManagerService: SceneManagerService,
    ) {}

    public calculate(objects: THREE.Object3D[]) {
        let scene = this.sceneManagerService.scene;
        for (let i = 0; i < objects.length; i++) {
            let adjacentData = objects[i].userData[Object3DUserData.adjacentData];
            for (let face in adjacentData) {
                let faceUuid = adjacentData[face];
                const matchingObject = scene.getObjectByProperty("uuid", faceUuid);
                if (matchingObject) {
                    updateFaceType(objects[i], matchingObject, face);
                }
            }
        }

        function updateFaceType(firstObj: THREE.Object3D, secondObj: THREE.Object3D, face: string) {
            const firstObjOrientation = firstObj.userData[Object3DUserData.orientationData];
            let secondObjOrientation = secondObj?.userData[Object3DUserData.orientationData];
            let mainFace = "";
            let currentFaceType = "";
            let foundMeshFace = "";
            let foundMeshCurrentFaceType = "";
            if (face === Face.Front) {
                mainFace = firstObjOrientation[0];
                currentFaceType = firstObj.userData[Object3DUserData.faceType][mainFace];
                foundMeshFace = secondObjOrientation[2];
                foundMeshCurrentFaceType = secondObj.userData[Object3DUserData.faceType][foundMeshFace];
                if (foundMeshCurrentFaceType === FaceType.Plane && currentFaceType === FaceType.Plane) {
                    firstObj.userData[Object3DUserData.faceType][mainFace] = FaceType.Node;
                    secondObj.userData[Object3DUserData.faceType][foundMeshFace] = FaceType.Hole;
                } else {
                    if (foundMeshCurrentFaceType === FaceType.Node) {
                        if (currentFaceType === FaceType.Curved) {
                            firstObj.userData[Object3DUserData.faceType][mainFace] = FaceType.Curved;
                        } else {
                            firstObj.userData[Object3DUserData.faceType][mainFace] = FaceType.Hole;
                        }
                    } else if (currentFaceType === FaceType.Hole) {
                        if (currentFaceType === FaceType.Curved) {
                            firstObj.userData[Object3DUserData.faceType][mainFace] = FaceType.Curved;
                        } else {
                            firstObj.userData[Object3DUserData.faceType][mainFace] = FaceType.Node;
                        }
                    } else if (foundMeshCurrentFaceType === FaceType.Curved) {
                        if (currentFaceType === FaceType.Curved) {
                            firstObj.userData[Object3DUserData.faceType][mainFace] = FaceType.Curved;
                        } else {
                            firstObj.userData[Object3DUserData.faceType][mainFace] = FaceType.Plane;
                        }
                    } else if (foundMeshCurrentFaceType === FaceType.Plane) {
                        if (currentFaceType === FaceType.Curved) {
                            secondObj.userData[Object3DUserData.faceType][foundMeshFace] = FaceType.Plane;
                        } else if (currentFaceType === FaceType.Node) {
                            secondObj.userData[Object3DUserData.faceType][foundMeshFace] = FaceType.Hole;
                        } else if (currentFaceType === FaceType.Node) {
                            secondObj.userData[Object3DUserData.faceType][foundMeshFace] = FaceType.Node;
                        }
                    }

                    if (currentFaceType === FaceType.Curved) {
                        if (foundMeshCurrentFaceType === FaceType.Curved) {
                            secondObj.userData[Object3DUserData.faceType][foundMeshFace] = FaceType.Curved;
                        } else {
                            secondObj.userData[Object3DUserData.faceType][foundMeshFace] = FaceType.Plane;
                        }
                    }
                }
            } else if (face === Face.Right) {
                mainFace = firstObjOrientation[1];
                currentFaceType = firstObj.userData[Object3DUserData.faceType][mainFace];
                foundMeshFace = secondObjOrientation[3];
                foundMeshCurrentFaceType = secondObj.userData[Object3DUserData.faceType][foundMeshFace];
                if (foundMeshCurrentFaceType === FaceType.Plane && currentFaceType === FaceType.Plane) {
                    firstObj.userData[Object3DUserData.faceType][mainFace] = FaceType.Node;
                    secondObj.userData[Object3DUserData.faceType][foundMeshFace] = FaceType.Hole;
                } else {
                    if (foundMeshCurrentFaceType === FaceType.Node) {
                        if (currentFaceType === FaceType.Curved) {
                            firstObj.userData[Object3DUserData.faceType][mainFace] = FaceType.Curved;
                        } else {
                            firstObj.userData[Object3DUserData.faceType][mainFace] = FaceType.Hole;
                        }
                    } else if (currentFaceType === FaceType.Hole) {
                        if (currentFaceType === FaceType.Curved) {
                            firstObj.userData[Object3DUserData.faceType][mainFace] = FaceType.Curved;
                        } else {
                            firstObj.userData[Object3DUserData.faceType][mainFace] = FaceType.Node;
                        }
                    } else if (foundMeshCurrentFaceType === FaceType.Curved) {
                        if (currentFaceType === FaceType.Curved) {
                            firstObj.userData[Object3DUserData.faceType][mainFace] = FaceType.Curved;
                        } else {
                            firstObj.userData[Object3DUserData.faceType][mainFace] = FaceType.Plane;
                        }
                    } else if (foundMeshCurrentFaceType === FaceType.Plane) {
                        if (currentFaceType === FaceType.Curved) {
                            secondObj.userData[Object3DUserData.faceType][foundMeshFace] = FaceType.Plane;
                        } else if (currentFaceType === FaceType.Node) {
                            secondObj.userData[Object3DUserData.faceType][foundMeshFace] = FaceType.Hole;
                        } else if (currentFaceType === FaceType.Node) {
                            secondObj.userData[Object3DUserData.faceType][foundMeshFace] = FaceType.Node;
                        }
                    }

                    if (currentFaceType === FaceType.Curved) {
                        if (foundMeshCurrentFaceType === FaceType.Curved) {
                            secondObj.userData[Object3DUserData.faceType][foundMeshFace] = FaceType.Curved;
                        } else {
                            secondObj.userData[Object3DUserData.faceType][foundMeshFace] = FaceType.Plane;
                        }
                    }
                }
            } else if (face === Face.Back) {
                mainFace = firstObjOrientation[2];
                currentFaceType = firstObj.userData[Object3DUserData.faceType][mainFace];
                foundMeshFace = secondObjOrientation[0];
                foundMeshCurrentFaceType = secondObj.userData[Object3DUserData.faceType][foundMeshFace];
                if (foundMeshCurrentFaceType === FaceType.Plane && currentFaceType === FaceType.Plane) {
                    firstObj.userData[Object3DUserData.faceType][mainFace] = FaceType.Node;
                    secondObj.userData[Object3DUserData.faceType][foundMeshFace] = FaceType.Hole;
                } else {
                    if (foundMeshCurrentFaceType === FaceType.Node) {
                        if (currentFaceType === FaceType.Curved) {
                            firstObj.userData[Object3DUserData.faceType][mainFace] = FaceType.Curved;
                        } else {
                            firstObj.userData[Object3DUserData.faceType][mainFace] = FaceType.Hole;
                        }
                    } else if (currentFaceType === FaceType.Hole) {
                        if (currentFaceType === FaceType.Curved) {
                            firstObj.userData[Object3DUserData.faceType][mainFace] = FaceType.Curved;
                        } else {
                            firstObj.userData[Object3DUserData.faceType][mainFace] = FaceType.Node;
                        }
                    } else if (foundMeshCurrentFaceType === FaceType.Curved) {
                        if (currentFaceType === FaceType.Curved) {
                            firstObj.userData[Object3DUserData.faceType][mainFace] = FaceType.Curved;
                        } else {
                            firstObj.userData[Object3DUserData.faceType][mainFace] = FaceType.Plane;
                        }
                    } else if (foundMeshCurrentFaceType === FaceType.Plane) {
                        if (currentFaceType === FaceType.Curved) {
                            secondObj.userData[Object3DUserData.faceType][foundMeshFace] = FaceType.Plane;
                        } else if (currentFaceType === FaceType.Node) {
                            secondObj.userData[Object3DUserData.faceType][foundMeshFace] = FaceType.Hole;
                        } else if (currentFaceType === FaceType.Node) {
                            secondObj.userData[Object3DUserData.faceType][foundMeshFace] = FaceType.Node;
                        }
                    }

                    if (currentFaceType === FaceType.Curved) {
                        if (foundMeshCurrentFaceType === FaceType.Curved) {
                            secondObj.userData[Object3DUserData.faceType][foundMeshFace] = FaceType.Curved;
                        } else {
                            secondObj.userData[Object3DUserData.faceType][foundMeshFace] = FaceType.Plane;
                        }
                    }
                }
            } else if (face === Face.Left) {
                mainFace = firstObjOrientation[3];
                currentFaceType = firstObj.userData[Object3DUserData.faceType][mainFace];
                foundMeshFace = secondObjOrientation[1];
                foundMeshCurrentFaceType = secondObj.userData[Object3DUserData.faceType][foundMeshFace];
                if (foundMeshCurrentFaceType === FaceType.Plane && currentFaceType === FaceType.Plane) {
                    firstObj.userData[Object3DUserData.faceType][mainFace] = FaceType.Node;
                    secondObj.userData[Object3DUserData.faceType][foundMeshFace] = FaceType.Hole;
                } else {
                    if (foundMeshCurrentFaceType === FaceType.Node) {
                        if (currentFaceType === FaceType.Curved) {
                            firstObj.userData[Object3DUserData.faceType][mainFace] = FaceType.Curved;
                        } else {
                            firstObj.userData[Object3DUserData.faceType][mainFace] = FaceType.Hole;
                        }
                    } else if (currentFaceType === FaceType.Hole) {
                        if (currentFaceType === FaceType.Curved) {
                            firstObj.userData[Object3DUserData.faceType][mainFace] = FaceType.Curved;
                        } else {
                            firstObj.userData[Object3DUserData.faceType][mainFace] = FaceType.Node;
                        }
                    } else if (foundMeshCurrentFaceType === FaceType.Curved) {
                        if (currentFaceType === FaceType.Curved) {
                            firstObj.userData[Object3DUserData.faceType][mainFace] = FaceType.Curved;
                        } else {
                            firstObj.userData[Object3DUserData.faceType][mainFace] = FaceType.Plane;
                        }
                    } else if (foundMeshCurrentFaceType === FaceType.Plane) {
                        if (currentFaceType === FaceType.Curved) {
                            secondObj.userData[Object3DUserData.faceType][foundMeshFace] = FaceType.Plane;
                        } else if (currentFaceType === FaceType.Node) {
                            secondObj.userData[Object3DUserData.faceType][foundMeshFace] = FaceType.Hole;
                        } else if (currentFaceType === FaceType.Node) {
                            secondObj.userData[Object3DUserData.faceType][foundMeshFace] = FaceType.Node;
                        }
                    }

                    if (currentFaceType === FaceType.Curved) {
                        if (foundMeshCurrentFaceType === FaceType.Curved) {
                            secondObj.userData[Object3DUserData.faceType][foundMeshFace] = FaceType.Curved;
                        } else {
                            secondObj.userData[Object3DUserData.faceType][foundMeshFace] = FaceType.Plane;
                        }
                    }
                }
            } else if (face === Face.Top) {
                mainFace = firstObjOrientation[4];
                currentFaceType = firstObj.userData[Object3DUserData.faceType][mainFace];
                foundMeshFace = secondObjOrientation[5];
                foundMeshCurrentFaceType = secondObj.userData[Object3DUserData.faceType][foundMeshFace];
                if (foundMeshCurrentFaceType === FaceType.Plane && currentFaceType === FaceType.Plane) {
                    firstObj.userData[Object3DUserData.faceType][mainFace] = FaceType.Node;
                    secondObj.userData[Object3DUserData.faceType][foundMeshFace] = FaceType.Hole;
                } else {
                    if (foundMeshCurrentFaceType === FaceType.Node) {
                        if (currentFaceType === FaceType.Curved) {
                            firstObj.userData[Object3DUserData.faceType][mainFace] = FaceType.Curved;
                        } else {
                            firstObj.userData[Object3DUserData.faceType][mainFace] = FaceType.Hole;
                        }
                    } else if (currentFaceType === FaceType.Hole) {
                        if (currentFaceType === FaceType.Curved) {
                            firstObj.userData[Object3DUserData.faceType][mainFace] = FaceType.Curved;
                        } else {
                            firstObj.userData[Object3DUserData.faceType][mainFace] = FaceType.Node;
                        }
                    } else if (foundMeshCurrentFaceType === FaceType.Curved) {
                        if (currentFaceType === FaceType.Curved) {
                            firstObj.userData[Object3DUserData.faceType][mainFace] = FaceType.Curved;
                        } else {
                            firstObj.userData[Object3DUserData.faceType][mainFace] = FaceType.Plane;
                        }
                    } else if (foundMeshCurrentFaceType === FaceType.Plane) {
                        if (currentFaceType === FaceType.Curved) {
                            secondObj.userData[Object3DUserData.faceType][foundMeshFace] = FaceType.Plane;
                        } else if (currentFaceType === FaceType.Node) {
                            secondObj.userData[Object3DUserData.faceType][foundMeshFace] = FaceType.Hole;
                        } else if (currentFaceType === FaceType.Node) {
                            secondObj.userData[Object3DUserData.faceType][foundMeshFace] = FaceType.Node;
                        }
                    }

                    if (currentFaceType === FaceType.Curved) {
                        if (foundMeshCurrentFaceType === FaceType.Curved) {
                            secondObj.userData[Object3DUserData.faceType][foundMeshFace] = FaceType.Curved;
                        } else {
                            secondObj.userData[Object3DUserData.faceType][foundMeshFace] = FaceType.Plane;
                        }
                    }
                }
            } else if (face === Face.Bottom) {
                mainFace = firstObjOrientation[5];
                currentFaceType = firstObj.userData[Object3DUserData.faceType][mainFace];
                foundMeshFace = secondObjOrientation[4];
                foundMeshCurrentFaceType = secondObj.userData[Object3DUserData.faceType][foundMeshFace];
                if (foundMeshCurrentFaceType === FaceType.Plane && currentFaceType === FaceType.Plane) {
                    firstObj.userData[Object3DUserData.faceType][mainFace] = FaceType.Node;
                    secondObj.userData[Object3DUserData.faceType][foundMeshFace] = FaceType.Hole;
                } else {
                    if (foundMeshCurrentFaceType === FaceType.Node) {
                        if (currentFaceType === FaceType.Curved) {
                            firstObj.userData[Object3DUserData.faceType][mainFace] = FaceType.Curved;
                        } else {
                            firstObj.userData[Object3DUserData.faceType][mainFace] = FaceType.Hole;
                        }
                    } else if (currentFaceType === FaceType.Hole) {
                        if (currentFaceType === FaceType.Curved) {
                            firstObj.userData[Object3DUserData.faceType][mainFace] = FaceType.Curved;
                        } else {
                            firstObj.userData[Object3DUserData.faceType][mainFace] = FaceType.Node;
                        }
                    } else if (foundMeshCurrentFaceType === FaceType.Curved) {
                        if (currentFaceType === FaceType.Curved) {
                            firstObj.userData[Object3DUserData.faceType][mainFace] = FaceType.Curved;
                        } else {
                            firstObj.userData[Object3DUserData.faceType][mainFace] = FaceType.Plane;
                        }
                    } else if (foundMeshCurrentFaceType === FaceType.Plane) {
                        if (currentFaceType === FaceType.Curved) {
                            secondObj.userData[Object3DUserData.faceType][foundMeshFace] = FaceType.Plane;
                        } else if (currentFaceType === FaceType.Node) {
                            secondObj.userData[Object3DUserData.faceType][foundMeshFace] = FaceType.Hole;
                        } else if (currentFaceType === FaceType.Node) {
                            secondObj.userData[Object3DUserData.faceType][foundMeshFace] = FaceType.Node;
                        }
                    }

                    if (currentFaceType === FaceType.Curved) {
                        if (foundMeshCurrentFaceType === FaceType.Curved) {
                            secondObj.userData[Object3DUserData.faceType][foundMeshFace] = FaceType.Curved;
                        } else {
                            secondObj.userData[Object3DUserData.faceType][foundMeshFace] = FaceType.Plane;
                        }
                    }
                }
            }

            const initialOrientationData = initialOrientation;
            const adjacentData = firstObj.userData[Object3DUserData.adjacentData];
            const facesNotHavingAdjacentData = initialOrientationData.filter(
                (face) => !adjacentData.hasOwnProperty(face),
            );
            if (facesNotHavingAdjacentData) {
                for (let face of facesNotHavingAdjacentData) {
                    if (face === Face.Front) {
                        mainFace = firstObjOrientation[0];
                        currentFaceType = firstObj.userData[Object3DUserData.faceType][mainFace];
                        if (currentFaceType === FaceType.Curved) {
                            firstObj.userData[Object3DUserData.faceType][mainFace] = FaceType.Curved;
                        } else {
                            firstObj.userData[Object3DUserData.faceType][mainFace] = FaceType.Plane;
                        }
                    } else if (face === Face.Right) {
                        mainFace = firstObjOrientation[1];
                        currentFaceType = firstObj.userData[Object3DUserData.faceType][mainFace];
                        if (currentFaceType === FaceType.Curved) {
                            firstObj.userData[Object3DUserData.faceType][mainFace] = FaceType.Curved;
                        } else {
                            firstObj.userData[Object3DUserData.faceType][mainFace] = FaceType.Plane;
                        }
                    } else if (face === Face.Back) {
                        mainFace = firstObjOrientation[2];
                        currentFaceType = firstObj.userData[Object3DUserData.faceType][mainFace];
                        if (currentFaceType === FaceType.Curved) {
                            firstObj.userData[Object3DUserData.faceType][mainFace] = FaceType.Curved;
                        } else {
                            firstObj.userData[Object3DUserData.faceType][mainFace] = FaceType.Plane;
                        }
                    } else if (face === Face.Left) {
                        mainFace = firstObjOrientation[3];
                        currentFaceType = firstObj.userData[Object3DUserData.faceType][mainFace];
                        if (currentFaceType === FaceType.Curved) {
                            firstObj.userData[Object3DUserData.faceType][mainFace] = FaceType.Curved;
                        } else {
                            firstObj.userData[Object3DUserData.faceType][mainFace] = FaceType.Plane;
                        }
                    } else if (face === Face.Top) {
                        mainFace = firstObjOrientation[4];
                        currentFaceType = firstObj.userData[Object3DUserData.faceType][mainFace];
                        if (currentFaceType === FaceType.Curved) {
                            firstObj.userData[Object3DUserData.faceType][mainFace] = FaceType.Curved;
                        } else {
                            firstObj.userData[Object3DUserData.faceType][mainFace] = FaceType.Plane;
                        }
                    } else if (face === Face.Bottom) {
                        mainFace = firstObjOrientation[5];
                        currentFaceType = firstObj.userData[Object3DUserData.faceType][mainFace];
                        if (currentFaceType === FaceType.Curved) {
                            firstObj.userData[Object3DUserData.faceType][mainFace] = FaceType.Curved;
                        } else {
                            firstObj.userData[Object3DUserData.faceType][mainFace] = FaceType.Plane;
                        }
                    }
                }
            }
        }

        for (let i = 0; i < objects.length; i++) {
            const order = [Face.Front, Face.Right, Face.Back, Face.Left, Face.Top, Face.Bottom];

            const connectionData: {
                face: string;
                connectionType: string;
            }[] = [];

            let output = "";

            order.forEach((key) => {
                output += objects[i].userData[Object3DUserData.faceType][key][0];
                connectionData.push({
                    face: key,
                    connectionType: objects[i].userData[Object3DUserData.faceType][key][0],
                });
            });
            objects[i].userData[Object3DUserData.facePattern] = output;
            objects[i].userData[Object3DUserData.connectionData] = connectionData;
        }

        let facePatterns: any[] = [];
        for (let i = 0; i < objects.length; i++) {
            let object = {
                name: objects[i].name,
                facePattern: objects[i].userData[Object3DUserData.facePattern],
                color: objects[i].userData[Object3DUserData.colour],
                connectionData: objects[i].userData[Object3DUserData.connectionData],
            };
            facePatterns.push(object);
        }

        function transformInputToOutput(inputArray: CountInputData[]): CountOutputData[] {
            const outputMap = new Map<string, CountOutputData>();

            for (const item of inputArray) {
                const key = `${item.name}-${item.facePattern}-${item.color}`;
                if (outputMap.has(key)) {
                    // If the key exists in the map, increment the count
                    outputMap.get(key)!.count++;
                } else {
                    // If the key doesn't exist, create a new entry
                    outputMap.set(key, {
                        name: item.name,
                        facePattern: item.facePattern,
                        color: item.color,
                        count: 1,
                        connectionData: item.connectionData,
                    });
                }
            }

            // Convert the map values to an array
            const outputArray = Array.from(outputMap.values());
            return outputArray;
        }

        const outputPattern: CountOutputData[] = transformInputToOutput(facePatterns);
        this.compIntSrv.setOutputPattern(outputPattern);
    }
}
