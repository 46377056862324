<div class="container">
    <div class="success-container" *ngIf="callbackData">
        <p class="order-number"><span>Order number: </span>{{ callbackData!.orderid || orderId }}</p>
        <div class="inside-success-container">
            <mat-icon svgIcon="checkMark" class="check-mark" aria-hidden="false" aria-label="Check Mark"></mat-icon>
            <br />
            <h1>Order Confirmed !</h1>
            <br />
            <p>Your order has been placed successfully.</p>
            <p>Get delivery by Mon, 06 Feb - Thu, 09 Feb</p>
            <button mat-button class="track-order-btn" (click)="onTrackOrder()">Track My Order</button>
            <br />

            <button mat-button class="continue-btn" (click)="onContinueShopping()">Continue Shopping</button>
        </div>
    </div>
</div>
