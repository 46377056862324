import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Coupon } from "@models/coupon.model";
import { TableDataResponse } from "@models/data-grid.model";
import { Observable } from "rxjs";
import { environment } from "~/src/environments/environment";

@Injectable({
    providedIn: "root",
})
export class CouponService {
    constructor(private readonly http: HttpClient) {}

    public getCoupons(
        pageIndex: number = environment.dynamicPagination.pageIndex,
        pageSize: number = environment.dynamicPagination.pageSize,
        couponCode?: string,
    ): Observable<TableDataResponse> {
        let url = "";
        if (couponCode) {
            const encodedCouponCode = encodeURIComponent(couponCode);
            url = `${environment.apiUrlBasePath}/admin/getCoupons?pageIndex=${pageIndex}&pageSize=${pageSize}&couponCode=${encodedCouponCode}`;
        } else {
            url = `${environment.apiUrlBasePath}/admin/getCoupons?pageIndex=${pageIndex}&pageSize=${pageSize}`;
        }
        return this.http.get<TableDataResponse>(url);
    }

    public updateCouponStatus(params: { id: number; status: boolean }): Observable<string> {
        return this.http.put<string>(`${environment.apiUrlBasePath}/admin/updateCouponStatus`, params);
    }

    public addCoupon(data: Coupon): Observable<string> {
        return this.http.post<string>(`${environment.apiUrlBasePath}/admin/addCoupon`, data);
    }

    public updateCoupon(data: Coupon, couponId: number): Observable<string> {
        const params = { data, couponId };
        return this.http.put<string>(`${environment.apiUrlBasePath}/admin/updateCoupon`, params);
    }

    public isCouponExist(params: { couponCode: string }): Observable<{ exist: boolean; message: string }> {
        return this.http.post<{ exist: boolean; message: string }>(
            `${environment.apiUrlBasePath}/admin/isCouponExist`,
            params,
        );
    }

    public loadCouponDetails(couponId: number): Observable<Coupon> {
        const data = { couponId };
        return this.http.post<Coupon>(`${environment.apiUrlBasePath}/admin/getCouponDetails`, data);
    }

    public getAllActiveCoupon(): Observable<Coupon[]> {
        return this.http.get<Coupon[]>(`${environment.apiUrlBasePath}/admin/getAllActiveCoupon`);
    }

    public updateCouponOnProducts(activeCouponIds: number[], productIds: number[]): Observable<string> {
        const params = { activeCouponIds, productIds };
        return this.http.put<string>(`${environment.apiUrlBasePath}/admin/updateCouponOnProducts`, params);
    }

    public getAvailableCouponDetails(availableCouponCode: object): Observable<Coupon[]> {
        const params = { availableCouponCode };
        return this.http.post<Coupon[]>(`${environment.apiUrlBasePath}/products/getAvailableCouponDetails`, params);
    }
}
