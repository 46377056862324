export const MatSnackBarConfg = {
    ErrorMessage1: "Select a shape to Add",
    ErrorMessage2: "INVALID JOIN",
    ErrorMessage3: "INVALID POINT OF JOIN",
    ErrorMessage4: "Invalid Point Of Generation Shape Outside Canvas",
    ErrorMessage5: "Sorry, the selected grid is currently unavailable",
    ErrorMessageToPlaceCurved: "Cannot place on Curved Surface",
    CloseButton: "close",
    MessageDisplayDuration: 1000,
};
