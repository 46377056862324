import { Injectable } from "@angular/core";
import { Router, RoutesRecognized, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable, of, merge } from "rxjs";
import { filter, map } from "rxjs/operators";

@Injectable({
    providedIn: "root",
})
export class RouteDataService {
    constructor(private router: Router) {}

    // This function checks the data of the root route and then all its children for a set of keys.
    // For example: get({noMenu: false}) will search each route to see if it has `noMenu` in its children and take the value from the last child.
    // You can pass multiple values at once if you want more than one: get({noMenu: false, showLogo: true})
    // The value of each key is used as a default value in case the key is not present in any route.
    // Warning: code using this function uses the assumption that it will fire on each router.events of type RoutesRecognized
    public get<A extends object>(obj: A): Observable<A> {
        return merge(
            of({ state: this.router.routerState.snapshot }), // Use router state snapshot as a default value
            this.router.events.pipe(filter((event): event is RoutesRecognized => event instanceof RoutesRecognized)),
        ).pipe(
            map((event) => {
                const data = { ...obj } as A; // Shallow copy of obj

                const checkRouteData = (route: ActivatedRouteSnapshot) => {
                    if (route.data) {
                        Object.keys(obj).forEach((key) => {
                            if (route.data[key] !== undefined) {
                                (data as any)[key] = route.data[key]; // Assign route data to data object
                            }
                        });
                    }

                    if (route.firstChild) {
                        checkRouteData(route.firstChild); // Recursive call for child routes
                    }
                };

                if ((event as RoutesRecognized).state) {
                    const state = (event as RoutesRecognized).state;
                    if (state.root.firstChild) {
                        checkRouteData(state.root.firstChild);
                    }
                } else if ((event as { state: RouterStateSnapshot }).state) {
                    const state = (event as { state: RouterStateSnapshot }).state;
                    if (state.root.firstChild) {
                        checkRouteData(state.root.firstChild);
                    }
                }
                return data;
            }),
        );
    }
}
