import { Component, OnInit } from "@angular/core";
import { WishlistService } from "../../services/wishlistService/wishlist.service";
import { ProductDetails } from "@models/product.model";
import { Router } from "@angular/router";
import { GenericDialogComponent } from "../generic-dialog/generic-dialog.component";
import { DialogInvokingComponents } from "@models/generic-dialog.model";
import { MatDialog } from "@angular/material/dialog";
import { CartItemService } from "../../services/cart-item/cart-item.service";
import { CartItem } from "@models/cart.model";
import { WishlistResponseMessage } from "@models/wishlist.model";
import { MatSnackBar } from "@angular/material/snack-bar";
import { finalize } from "rxjs";
import { v4 as uuidv4 } from "uuid";

@Component({
    selector: "app-wishlist-page",
    templateUrl: "./wishlist-page.component.html",
    styleUrls: ["./wishlist-page.component.scss"],
})
export class WishlistPageComponent implements OnInit {
    public wishlistData: ProductDetails[] = [];
    public loading: boolean = true;
    public cartUUID!: string;

    constructor(
        private wishlistService: WishlistService,
        private router: Router,
        private dialog: MatDialog,
        private cartService: CartItemService,
        private snackBar: MatSnackBar,
    ) {}

    ngOnInit(): void {
        this.loadMyWishlistItems();
    }

    private loadMyWishlistItems(): void {
        this.loading = true;
        this.wishlistService
            .getWishListItems()
            .pipe(
                finalize(() => {
                    this.loading = false;
                }),
            )
            .subscribe({
                next: (response) => {
                    this.wishlistData = response.map((item: any) => item.product);
                    const inActiveIds = this.wishlistData
                        .filter((product) => !product.isActive)
                        .map((item: { id: number }) => item.id); // This field indicates that only active products should be filtered
                    if (inActiveIds.length) {
                        this.openDialogUnavailableProduct(inActiveIds);
                    }
                },
                error: (error) => {
                    throw error;
                },
            });
    }

    private openDialogUnavailableProduct(inActiveIds: number[]): void {
        const dialogRef = this.dialog.open(GenericDialogComponent, {
            data: {
                componentName: DialogInvokingComponents.RemoveFromCart,
                title: "Remove from Wishlist",
                content: "Products will be removed from the Wishlist due to being unavailable!",
                firstBtn: "Remove",
            },
            autoFocus: false,
            restoreFocus: false,
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                inActiveIds.map((id) => this.removeItemFromWishlist(id));
            }
        });
    }

    public onClickProduct(productId: number): void {
        this.router.navigate([`products/${productId}`]);
    }

    public deleteWishlistItem(item: ProductDetails): void {
        this.openToremoveItemFromWishList(item);
    }

    // Open to Dialog for Remove item from Wishlist
    private openToremoveItemFromWishList(item: ProductDetails) {
        const dialogRef = this.dialog.open(GenericDialogComponent, {
            data: {
                componentName: DialogInvokingComponents.RemoveFromCart,
                title: "Remove from Wishlist",
                image: item.images?.imagesUrl[0],
                content: "Are you sure you want to remove this item from Wishlist?",
                firstBtn: "Remove",
            },
            autoFocus: false,
            restoreFocus: false,
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.removeItemFromWishlist(item.id);
            }
        });
    }

    //Remove from the Wishlist
    public removeItemFromWishlist(productId: number): void {
        this.wishlistService.deleteFromMyWishlist(productId).subscribe((response) => {
            if (response) {
                this.loadMyWishlistItems();
            }
        });
    }

    //Move to Cart
    public moveToCart(product: ProductDetails, quantity: number): void {
        this.cartService.getCartItems().subscribe((cartResponse) => {
            const cartItems = cartResponse;
            const existingItem = cartItems.find((item) => item.product?.id === product.id);
            if (existingItem && existingItem.product) {
                // Update quantity if the item is already in the cart
                this.updateCartQuantity(existingItem.product.id, ++existingItem.quantity);
            } else {
                // Add new item to the cart if not exist
                this.addToCart(product.id, quantity);
            }
        });
    }

    private updateCartQuantity(productId: number, updatedQuantity: number): void {
        this.cartService.updateCartQuantity(productId, updatedQuantity).subscribe({
            next: (response) => {
                if (response) {
                    this.removeItemFromWishlist(productId);
                    this.snackBar.open(WishlistResponseMessage.MovetToCart, "Okay", { duration: 3000 });
                }
            },
        });
    }

    private addToCart(productId: number, quantity: number): void {
        this.cartUUID = uuidv4();
        const body: CartItem = { id: productId, quantity: quantity, cartUUID: this.cartUUID };
        this.cartService.addToCart(body).subscribe({
            next: (response) => {
                if (response) {
                    this.removeItemFromWishlist(productId);
                    this.snackBar.open(WishlistResponseMessage.MovetToCart, "Okay", { duration: 3000 });
                }
            },
        });
    }

    public goToHomePage(): void {
        this.router.navigate(["/home"]);
    }
}
