import { AbstractControl, ValidatorFn } from "@angular/forms";

interface LeadingSpacesError {
    leadingSpaces: {
        value: string;
    };
}

export function noSpacesValidator(): ValidatorFn {
    return (control: AbstractControl): LeadingSpacesError | null => {
        const value = control.value;
        const isValid = value === null || value === undefined || value.trim().length > 0;
        return isValid ? null : { leadingSpaces: { value: control.value } };
    };
}
