<div class="container">
    <h1>Privacy Policy</h1>
    <hr class="line" />
    <br />
    <div class="raw block1">
        <p>
            UDM Global Solution Private Limited (“UDM”), a private limited company, is the licensed owner of the brand
            and website FunFigz.com (“The Site”). We appreciate that you trust us with information about you that is
            required for you to effectively use our product, and we are committed to safeguarding and protecting the
            same. This Privacy Policy describes how your information and data are collected, stored and managed by UDM.
            By visiting FunFigz.com, or by accessing or subscribing to the services provided by FunFigz, you are
            accepting and consenting to the collection and use of your data by UDM in the manner described in this
            Privacy Policy.
        </p>
    </div>

    <div class="raw block2">
        <h2>Your Personal Information that we need</h2>

        <p>
            Your personal information helps us personalize and continually improve your shopping experience, assist
            sellers in handling and fulfilling orders, process payments, communicate with you about orders, products,
            services and promotional offers, and generally maintain your accounts with us. We also use this information
            to improve our platform and services, prevent or detect fraud or abuses of our website, and enable third
            parties to carry out technical and payment on our behalf.
        </p>
    </div>

    <div class="raw block3">
        <h2>The way that we collect your information</h2>

        <p>
            Your personal information helps us personalize and continually improve your shopping experience, assist
            sellers in handling and fulfilling orders, process payments, communicate with you about orders, products,
            services and promotional offers, and generally maintain your accounts with us. We also use this information
            to improve our platform and services, prevent or detect fraud or abuses of our website, and enable third
            parties to carry out technical and payment on our behalf.
        </p>
    </div>

    <div class="raw block4">
        <h2>Why do we need your information?</h2>

        <p>
            Your information is used to allow you to use services on the platform. Your information is used to manage
            your account, to improve the services, to process payments, to communicate important notices from time to
            time and to respond to your valuable queries, questions and suggestions.
        </p>

        <p>
            We may use personal information to resolve disputes that may arise with the use of Our services on the
            Platform, help promote a safe service to You on the Platform, measure consumer interest in Our services,
            customize Your experience, detect and protect against error, fraud and other illegal activity, enforce Our
            terms and conditionsWe may use personal information to resolve disputes that may arise with the use of Our
            services on the Platform, help promote a safe service to You on the Platform, measure consumer interest in
            Our services, customize Your experience, detect and protect against error, fraud and other illegal activity,
            enforce Our terms and conditions.
        </p>
    </div>

    <div class="raw block5">
        <h2>Sharing of your information</h2>

        <p>UDM does not support or indulge in selling or renting your personal data.</p>

        <p>
            We share Your information with third parties and third-party service providers upon receipt of request by
            You to initiate a transaction to carry out specific service requests and fulfill such service requests. We
            may also share your information with payment service providers for the purpose of fulfilling Services to
            you.
        </p>

        <p>
            We may disclose Your personal information if required to do so by law or in the good faith belief that such
            disclosure is reasonably necessary to respond to subpoenas, court orders, or other legal process. We may
            disclose personal information to law enforcement offices, third party rights owners, or others in the good
            faith belief that such disclosure is reasonably necessary to enforce our Terms or Privacy Policy; respond to
            claims that an advertisement, posting or other content violates the rights of a third party; or protect the
            rights, property or personal safety of our Users or the general public. Other than as set out above, you
            will receive notice when information about you might go to third parties and you will have an opportunity to
            choose not to share the information.
        </p>
    </div>

    <div class="raw block6">
        <h2>Choices you can make</h2>

        <p>
            As all the information is disclosed and shared voluntarily and willingly without any coercion by you, there
            is no liability pertaining to the authenticity/genuineness/ misrepresentation/ fraud/ negligence, etc. of
            the information disclosed shall lie on the Company nor will the Company in any way be responsible to verify
            any information obtained from You.
        </p>

        <p>You can always and at any point of time offload yourself from the platform.</p>
    </div>

    <div class="raw block7">
        <h2>Threat to your information</h2>

        <p>
            Your information during the transaction is encrypted and secured by using Secure Sockets Layer (SSL). UDM
            shall take reasonable steps to help protect your rights of privacy and your information (personal or
            otherwise) in an effort to prevent loss, misuse, unauthorized access, disclosure, alteration, or destruction
            of such information, in compliance with the applicable laws.
        </p>

        <p>
            UDM servers are accessible only to authorized personnel and your information is shared with employees and
            authorised personnel on a need to know basis to complete the transaction and to provide the services
            requested by you.
        </p>

        <p>
            You also play an important role in keeping your personal information and account secure. We request you to
            never share your username, password or any other security information of your FunFigz account with anyone
            else. We don't save any password or Payment related information on our servers. All logins are done via a
            OTP and for payments we used a third party payment gateway.
        </p>
    </div>

    <div class="raw block8">
        <h2>Grievances and correction of information:</h2>
        <p>To rectify or update any information or for any grievances, you can write to</p>
        <p class="email">{{ email }}</p>

        <h2>Policy Updates</h2>
        <p>
            UDM reserves the right to change or update this policy at any time. Such changes shall be effective
            immediately upon posting to the Site.
        </p>
    </div>

    <div class="raw block9">
        <h2>Contact Information</h2>
        <p># 55/3 & 55/4,</p>
        <p>8th Floor, Silpitha tech Park</p>
        <p>Devarabisanahalli Bellandur</p>
        <p>Bangalore – 560103, Karnataka, INDIA</p>
        <p>Phone: +9173386177665</p>
        <p class="email">{{ email }}</p>
    </div>
</div>
