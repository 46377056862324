import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root",
})
export class FilenameService {
    constructor() {}

    /**
     * Generate a timestamp-based filename
     * @param filename - Original filename
     * @param extension - File extension (default: 'glb')
     * @returns Formatted filename string
     */
    generateTimestampFileName(filename: string, extension: string = "glb"): string {
        // Use ISO 8601 format for consistent, sortable timestamp
        const timestamp = this.getFormattedTimestamp();
        return `${filename}_${timestamp}.${extension}`;
    }

    /**
     * Generate a formatted timestamp in YYYYMMDDHHMMSS format
     * @returns Formatted timestamp string
     */
    private getFormattedTimestamp(): string {
        const now = new Date();
        return now
            .toLocaleString()
            .replace(/[^\d]/g, "") // Remove non-digit characters
            .slice(0, 14); // Take first 14 characters (YYYYMMDDHHMMSS)
    }

    /**
     * Generate a unique filename
     * @param filename - Original filename
     * @param extension - File extension (default: 'glb')
     * @returns Unique filename string
     */
    generateUniqueFileName(filename: string, extension: string): string {
        const timestamp = Date.now();
        const randomString = Math.random().toString(36).substring(2, 7);
        return `${filename}_${timestamp}_${randomString}.${extension}`;
    }
}
