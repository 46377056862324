import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import * as THREE from "three";
import { Object3DUserData } from "../utils/shape";
import { ComponentInteractionSrevice } from "./services/component-interaction/component-interaction.service";

@Injectable({
    providedIn: "root",
})
export class ActionHelperService {
    private isRotatedSubject = new Subject<boolean>();
    public isRotated$ = this.isRotatedSubject.asObservable();

    private rotationSettings = {
        animate: true,
        smoothness: 0.3,
        isRotating: false,
    };

    private currentAnimation: {
        mesh: THREE.Mesh;
        targetQuaternion: THREE.Quaternion;
        startRotation: THREE.Quaternion;
        geometricCenter: THREE.Vector3;
    } | null = null;

    private rotationCenterHandlers: {
        [key: string]: (mesh: THREE.Mesh, boundingBox: THREE.Box3, geometricCenter: THREE.Vector3) => THREE.Vector3;
    } = {
        HalfCylinder: this.getHalfCylinderRotationCenter,
        HemiSphere: this.getHemiSphereRotationCenter,
        // Add more rotation center handlers as needed
    };

    constructor(private componentInteractionSrv: ComponentInteractionSrevice) {}

    // Call this method wherever you modify `isEditted`
    private markAsEdited() {
        this.isRotatedSubject.next(true);
    }

    private getHalfCylinderRotationCenter(
        mesh: THREE.Mesh,
        boundingBox: THREE.Box3,
        geometricCenter: THREE.Vector3,
    ): THREE.Vector3 {
        // Move the center point up to the top edge of the bounding box
        geometricCenter.y = boundingBox.max.y;
        // Transform the center point to world coordinates
        geometricCenter.applyMatrix4(mesh.matrixWorld);
        return geometricCenter;
    }

    private getHemiSphereRotationCenter(
        mesh: THREE.Mesh,
        boundingBox: THREE.Box3,
        geometricCenter: THREE.Vector3,
    ): THREE.Vector3 {
        // Move the center point up to the top of the bounding box
        geometricCenter.y = boundingBox.max.y;
        // Transform the center point to world coordinates
        geometricCenter.applyMatrix4(mesh.matrixWorld);
        return geometricCenter;
    }

    public getCustomRotationCenter(mesh: THREE.Mesh): THREE.Vector3 {
        // First get the standard geometric center
        mesh.geometry.computeBoundingBox();
        const boundingBox = mesh.geometry.boundingBox!;
        const geometricCenter = new THREE.Vector3();
        boundingBox.getCenter(geometricCenter);

        // Check if there's a custom rotation center handler for this mesh
        const customHandler = this.rotationCenterHandlers[mesh.name];
        if (customHandler) {
            return customHandler(mesh, boundingBox, geometricCenter);
        }
        // Transform the center point to world coordinates
        geometricCenter.applyMatrix4(mesh.matrixWorld);
        return geometricCenter;
    }

    private calculateProposedQuaternion(axis: string, angleInRad: number): THREE.Quaternion {
        const rotationQuaternion = new THREE.Quaternion();
        const rotationAxis = new THREE.Vector3();
        switch (axis) {
            case "x":
                rotationAxis.set(1, 0, 0);
                break;
            case "y":
                rotationAxis.set(0, 1, 0);
                break;
            case "z":
                rotationAxis.set(0, 0, 1);
                break;
        }
        rotationQuaternion.setFromAxisAngle(rotationAxis, angleInRad);
        return rotationQuaternion;
    }

    private performRotationAsync(
        axis: string,
        mesh: THREE.Mesh,
        inverse: boolean = false,
        angleInDegrees: number = 90,
    ): Promise<void> {
        return new Promise((resolve) => {
            // Convert angle from degrees to radians
            let angleInRad = THREE.MathUtils.degToRad(angleInDegrees);
            angleInRad = inverse ? -angleInRad : angleInRad;

            // Get the custom rotation center for this mesh
            const rotationCenter = this.getCustomRotationCenter(mesh);

            // Calculate target quaternion
            const targetQuaternion = new THREE.Quaternion();
            const rotationQuaternion = this.calculateProposedQuaternion(axis, angleInRad);
            targetQuaternion.multiplyQuaternions(rotationQuaternion, mesh.quaternion);

            // Apply rotation
            if (!this.rotationSettings.animate) {
                this.applyRotationAroundCenter(mesh, targetQuaternion, rotationCenter);
                resolve();
            } else {
                const animationDuration = 10;
                setTimeout(() => {
                    this.applyRotationAroundCenter(mesh, targetQuaternion, rotationCenter);
                    resolve();
                }, animationDuration);
            }
            // Mark the mesh as edited
            mesh.userData[Object3DUserData.initialPosition] = mesh.position;
            this.markAsEdited();
        });
    }

    private applyRotationAroundCenter(
        mesh: THREE.Mesh,
        targetQuaternion: THREE.Quaternion,
        center: THREE.Vector3,
    ): void {
        // Apply the rotation
        mesh.quaternion.copy(targetQuaternion);

        // Update the world matrix to reflect the new rotation
        mesh.updateMatrixWorld(true);

        // Get the new geometric center after rotation
        const newCenter = this.getCustomRotationCenter(mesh);

        // Calculate the position offset caused by rotation
        const offset = new THREE.Vector3().subVectors(newCenter, center);

        // Adjust the position to maintain the rotation center
        mesh.position.sub(offset);
    }

    public updateRotation(): void {
        if (!this.currentAnimation || !this.rotationSettings.animate || !this.rotationSettings.isRotating) {
            return;
        }

        const { mesh, targetQuaternion, geometricCenter } = this.currentAnimation;

        // Update rotation
        mesh.quaternion.slerp(targetQuaternion, this.rotationSettings.smoothness);

        // Apply the rotation while maintaining the geometric center
        this.applyRotationAroundCenter(mesh, mesh.quaternion, geometricCenter);

        // Check if we've reached the target rotation
        const isRotationComplete = mesh.quaternion.angleTo(targetQuaternion) < 0.01;

        if (isRotationComplete) {
            this.applyRotationAroundCenter(mesh, targetQuaternion, geometricCenter);
            this.rotationSettings.isRotating = false;
            this.currentAnimation = null;
        }
    }

    // normal orientation => front right back left top bottom
    public flipTop(mesh?: THREE.Mesh): void {
        if (mesh) {
            this.performRotationAsync("x", mesh, false, 180);
            const currentOrientation = [...mesh.userData["orientation"]];
            mesh.userData["orientation"] = [
                currentOrientation[0], // front remains the same
                currentOrientation[3], // right becomes left
                currentOrientation[2], // back remains the same
                currentOrientation[1], // left becomes right
                currentOrientation[5], // top becomes bottom
                currentOrientation[4], // bottom becomes top
            ];
        } else {
            const meshes = this.componentInteractionSrv.selectedshapes1 as THREE.Mesh[];
            const rotationPromises = meshes.map((mesh, index) => {
                const currentOrientation = [...mesh.userData["orientation"]];
                mesh.userData["orientation"] = [
                    currentOrientation[0], // front remains the same
                    currentOrientation[3], // right becomes left
                    currentOrientation[2], // back remains the same
                    currentOrientation[1], // left becomes right
                    currentOrientation[5], // top becomes bottom
                    currentOrientation[4], // bottom becomes top
                ];
                return this.performRotationAsync("x", mesh, false, 180);
            });
            Promise.all(rotationPromises).then(() => {});
        }
    }

    public flipLeft(mesh?: THREE.Mesh): void {
        if (mesh) {
            this.performRotationAsync("x", mesh);
            const currentOrientation = [...mesh.userData["orientation"]];
            mesh.userData["orientation"] = [
                currentOrientation[0], //front is same
                currentOrientation[5], //right become bottom
                currentOrientation[2], // back is same
                currentOrientation[4], //left become top
                currentOrientation[1], // top become right
                currentOrientation[3], // bottom become left
            ];
        } else {
            const meshes = this.componentInteractionSrv.selectedshapes1 as THREE.Mesh[];
            const rotationPromises = meshes.map((mesh, index) => {
                const currentOrientation = [...mesh.userData["orientation"]];
                mesh.userData["orientation"] = [
                    currentOrientation[0], //front is same
                    currentOrientation[5], //right become bottom
                    currentOrientation[2], // back is same
                    currentOrientation[4], //left become top
                    currentOrientation[1], // top become right
                    currentOrientation[3], // bottom become left
                ];
                return this.performRotationAsync("x", mesh);
            });
            Promise.all(rotationPromises).then(() => {});
        }
    }

    public flipRight(mesh?: THREE.Mesh): void {
        if (mesh) {
            this.performRotationAsync("x", mesh, true);
            const currentOrientation = [...mesh.userData["orientation"]];
            mesh.userData["orientation"] = [
                currentOrientation[0], // front is same
                currentOrientation[4], // right become top
                currentOrientation[2], // back is same
                currentOrientation[5], // left become bottom
                currentOrientation[3], // top become left
                currentOrientation[1], // bottom become right
            ];
        } else {
            const meshes = this.componentInteractionSrv.selectedshapes1 as THREE.Mesh[];
            const rotationPromises = meshes.map((mesh, index) => {
                const currentOrientation = [...mesh.userData["orientation"]];
                mesh.userData["orientation"] = [
                    currentOrientation[0], // front is same
                    currentOrientation[4], // right become top
                    currentOrientation[2], // back is same
                    currentOrientation[5], // left become bottom
                    currentOrientation[3], // top become left
                    currentOrientation[1], // bottom become right
                ];
                return this.performRotationAsync("x", mesh, true);
            });
            Promise.all(rotationPromises).then(() => {});
        }
    }

    public flipFront(mesh?: THREE.Mesh): void {
        if (mesh) {
            this.performRotationAsync("z", mesh, true);
            const currentOrientation = [...mesh.userData["orientation"]];
            mesh.userData["orientation"] = [
                currentOrientation[4], // front is top
                currentOrientation[1], // right is same
                currentOrientation[5], // back become bottom
                currentOrientation[3], // left is same
                currentOrientation[2], // top become back
                currentOrientation[0], // bottom become front
            ];
        } else {
            const meshes = this.componentInteractionSrv.selectedshapes1 as THREE.Mesh[];
            const rotationPromises = meshes.map((mesh, index) => {
                const currentOrientation = [...mesh.userData["orientation"]];
                mesh.userData["orientation"] = [
                    currentOrientation[4], // front is top
                    currentOrientation[1], // right is same
                    currentOrientation[5], // back become bottom
                    currentOrientation[3], // left is same
                    currentOrientation[2], // top become back
                    currentOrientation[0], // bottom become front
                ];
                return this.performRotationAsync("z", mesh, true);
            });
            Promise.all(rotationPromises).then(() => {});
        }
    }

    public flipBack(mesh?: THREE.Mesh): void {
        if (mesh) {
            this.performRotationAsync("z", mesh);
            const currentOrientation = [...mesh.userData["orientation"]];
            mesh.userData["orientation"] = [
                currentOrientation[5], // front is bottom
                currentOrientation[1], // right is same
                currentOrientation[4], // back become top
                currentOrientation[3], // left is same
                currentOrientation[0], // top become front
                currentOrientation[2], // bottom become back
            ];
        } else {
            const meshes = this.componentInteractionSrv.selectedshapes1 as THREE.Mesh[];
            const rotationPromises = meshes.map((mesh, index) => {
                const currentOrientation = [...mesh.userData["orientation"]];
                mesh.userData["orientation"] = [
                    currentOrientation[5], // front is bottom
                    currentOrientation[1], // right is same
                    currentOrientation[4], // back become top
                    currentOrientation[3], // left is same
                    currentOrientation[0], // top become front
                    currentOrientation[2], // bottom become back
                ];
                return this.performRotationAsync("z", mesh);
            });
            Promise.all(rotationPromises).then(() => {});
        }
    }

    public setAnimationSpeed(speed: number): void {
        this.rotationSettings.smoothness = speed;
    }

    public setAnimationState(animate: boolean): void {
        this.rotationSettings.animate = animate;
    }
}
