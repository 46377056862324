/*
    This method will return the dimension confuguration for all the shapes, we need to configure the
    dimension here and use it in property model.
    'isForSelector' is because we show the smaller version of each shape in the shape selector.
*/

import { AvailableShapes } from "@utils/shape-facetype";
import { ColorRepresentation } from "three";
import { ShapeConfig } from "./shape.model";

export const createShapeConfig = (color: ColorRepresentation, isForSelector: boolean): ShapeConfig => {
    const shapeConfiguration: ShapeConfig = {
        [AvailableShapes.Cube]: {
            width: isForSelector ? 3 : 20,
            height: isForSelector ? 3 : 20,
            depth: isForSelector ? 3 : 20,
            // This is for placement of the cube, has to be changed when new layout is changed.
            offSetCoordinates: isForSelector
                ? {
                      x: -8,
                      y: 5,
                      z: 0,
                  }
                : null,
            color: color,
        },
        //only for adding on the sl h of right triangle
        [AvailableShapes.Cuboid]: {
            width: 20,
            height: 28.28,
            depth: 20,
            color: color,
        },
        [AvailableShapes.CurvedCube]: {
            // This is how the dimensions are given for custom generated shape.
            firstLine: {
                x: 0,
                y: isForSelector ? 3 : 20,
            },
            arc: {
                x: 0,
                y: 0,
                radius: isForSelector ? 3 : 20,
                startAngle: 1.5,
                endAngle: 0,
                isClockwise: true,
            },
            lastLine: {
                x: 0,
                y: 0,
            },
            extrudeSetting: {
                depth: isForSelector ? 3 : 20,
                bevelEnabled: false,
                bevelSegments: 0,
                steps: 1,
                bevelSize: 0,
                bevelThickness: 0,
            },
            // This is for placement of the curved cube, has to be changed when new layout is changed.
            offSetCoordinates: isForSelector
                ? {
                      x: -3,
                      y: 5,
                      z: 0,
                  }
                : null,
            color: color,
        },
        [AvailableShapes.CurvedCube2]: {
            // This is how the dimensions are given for custom generated shape.
            moveTo: {
                x: 0,
                y: 0,
            },
            firstLine: {
                x: isForSelector ? 3 : 20, // width
                y: 0,
            },
            secondLine: {
                x: isForSelector ? 3 : 20,
                y: 10, // height
            },
            arc: {
                x: isForSelector ? 1.5 : 10, // center x of arc
                y: isForSelector ? 1.5 : 10, // center y of arc
                radius: isForSelector ? 1.5 : 10, // radius of the curve
                startAngle: 0,
                endAngle: Math.PI,
                isClockwise: false,
            },
            lastLine: {
                x: 0,
                y: 0,
            },
            extrudeSetting: {
                depth: isForSelector ? 3 : 20,
                bevelEnabled: false,
                bevelSegments: 0,
                steps: 1,
                bevelSize: 0,
                bevelThickness: 0,
            },
            offSetCoordinates: isForSelector
                ? {
                      x: 3,
                      y: 4.5,
                      z: 0,
                  }
                : null,
            color: color,
        },
        [AvailableShapes.HalfCylinder]: {
            arc: {
                x: 0,
                y: 0,
                radius: isForSelector ? 2 : 10,
                startAngle: 0,
                endAngle: Math.PI,
                isClockwise: false,
            },
            extrudeSetting: {
                depth: isForSelector ? 3 : 20,
                bevelEnabled: false,
                bevelSegments: 0,
                steps: 1,
                bevelSize: 0,
                bevelThickness: 0,
            },
            offSetCoordinates: isForSelector
                ? {
                      x: 8,
                      y: 5,
                      z: 0,
                  }
                : null,
            color: color,
        },
        [AvailableShapes.Prism]: {
            moveTo: {
                x: 0,
                y: 0,
            },
            firstLine: {
                x: isForSelector ? 3 : 20,
                y: 0,
            },
            secondLine: {
                x: isForSelector ? 1.5 : 10,
                y: isForSelector ? 3 : 20,
            },
            thirdLine: {
                x: 0,
                y: 0,
            },
            extrudeSetting: {
                amount: 1,
                depth: isForSelector ? 3 : 20, // Extrusion depth
                bevelEnabled: false,
            },
            // This is for placement of the cube, has to be changed when new layout is changed.
            offSetCoordinates: isForSelector
                ? {
                      x: -8,
                      y: 0,
                      z: 0,
                  }
                : null,
            color: color,
        },
        [AvailableShapes.SquarePyramid]: {
            radius: isForSelector ? 2 : 14.14,
            height: isForSelector ? 3 : 20,
            radialSegments: 4,
            heightSegments: 1,
            thetaStart: 0.785,
            // This is for placement of the Square Pyramid, has to be changed when new layout is changed.
            offSetCoordinates: isForSelector
                ? {
                      x: -3,
                      y: 0.5,
                      z: 0,
                  }
                : null,
            color: color,
        },
        [AvailableShapes.Cone]: {
            radius: isForSelector ? 2 : 10,
            height: isForSelector ? 3 : 20,
            radialSegments: 64,
            heightSegments: 1,
            // This is for placement of the Square Pyramid, has to be changed when new layout is changed.
            offSetCoordinates: isForSelector
                ? {
                      x: 3,
                      y: 0.5,
                      z: 0,
                  }
                : null,
            color: color,
        },
        [AvailableShapes.Cylinder]: {
            radiusTop: isForSelector ? 2 : 10,
            radiusBottom: isForSelector ? 2 : 10,
            height: isForSelector ? 3 : 20,
            radialSegments: 64,
            heightSegments: 1,
            // This is for placement of the Square Pyramid, has to be changed when new layout is changed.
            offSetCoordinates: isForSelector
                ? {
                      x: 8,
                      y: 0.5,
                      z: 0,
                  }
                : null,
            color: color,
        },
        [AvailableShapes.HemiSphere]: {
            radius: isForSelector ? 2 : 10,
            widthSegments: 16,
            heightSegments: 16,
            phiStart: 0,
            phiEnd: Math.PI,
            thetaStart: 0,
            thetaEnd: Math.PI,
            capRadius: isForSelector ? 2 : 10,
            capRadiusSegments: 15,
            // This is for placement of the Square Pyramid, has to be changed when new layout is changed.
            offSetCoordinates: isForSelector
                ? {
                      x: -8,
                      y: -5,
                      z: 0,
                  }
                : null,
            color: color,
        },
        [AvailableShapes.RightTriangle]: {
            moveTo: {
                x: 0,
                y: 0,
            },
            firstLine: {
                x: isForSelector ? 4 : 20, // Base of the triangle
                y: 0,
            },
            secondLine: {
                x: 0, // Move up vertically to form the right angle
                y: isForSelector ? 4 : 20, // Height of the triangle
            },
            extrudeSetting: {
                depth: isForSelector ? 4 : 20, // Extrusion depth for the 3D shape
                bevelEnabled: false,
                steps: 1,
                bevelSize: 0,
                bevelSegments: 0,
                bevelThickness: 0,
            },
            offSetCoordinates: isForSelector
                ? {
                      x: -5,
                      y: -7,
                      z: -10,
                  }
                : null,
            color: color,
        },
        [AvailableShapes.ConcavePrism]: {
            moveTo: {
                x: 0,
                y: isForSelector ? 3 : 20,
            },
            firstLine: {
                x: 0,
                y: 0,
            },
            arc: {
                x: isForSelector ? 3 : 20,
                y: isForSelector ? 3 : 20,
                radius: isForSelector ? 3 : 20,
                startAngle: Math.PI,
                endAngle: (3 * Math.PI) / 2,
                isClockwise: false,
            },
            lastLine: {
                x: 0,
                y: isForSelector ? 3 : 20,
            },
            extrudeSetting: {
                depth: isForSelector ? 3 : 20,
                bevelEnabled: false,
                bevelSegments: 0,
                steps: 1,
                bevelSize: 0,
                bevelThickness: 0,
            },
            // This is for placement of the curved cube, has to be changed when new layout is changed.
            offSetCoordinates: {
                x: 2,
                y: -5,
                z: 0,
            },
            color: color,
        },
    };
    return JSON.parse(JSON.stringify(shapeConfiguration));
};

export enum CylinderConfiguration {
    radiusTop = 0.6,
    radiusBottom = 0.6,
    height = 2,
    radialSegment = 32,
}
