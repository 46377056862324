<div class="container" *ngIf="order">
    <mat-card class="content">
        <mat-card-content>
            <mat-card-header>
                <p class="order-id">OrderId : {{ order.id }}</p>
            </mat-card-header>
            <div class="btn-group">
                <div class="btn-content" *ngFor="let status of statusArray">
                    <span class="status-label">{{ status.label }}</span>
                    <button
                        mat-fab
                        (click)="
                            status.label === orderStatus.Dispatched
                                ? showDialogBoxCourierInfo(status.status)
                                : updateStatus(order, status.status, '', '')
                        "
                        [ngClass]="getButtonColor(statusArray, status.status)"
                        [disabled]="
                            getButtonColor(statusArray, status.status) === 'green' ||
                            order.orderStatus === orderStatus.Cancelled ||
                            order.orderStatus === orderStatus.ReturnInitiated ||
                            isReturnStatus(order) ||
                            isExchangeStatus(order) ||
                            getButtonColor(statusArray, status.status) === 'grey'
                        "
                    >
                        <mat-icon>{{ status.icon }}</mat-icon>
                    </button>
                    <p>
                        {{ getStatusDate(status.status) }}
                    </p>
                    <div *ngIf="status.status === orderStatus.Dispatched && hasBeenDispatched()">
                        <p class="awb-number">
                            <strong>AWB Number :</strong>
                            <span class="text-color">{{ order.awbNumber }}</span>
                        </p>
                        <p class="courier-name">
                            <strong>Courier Name : </strong><span class="text-color">{{ order.courierName }}</span>
                        </p>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>

    <mat-card class="content" *ngIf="showExchangeProcess(order) || isExchangeStatus(order)">
        <mat-card-content>
            <mat-card-header>
                <p class="order-id">Exchange Order Status :</p>
            </mat-card-header>
            <div class="btn-group">
                <div class="btn-content" *ngFor="let exchangeStatus of exchangeOrderStatusArray">
                    <span class="status-label">{{ exchangeStatus.label }}</span>
                    <button
                        mat-fab
                        (click)="
                            exchangeStatus.label === orderStatus.ExchangeDispatched
                                ? showDialogBoxCourierInfo(exchangeStatus.status)
                                : updateStatus(order, '', '', exchangeStatus.status)
                        "
                        [ngClass]="getButtonColorExchange(exchangeOrderStatusArray, exchangeStatus.status)"
                        [disabled]="
                            getButtonColorExchange(exchangeOrderStatusArray, exchangeStatus.status) === 'green' ||
                            getButtonColorExchange(exchangeOrderStatusArray, exchangeStatus.status) === 'grey'
                        "
                    >
                        <mat-icon>{{ exchangeStatus.icon }}</mat-icon>
                    </button>
                    <p>
                        {{ getStatusDate(exchangeStatus.status) }}
                    </p>
                    <div
                        *ngIf="exchangeStatus.status === orderStatus.ExchangeDispatched && hasBeenExchangeDispatched()"
                    >
                        <p class="awb-number">
                            <strong>AWB Number :</strong>
                            <span class="text-color">{{ getExchangeAwbNumber(order) }}</span>
                        </p>
                        <p class="courier-name">
                            <strong>Courier Name : </strong
                            ><span class="text-color">{{ getExchangeCourierName(order) }}</span>
                        </p>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>

    <mat-card class="content" *ngIf="showReturnProcess(order) || isReturnStatus(order)">
        <mat-card-content>
            <mat-card-header>
                <p class="order-id">Return Order Status :</p>
            </mat-card-header>
            <div class="btn-group">
                <div class="btn-content" *ngFor="let returnStatus of returnOrderStatusArray">
                    <span class="status-label">{{ returnStatus.label }}</span>
                    <button
                        mat-fab
                        (click)="
                            returnStatus.label === orderStatus.PickupScheduled
                                ? showDialogBoxPickupScheduled()
                                : returnStatus.label === refundStatus.RefundInitiated
                                ? openDialogRefundInitiated()
                                : updateStatus(order, '', returnStatus.status, '')
                        "
                        [ngClass]="getButtonColorReturn(returnOrderStatusArray, returnStatus.status)"
                        [disabled]="
                            getButtonColorReturn(returnOrderStatusArray, returnStatus.status) === 'green' ||
                            getButtonColorReturn(returnOrderStatusArray, returnStatus.status) === 'grey'
                        "
                    >
                        <mat-icon>{{ returnStatus.icon }}</mat-icon>
                    </button>
                    <p>
                        {{ getStatusDate(returnStatus.status) }}
                    </p>
                    <div *ngIf="returnStatus.status === refundStatus.RefundInitiated && isRefundInitiated()">
                        <p class="awb-number">
                            <strong>UDR Number :</strong>
                            <span class="text-color">{{ getUdrNumber() }}</span>
                        </p>
                    </div>
                    <div
                        class="pickup-scheduled"
                        *ngIf="returnStatus.status === orderStatus.PickupScheduled && ispickupSchedule()"
                    >
                        <p>
                            <strong>Pickup Date :</strong>
                            <span class="text-color">{{ pickupScheduleDate }}</span>
                        </p>
                        <p>
                            <strong>Pickup Time : </strong><span class="text-color">{{ order.pickupTime }}</span>
                        </p>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>

    <div class="customer-info">
        <mat-card class="content">
            <mat-card-content>
                <div class="customer-info-content">
                    <div class="left">
                        <h3>Customer Information :</h3>
                        <p>Name : {{ order.recipientName }}</p>
                        <p>Phone Number : {{ order.recipientMobileNumber }}</p>
                        <p>Email Id : {{ order.customerEmail }}</p>
                        <p>
                            Address : {{ order.recipientHouseNumber }}, {{ order.recipientAddress }},
                            {{ order.recipientCity }}, {{ order.recipientState }},{{ order.recipientPincode }}
                        </p>
                    </div>
                    <div class="mid" *ngIf="order.refundOption">
                        <h3>Bank Details</h3>
                        <p *ngIf="order.accountHolderName">
                            Account Holder Name : <span class="bank-details">{{ order.accountHolderName }}</span>
                        </p>
                        <p *ngIf="order.accountNumber">
                            Account Number : <span class="bank-details">{{ order.accountNumber }}</span>
                        </p>
                        <p *ngIf="order.ifscCode">
                            IFSC code : <span class="bank-details">{{ order.ifscCode }}</span>
                        </p>
                        <p *ngIf="order.upiId">
                            UPI Id : <span class="bank-details">{{ order.upiId }}</span>
                        </p>
                        <p *ngIf="getRefundAmount()">
                            Refund Amount : <span class="bank-details"> ₹ {{ getRefundAmount() }}</span>
                        </p>
                    </div>
                    <div>
                        <h3>Status :</h3>
                        <p>
                            Payment :
                            <span
                                [ngClass]="{
                                    success: order.paymentStatus === paymentStatus.Success,
                                    pending: order.paymentStatus === paymentStatus.Pending,
                                    fail: order.paymentStatus === paymentStatus.Failed,
                                    cod: order.paymentStatus === paymentType.CashOnDelivery
                                }"
                                >{{ order.paymentStatus }}</span
                            >
                        </p>
                        <p *ngIf="this.isAllRefundSuccessful">
                            Refund Status :<span class="refund-successful">{{ refundStatusMessage }}</span>
                        </p>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>

    <div *ngFor="let item of this.order.orderItems">
        <mat-card class="content">
            <mat-card-content class="whole">
                <div class="product-info">
                    <div>
                        <img [src]="item.product?.images?.imagesUrl?.[0]" alt="Item Image" />
                    </div>
                    <div>
                        <p>Product Name : {{ item.product?.name }}</p>
                        <p>Quantity: {{ item.quantity }}</p>
                        <p>Price: Rs {{ item.discountPrice || item.price }}</p>
                    </div>
                </div>
                <div>
                    <div class="refund-status" *ngIf="item.refund?.refundStatus">
                        <p>
                            Refund Status:
                            <span [ngClass]="getRefundStatusClass(item.refund?.refundStatus)">{{
                                item.refund?.refundStatus
                            }}</span>
                        </p>
                    </div>

                    <div class="refund-status" *ngIf="item.itemStatus">
                        <p>
                            Order Status:
                            <span [ngClass]="getOrderStatusClasses(item)">{{ item.itemStatus }}</span>
                        </p>
                    </div>
                </div>
            </mat-card-content>
            <hr />
        </mat-card>
    </div>

    <div>
        <mat-card class="content">
            <mat-card-content>
                <p class="order-info">
                    <strong>Sub Total : </strong>
                    <span>₹ {{ order.totalAmount - order.deliveryCharge }}</span>
                </p>
                <p class="order-info">
                    <strong>Delivery Charge : </strong>
                    <span>₹ {{ order.deliveryCharge }}</span>
                </p>
                <p class="order-info">
                    <strong>Total Amount : </strong>
                    <span>₹ {{ order.totalAmount }}</span>
                </p>
            </mat-card-content>
        </mat-card>
    </div>
</div>
