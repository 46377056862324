import { Images } from "./product.model";

export interface RewardDetail {
    id: number;
    userId: number;
    coinsEarned: number;
    coinsSpent: number;
    orderId: number;
    productId: number;
    name: string | null;
    createdAt?: Date;
    type: string;
}

export interface RewardResponse {
    success: boolean;
    message: string;
    data: {
        userId: number;
        orderId: number;
        productId: number | null;
        coinsSpent: number;
    };
}

export interface Order {
    id: number;
    productId?: number;
}

export interface ProductReward {
    id: number;
    name: string;
    price: number;
    discountPrice?: number;
    quantity: number;
    images?: Images;
}

export interface EmailContent {
    message: string;
    structureName?: string;
    productName?: string;
    coinsEarned?: number;
    structureImage?: string;
    orderId?: number;
    quantity?: number;
    price?: number;
    productImage?: string;
    coinsSpent?: number;
    creditedOn?: string | undefined;
    debitedOn?: string | undefined;
    isDebit?: boolean;
    imageUrl?: string;
}

export interface RewardDetails {
    coinsEarned?: number;
    creditedOn?: Date;
    coinsSpent?: number;
    debitedOn?: Date;
}

export enum rewardCoins {
    coinsSpentSucessfully = "Coins spent updated successfully!",
    coinSpentFailed = "Failed to update coins spent. Please try again",
    coinsSpentSucessfull = "Coins spent updated successfully!",
}

export enum RewardType {
    Debited = "Debited",
    Credited = "Credited",
}
export interface Item {
    structure: {
        dataValues: {
            id: number;
            userId: number;
            imageurl: string | string[];
            structureName: string;
        };
    };
    product: {
        dataValues: {
            id: number;
            name: string;
            image: {
                imagesUrl: string | string[];
            };
        };
    };
}
