<div class="product-list-table">
    <div class="table-container">
        <mat-spinner *ngIf="dataLoading" class="loading-spinner" [diameter]="70"></mat-spinner>
        <table mat-table *ngIf="!dataLoading && dataSource.data" [dataSource]="dataSource">
            <ng-container matColumnDef="{{ col.key }}" *ngFor="let col of tableColumns; let i = index">
                <th mat-header-cell class="table-header-cell" *matHeaderCellDef>
                    <ng-container *ngIf="col.type !== 'checkbox'">
                        {{ col.value || col.key }}
                    </ng-container>
                </th>
                <td
                    mat-cell
                    #tdElement
                    class="table-data-cell hide-overflow"
                    [matTooltip]="
                        col.type === 'datetime'
                            ? (getValue(row, col.key) | date: 'dd/MM/yyyy hh:mm:ss a')
                            : col.tooltipKey
                            ? getValue(row, col.tooltipKey)
                            : getValue(row, col.key)
                    "
                    [matTooltipDisabled]="!isOverflow(tdElement)"
                    [matTooltipShowDelay]="500"
                    [matTooltipHideDelay]="500"
                    matTooltipPosition="above"
                    matTooltipClass="tooltip"
                    *matCellDef="let row"
                    [ngSwitch]="col.type"
                    (click)="onCellClick(row)"
                >
                    <ng-container *ngSwitchCase="'checkbox'">
                        <mat-checkbox
                            (change)="$event ? handleCheckboxChange(row) : null"
                            [checked]="selection.isSelected(row)"
                        ></mat-checkbox>
                    </ng-container>

                    <ng-container *ngSwitchCase="'action'">
                        <div class="actions" *ngIf="actions?.length">
                            <mat-icon
                                class="actions-icon"
                                matTooltip="{{ action.label }}"
                                [matTooltipShowDelay]="500"
                                [matTooltipHideDelay]="500"
                                matTooltipPosition="above"
                                matTooltipClass="iconTooltip"
                                (click)="onActionClick(action, row.id)"
                                *ngFor="let action of actions"
                                >{{ action.icon }}</mat-icon
                            >
                        </div>
                    </ng-container>

                    <ng-container *ngSwitchCase="'image'">
                        <img src="{{ row[col.key] }}" alt="" />
                    </ng-container>

                    <ng-container *ngSwitchCase="'thumbnail'">
                        <img src="{{ (row[col.key]?.imagesUrl)[0] }}" class="thumbnail-image" alt="" />
                    </ng-container>

                    <ng-container *ngSwitchCase="'imagesGallery'">
                        <div
                            class="image-content"
                            *ngIf="row[col.key]?.imagesUrl?.length"
                            (click)="onClickImage(row[col.key]?.imagesUrl)"
                        >
                            <img src="{{ (row[col.key]?.imagesUrl)[0] }}" alt="" />
                        </div>
                    </ng-container>

                    <ng-container *ngSwitchCase="'price'"> {{ config.currency }} {{ row[col.key] }} </ng-container>

                    <ng-container *ngSwitchCase="'percent'"> {{ row[col.key] }} {{ "%" }} </ng-container>

                    <ng-container *ngSwitchCase="'datetime'">
                        <ng-container *ngIf="col.key === 'updatedAt' && shouldDisplayUpdatedAt(row)">
                            {{ row[col.key] | date: "dd/MM/yyyy hh:mm:ss a" }}
                        </ng-container>
                        <ng-container *ngIf="col.key !== 'updatedAt'">
                            {{ row[col.key] | date: "dd/MM/yyyy hh:mm:ss a" }}
                        </ng-container>
                    </ng-container>

                    <ng-container *ngSwitchCase="'button'">
                        <ng-container *ngIf="row[col.key] === reviewStatus.Pending; else showText">
                            <button class="save-btn" mat-stroked-button (click)="onStatusButtonClick(row)">
                                {{ row[col.key] }}
                            </button>
                        </ng-container>
                        <ng-template #showText>
                            <span>{{ row[col.key] }}</span>
                        </ng-template>
                    </ng-container>

                    <ng-container *ngSwitchDefault>
                        <div class="reduce-text-content">
                            {{ getValue(row, col.key) }}
                        </div>
                    </ng-container>

                    <ng-container *ngSwitchCase="'toggle'">
                        <mat-slide-toggle
                            [checked]="row[col.key]"
                            (change)="onToggleChange($event, row)"
                        ></mat-slide-toggle>
                    </ng-container>

                    <ng-container *ngSwitchCase="'text'">
                        <ng-container *ngIf="col.key === 'status'">
                            <ng-container *ngIf="row.isStructure">
                                {{ row[col.key] === "Published" ? "Pending" : row[col.key] }}
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="col.key !== 'status'">
                            {{ getValue(row, col.key) }}
                        </ng-container>
                    </ng-container>
                </td>
            </ng-container>

            <tr mat-header-row class="table-header-row" *matHeaderRowDef="getColumnProps('key'); sticky: true"></tr>

            <tr mat-row class="table-data-row" *matRowDef="let row; columns: getColumnProps('key')"></tr>

            <!-- Row shown when there is no matching data. -->
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell empty-td" [attr.colspan]="tableColumns && tableColumns.length" *ngIf="!dataLoading">
                    No data found
                </td>
            </tr>
        </table>
    </div>
    <mat-paginator
        #paginator
        *ngIf="showPaginator"
        [length]="dataTableSize"
        [pageIndex]="config.pageIndex"
        [pageSize]="config.pageSizeOptions![0]"
        [pageSizeOptions]="config.pageSizeOptions!"
        (page)="onPageChange($event)"
        showFirstLastButtons
    >
    </mat-paginator>
</div>
