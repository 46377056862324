import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { CartItem, WishListItem } from "@models/cart.model";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class LocalStorageService {
    private keyName = "cartItems";
    private wishlistKeyName = "wishListItems";
    private cartItems: CartItem[] = [];
    private wishListItems: WishListItem[] = [];
    private cartUpdatedSubject = new BehaviorSubject(0);
    public cartUpdateObservable: Observable<number> = this.cartUpdatedSubject.asObservable();
    public wishlistCountSubject = new BehaviorSubject(0);
    public wishlistCount$: Observable<number> = this.wishlistCountSubject.asObservable();

    constructor(private router: Router) {
        const storedCartItems = localStorage.getItem(this.keyName);
        if (storedCartItems) {
            this.cartItems = JSON.parse(storedCartItems);
        }
        const wishlistItems = localStorage.getItem(this.wishlistKeyName);
        if (wishlistItems) {
            this.wishListItems = JSON.parse(wishlistItems);
        }
    }

    public getCartItems(): CartItem[] {
        return this.cartItems;
    }

    public getWishListItems(): WishListItem[] {
        return this.wishListItems;
    }

    public addItemToCart(item: CartItem): void {
        this.cartItems.push(item);
        this.setCartItems(this.cartItems);
    }

    // set wishlist
    public addItemToWishList(ids: WishListItem): void {
        this.wishListItems.push(ids);
        this.setWishListItems(this.wishListItems);
    }

    //set to local storage
    public setWishListItems(ids: WishListItem[]): void {
        this.wishListItems = ids;
        localStorage.setItem(this.wishlistKeyName, JSON.stringify(this.wishListItems));
        this.wishlistCountSubject.next(0);
    }

    //remove from wishlist
    public removeFromWishlist(id: number): void {
        const index = this.wishListItems.findIndex((item) => item.id === id);
        if (index !== -1) {
            this.wishListItems.splice(index, 1);
        }
        if (!this.wishListItems.length) {
            localStorage.removeItem(this.wishlistKeyName);
            this.wishlistCountSubject.next(0);
            return;
        }
        this.setWishListItems(this.wishListItems);
    }

    public setCartItems(items: CartItem[]): void {
        this.cartItems = items;
        localStorage.setItem(this.keyName, JSON.stringify(this.cartItems));
        this.cartUpdatedSubject.next(0);
    }

    public getCartedQuantity(productId: number, cartUUID?: string): number {
        const cartItem = this.cartItems.find((item) =>
            item.singleBlockInfo ? item.id === productId && item.cartUUID === cartUUID : item.id === productId,
        );
        return cartItem ? cartItem.quantity : 0;
    }

    public updateCartQuantity(productId: number, quantity: number, cartUUId: string) {
        const index = this.cartItems.findIndex((item) =>
            item.singleBlockInfo ? item.id === productId && item.cartUUID === cartUUId : item.id === productId,
        );

        if (index === -1) {
            return;
        }
        this.cartItems[index].quantity = quantity;
        this.setCartItems(this.cartItems);
    }

    public removeCartItem(itemId: number, cartUUID: string): void {
        const indexToRemove = this.cartItems.findIndex((item) =>
            item.singleBlockInfo ? item.id === itemId && item.cartUUID === cartUUID : item.id === itemId,
        );
        if (indexToRemove !== -1) {
            this.cartItems.splice(indexToRemove, 1);
            if (!this.cartItems.length) {
                localStorage.removeItem(this.keyName);
                this.cartUpdatedSubject.next(0);
                return;
            }
            this.setCartItems(this.cartItems);
        }
    }

    public removeAllCartItems(): void {
        this.cartItems = [];
        this.wishListItems = [];
        localStorage.removeItem(this.keyName);
        localStorage.removeItem(this.wishlistKeyName);
        this.cartUpdatedSubject.next(0);
    }
}
