import { Component } from "@angular/core";

@Component({
    selector: "app-term-condition",
    templateUrl: "./term-condition.component.html",
    styleUrls: ["./term-condition.component.scss"],
})
export class TermConditionComponent {
    public email = "contact@udmglobal.com";
}
