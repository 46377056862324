<div class="container" [ngClass]="{ 'in-dialog': isInDialog }">
    <form [formGroup]="addressForm">
        <div class="form">
            <div class="fields">
                <div class="input-field" *ngIf="showNameAndMobile">
                    <label for="id" class="required">Name</label>
                    <input
                        id="name"
                        type="text"
                        formControlName="name"
                        class="location-input"
                        placeholder="Enter recipient's name"
                        maxlength="50"
                    />
                    <div *ngIf="isInvalid('name')">
                        <div *ngIf="isInvalid('name', 'required')" class="text-red">Enter the name!</div>
                        <div *ngIf="isInvalid('name', 'forbiddenName')" class="text-red">
                            Please do not use special characters or numbers!
                        </div>
                    </div>
                </div>
                <div class="input-field" *ngIf="showNameAndMobile">
                    <label for="mobileNumber" class="required">Phone Number</label>
                    <input
                        id="mobileNumber"
                        type="text"
                        class="location-input"
                        formControlName="mobileNumber"
                        placeholder="Enter recipient's number"
                        (input)="onValidNumber($event)"
                        maxlength="10"
                        minlength="10"
                    />
                    <div *ngIf="isInvalid('mobileNumber')">
                        <div *ngIf="isInvalid('mobileNumber', 'required')" class="text-red">
                            Enter the mobile number!
                        </div>
                        <div
                            *ngIf="
                                isInvalid('mobileNumber', 'indianPhoneNumber') && !isInvalid('mobileNumber', 'required')
                            "
                            class="text-red"
                        >
                            Please enter a valid mobile number!
                        </div>
                    </div>
                </div>
                <div class="input-field">
                    <label for="houseNumber" class="required">House No. & Floor</label>
                    <input
                        id="houseNumber"
                        type="text"
                        formControlName="houseNumber"
                        class="location-input"
                        placeholder="Enter house number"
                        required
                    />
                    <div *ngIf="isInvalid('houseNumber')">
                        <div *ngIf="isInvalid('houseNumber', 'required')" class="text-red">
                            Enter the House No. & Floor!
                        </div>
                    </div>
                </div>
                <div class="input-field">
                    <label for="address" class="required">Address</label>
                    <input
                        id="address"
                        type="text"
                        formControlName="address"
                        class="location-input"
                        placeholder="Enter address"
                        required
                    />
                    <div *ngIf="isInvalid('address')">
                        <div *ngIf="isInvalid('address', 'required')" class="text-red">Enter the address!</div>
                    </div>
                </div>
                <div class="input-field">
                    <label for="pincode" class="required">Pincode</label>
                    <input
                        id="pincode"
                        matInput
                        type="text"
                        formControlName="pincode"
                        class="location-input"
                        placeholder="Enter pincode"
                        (input)="onValidNumber($event)"
                        maxlength="6"
                        required
                    />
                    <div *ngIf="isInvalid('pincode')">
                        <div *ngIf="isInvalid('pincode', 'required')" class="text-red">Enter the pincode!</div>
                        <div
                            *ngIf="isInvalid('pincode', 'invalidPincode') && !isInvalid('pincode', 'required')"
                            class="text-red"
                        >
                            Please enter a valid pincode!
                        </div>
                    </div>
                </div>
                <div class="input-field">
                    <label for="city" class="required">City</label>
                    <input
                        id="city"
                        type="text"
                        formControlName="city"
                        class="location-input"
                        placeholder="Enter city"
                        required
                    />
                    <div *ngIf="isInvalid('city')">
                        <div *ngIf="isInvalid('city', 'required')" class="text-red">Enter the city!</div>
                    </div>
                </div>
                <div class="input-field">
                    <label for="state" class="required">State</label>
                    <input
                        id="state"
                        type="text"
                        formControlName="state"
                        class="location-input"
                        placeholder="Enter state"
                        required
                    />
                    <div *ngIf="isInvalid('state')">
                        <div *ngIf="isInvalid('state', 'required')" class="text-red">Enter the state!</div>
                    </div>
                </div>
                <div class="input-field">
                    <label for="addressType" class="required">Address Type</label>
                    <mat-chip-listbox id="addressType" formControlName="addressType">
                        <mat-chip-option> Home </mat-chip-option>
                        <mat-chip-option> Work </mat-chip-option>
                        <mat-chip-option> Other </mat-chip-option>
                    </mat-chip-listbox>
                </div>
            </div>
        </div>
    </form>
</div>
