export enum PaymentStatus {
    Success = "Success",
    Failed = "Failed",
    Pending = "Pending",
    CashOnDelivery = "Cash On Delivery",
}

export enum RefundStatus {
    RefundInitiated = "Refund Initiated",
    RefundProcessed = "Refund Processed",
    RefundSuccessful = "Refund Successful",
    RefundFailed = "Refund Failed",
}

export enum PaymentErrorMessage {
    ChecksumVerificationFailed = "Checksum verification failed",
    RefundAmountMoreThanOriginalAmount = "Refund amount is more than original payment amount",
}

export interface InitiatePaymentInput {
    orderId: string;
    userInfo: {
        custId: string;
        //mobile Mandatory when the merchant wants to give Debit Card EMI as a payment option to its users
        mobile?: string;
        email?: string;
        firstName?: string;
        lastName?: string;
    };
    callbackUrl: string;
    txnAmount: {
        value: string;
        currency: string;
    };
}

export interface InitiatePaymentResponse {
    head: ResponseHead;
    body: ResponseBody;
}

interface ResponseHead {
    responseTimestamp?: string;
    version?: string;
    signature: string;
}

interface ResponseBody {
    resultInfo: ResultInfo;
    txnToken?: string;
    isPromoCodeValid?: boolean;
    authenticated?: boolean;
    amount: string;
    orderId: string;
}

interface ResultInfo {
    resultStatus: string;
    resultCode: string;
    resultMsg: string;
}

export interface PaytmCheckoutConfig {
    flow: string;
    merchant: MerchantConfig;
    style: object;
    jsFile: string;
    mapClientMessage: object;
    labels: object;
    payMode: PayModeConfig;
    data?: {
        orderId: string;
        token: string;
        tokenType: string;
        amount: string;
    };
    handler?: {
        notifyMerchant: (eventType: string, data: string | {}) => void;
        transactionStatus: (data: PaytmCallbackData) => void;
    };
}

interface MerchantConfig {
    mid: string;
    name: string;
    logo: string;
    callbackUrl: string;
    redirect: boolean;
}

interface PayModeConfig {
    labels: object;
    filter: {
        exclude: string[];
    };
    order: string[];
}

export interface PaytmCallbackData {
    bankname: string;
    banktxnid: string;
    checksumhash: string;
    currency: string;
    gatewayname: string;
    mid: string;
    orderid: string;
    paymentmode: string;
    respcode: string;
    respmsg: string;
    status: string;
    txnamount: string;
    txndate: string;
    txnid: string;
}

export enum PaymentTransactionStatus {
    Success = "TXN_SUCCESS",
    Failed = "TXN_FAILURE",
    Pending = "PENDING",
}
