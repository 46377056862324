<div class="container">
    <div class="map-content">
        <div class="search-content">
            <button class="currentlocation-btn" (click)="loadMap()">
                <mat-icon>gps_fixed</mat-icon> Use Current Location</button
            ><br />
            <div>
                <input
                    #input
                    id="pac-input"
                    class="search-place"
                    type="text"
                    placeholder="Search your Address"
                    minlength="5"
                />
            </div>
        </div>
        <div id="map" class="map"></div>
    </div>
</div>
