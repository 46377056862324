<div class="content" *ngIf="productDetail">
    <div class="product-detail-container">
        <div class="product-image-content">
            <app-block-details-component
                [shapeJson]="shapeJson"
                [selectedColor]="selectedColor"
                [resetSelectedOption]="resetSelectedOption"
                (seletedchoice)="getSeletedChoice($event)"
                *ngIf="isCategoryBuyBlock"
            ></app-block-details-component>
            <app-product-image-gallery
                [productId]="productId"
                class="product-image-gal"
                *ngIf="!isCategoryBuyBlock"
            ></app-product-image-gallery>
        </div>
        <div class="product-info">
            <h2 class="product-name">
                {{ productDetail.name }}
            </h2>
            <div class="rating-content">
                <p class="rating" *ngIf="productDetail.averageRating">
                    {{ productDetail.averageRating }}
                    <mat-icon class="star">star</mat-icon>
                </p>
                <span *ngIf="productDetail.averageRating && productDetail.reviews!.length" class="count-rating">
                    {{ "(" + productDetail.reviews!.length + ")" }}
                </span>
            </div>
            <hr />
            <p class="description" [class.line-clamp-3]="!isFullDescription" #expandDetails>
                {{ productDetail.description }}
            </p>

            <a class="showmore" (click)="readMore()" *ngIf="isOverflow(expandDetails)">{{ "Read more" }}</a>
            <a class="showmore" (click)="readLess()" *ngIf="isFullDescription && isDescriptionMoreThanThreeLines">{{
                "Read less"
            }}</a>

            <div class="price-container">
                <div>
                    <span
                        [ngClass]="{
                            'cut-price':
                                productDetail.discountPrice &&
                                productDetail.discountPrice !== 0 &&
                                productDetail.discountPrice !== productDetail.price
                        }"
                        >Actual Price : ₹{{ productDetail.price }}</span
                    ><br />
                    <span
                        class="discount-price"
                        *ngIf="
                            productDetail.discountPrice &&
                            productDetail.discountPrice !== 0 &&
                            productDetail.discountPrice !== productDetail.price
                        "
                        >Discount Price : ₹{{ productDetail.discountPrice }}</span
                    >
                    <p class="text-color">Price inclusive all the taxes</p>
                </div>

                <div class="quantity-counter">
                    <button class="decrement" (click)="onClick(-1)" [disabled]="quantity <= 1">-</button>
                    <input
                        type="number"
                        class="input-quantity"
                        [(ngModel)]="quantity"
                        (input)="onInputChange($event, productDetail)"
                        (blur)="checkQuantity(productDetail)"
                    />
                    <button class="increment" (click)="onClick(1)" [disabled]="quantity >= 99">+</button>
                </div>

                <div class="color-container" *ngIf="isCategoryBuyBlock">
                    <h3>Select color</h3>
                    <div class="color-content">
                        <div *ngFor="let color of colors">
                            <div
                                [class]="color.class"
                                [ngStyle]="{ 'background-color': color.value }"
                                (click)="getSelectedColor(color.value)"
                            ></div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="btn-content">
                <div *ngIf="!isCategoryBuyBlock">
                    <div *ngIf="isProductInCart; else addProduct">
                        <button mat-stroked-button class="add" (click)="goToCart()">Go To Cart</button>
                    </div>
                    <ng-template #addProduct>
                        <button mat-stroked-button class="add" (click)="addToCart()" *ngIf="!isProductInCart">
                            Add to Cart
                        </button>
                    </ng-template>
                </div>
                <div *ngIf="isCategoryBuyBlock">
                    <div>
                        <button mat-stroked-button class="add" (click)="addToCart()">Add to Cart</button>
                    </div>
                </div>
                <button
                    mat-stroked-button
                    class="save-btn"
                    (click)="onClickBuy(productDetail.id, quantity, productDetail)"
                >
                    Buy It Now
                </button>
            </div>

            <div class="offer-container" *ngIf="activeCoupons?.length">
                <div class="offer-heading">
                    <mat-icon>local_offer</mat-icon>&nbsp;<span class="offer-word">OFFER</span>
                </div>
                <div class="offer-type">
                    <div *ngFor="let coupon of activeCoupons">
                        <mat-card class="offer">
                            <mat-card-header class="coupon-header">{{ coupon.description }}</mat-card-header>
                            <mat-card-content>
                                <div class="coupon-content">
                                    <span class="coupon-code">{{ coupon.couponCode }}</span>
                                    <button
                                        mat-raised-button
                                        class="copy-btn"
                                        (click)="copyToClipboard(coupon.couponCode)"
                                    >
                                        COPY
                                    </button>
                                </div>
                                <p class="line">Copy this promo code and paste when you checkout at</p>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </div>
            </div>

            <div class="service">
                <div class="service-type">
                    <mat-icon class="service-icon">local_shipping</mat-icon>
                    <p>Free Delivery</p>
                </div>

                <div class="service-type">
                    <mat-icon class="service-icon">autorenew</mat-icon>
                    <p>Returnable</p>
                </div>

                <div class="service-type">
                    <mat-icon class="service-icon">local_shipping</mat-icon>
                    <p>Funfigz Delivery</p>
                </div>

                <div class="service-type">
                    <mat-icon class="service-icon">verified_user</mat-icon>
                    <p>Secure Transaction</p>
                </div>
            </div>
        </div>
    </div>

    <!--Suggested Product-->

    <div class="section" *ngIf="suggestedProducts && suggestedProducts.length && !isCategoryBuyBlock">
        <h1 class="section-heading">Similar Products</h1>

        <div class="container">
            <div class="image-grid">
                <div class="product-item" *ngFor="let product of suggestedProducts">
                    <div class="product-card">
                        <div
                            class="item-image-container"
                            (mouseenter)="startImageRotation(product.id)"
                            (mouseleave)="stopImageRotation(product.id)"
                            matTooltip="{{ product.name }}"
                            matTooltipPosition="right"
                        >
                            <img
                                *ngIf="product.images; else placeholder"
                                [src]="getCurrentImageUrl(product.id)"
                                (click)="onClickProduct(product.id)"
                                alt="Item Image"
                            />

                            <!-- Template for the placeholder when there's no image -->
                            <ng-template #placeholder>
                                <!-- Placeholder div with a specific height to retain space -->
                                <div class="image-placeholder"></div>
                            </ng-template>
                            <div class="suggested-rating-content">
                                <!-- wishlist icon -->
                                <mat-icon
                                    class="wish-list"
                                    [ngClass]="{ activeWishlist: isInWishlist(product) }"
                                    (click)="addToFavorite(product)"
                                >
                                    favorite
                                </mat-icon>

                                <p class="suggested-rating" *ngIf="product.averageRating">
                                    {{ product.averageRating }}
                                    <mat-icon class="suggested-star">star</mat-icon>
                                    <span
                                        *ngIf="product.averageRating && product.reviews?.length"
                                        class="suggested-count-rating"
                                    >
                                        {{ "(" + +product.reviews!.length + ")" }}
                                    </span>
                                </p>
                            </div>
                        </div>

                        <div class="product-card-content">
                            <p class="suggested-product-name">{{ product.name }}</p>
                            <div class="product-price">
                                <p
                                    [ngClass]="{
                                        'cut-price':
                                            product.discountPrice &&
                                            product.discountPrice !== 0 &&
                                            product.discountPrice !== product.price
                                    }"
                                >
                                    {{ "Rs. " + product.price }}
                                </p>
                                <p
                                    *ngIf="
                                        product.discountPrice &&
                                        product.discountPrice !== 0 &&
                                        product.discountPrice !== product.price
                                    "
                                >
                                    {{ "Rs. " + product.discountPrice }}
                                </p>
                            </div>
                            <div class="add-to-container">
                                <div *ngIf="getProductQtyInCart(product.id); else addProduct" class="quantity-add">
                                    <button
                                        class="decrement"
                                        [disabled]="getProductQtyInCart(product.id) <= 0"
                                        (click)="onclick(-1, product.id)"
                                    >
                                        -
                                    </button>
                                    <input
                                        class="quantity"
                                        type="number"
                                        [value]="getProductQtyInCart(product.id)"
                                        (input)="onInputChange($event, product)"
                                        (blur)="onBlur($event, product.id)"
                                    />
                                    <button
                                        class="increment"
                                        [disabled]="getProductQtyInCart(product.id) >= 99"
                                        (click)="onclick(1, product.id)"
                                    >
                                        +
                                    </button>
                                </div>
                                <ng-template #addProduct>
                                    <button
                                        mat-button
                                        class="suggested-add"
                                        (click)="addToCartSuggested(product.id, 1)"
                                    >
                                        ADD
                                    </button>
                                </ng-template>
                            </div>
                            <!-- Section 2 End -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- ratings & review  -->
    <ng-container *ngIf="getFilteredReviews()?.length">
        <mat-card class="review-card">
            <mat-card-content>
                <div class="default-count">
                    <h2>Ratings & Reviews</h2>
                    <p class="rating" *ngIf="productDetail.averageRating">
                        {{ productDetail.averageRating }}
                        <mat-icon class="star">star</mat-icon>
                    </p>
                </div>
                <div class="count-container">
                    {{ getTotalRatingsAndReviews().ratingCount }} Ratings &
                    {{ getTotalRatingsAndReviews().validReviewsCount }} Reviews
                </div>
                <div *ngFor="let review of getFilteredReviews()?.slice(0, visibleReviews)">
                    <div class="review-container" *ngIf="review.review">
                        <p class="rating" *ngIf="productDetail.averageRating">
                            {{ review.rating }}
                            <mat-icon class="star">star</mat-icon>
                        </p>
                        <p>{{ review.review }}</p>
                        <div class="review-info">
                            <p class="name-and-date">{{ review.name }}</p>
                            <span class="date">{{ review.createdAt | date: "dd MMM yyyy" }}</span>
                        </div>
                        <div *ngIf="review.images?.imagesUrl && review.images.imagesUrl.length">
                            <div class="review-images-container">
                                <img
                                    *ngFor="let imageUrl of review.images.imagesUrl"
                                    [src]="imageUrl"
                                    class="image"
                                    (click)="openReviewImages(review)"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="view-more" *ngIf="canShowMoreReviews()">
                    <button class="view-more-btn" mat-raised-button (click)="showMoreReviews()">View More</button>
                </div>
            </mat-card-content>
        </mat-card>
    </ng-container>
    <app-right-navbar [isOpen]="isNavbarOpen" (closeEvent)="onCloseNavbar()"></app-right-navbar>
</div>
