import { Injectable } from "@angular/core";
import { appConfig } from "../../../environments/app.config";
import { environment } from "../../../environments/environment";
import { S3UploadHelperService } from "./s3UploadHelper.service";
import { MultipartErrorMessage } from "@models/structure.model";
@Injectable({
    providedIn: "root",
})
export class S3UploadService {
    constructor(private s3UploadHelperService: S3UploadHelperService) {}

    public async multipartOperation(files: File[], categoryName: string, fileType: string): Promise<string[]> {
        const partSize = 5 * 1024 * 1024;
        const removeDuplicateS3ImagesSet = new Set<string>();
        try {
            await Promise.all(
                files.map(async (file) => {
                    const filePathWithName = `${environment.name}/${categoryName}/${fileType}/${file.name}`;
                    const fileUrl = `${appConfig.awsUrlBasePath}/${filePathWithName}`;
                    if (!removeDuplicateS3ImagesSet.has(fileUrl)) {
                        const parts = Math.ceil(file.size / partSize);
                        const partNumbers = Array.from({ length: parts }, (_, i) => i + 1);
                        const url = await this.uploadFile(filePathWithName, partNumbers, file);
                        removeDuplicateS3ImagesSet.add(url);
                    }
                }),
            );
        } catch (error) {
            throw error;
        }

        return Array.from(removeDuplicateS3ImagesSet);
    }

    public async uploadFile(filePathWithName: string, partNumbers: number[], file: any): Promise<string> {
        const partSize = 5 * 1024 * 1024; // from config
        let fileUrl = "";

        try {
            const updateData = await this.s3UploadHelperService.startUpload(filePathWithName).toPromise();
            if (updateData) {
                const urls = (await this.s3UploadHelperService
                    .getPresignedUrls(filePathWithName, updateData.uploadId, partNumbers)
                    .toPromise()) as any;
                const uploadPromises = urls.map((url: string, index: number) => {
                    const start = index * partSize;
                    const end = Math.min(start + partSize, file.size || file.length);
                    const blob = new Blob([file.slice(start, end)], {
                        type: "application/json",
                    });
                    return fetch(url, {
                        method: "PUT",
                        body: blob,
                    }).then((response) => {
                        if (!response.ok) {
                            throw new Error(MultipartErrorMessage.FailedToUploadPart);
                        }
                        const eTag = response.headers.get("ETag");
                        if (!eTag) {
                            throw new Error(MultipartErrorMessage.ETagNotFound);
                        }
                        return { PartNumber: index + 1, ETag: eTag.replace(/"/g, "") };
                    });
                });

                const partsList = await Promise.all(uploadPromises);
                await this.s3UploadHelperService
                    .completeUpload(filePathWithName, updateData.uploadId, partsList)
                    .toPromise();

                fileUrl = `${appConfig.awsUrlBasePath}/${filePathWithName}`;
            }
        } catch (error) {
            console.error(error);
            throw new Error(MultipartErrorMessage.FailedToUpload);
        }
        return fileUrl;
    }
}
