<div class="container">
    <app-banner [images]="banners" *ngIf="banners.length && isShowBanner"></app-banner>

    <div class="search-content">
        <div class="search-product">
            <input
                placeholder=""
                class="search-input"
                id="input"
                autocomplete="off"
                (keyup.enter)="onSearchClick(searchInput.value)"
                (input)="onSearchInputChange($event)"
                #searchInput
            /><span><mat-icon class="search-icon" (click)="onSearchClick(searchInput.value)">search</mat-icon></span>
        </div>
    </div>

    <!-- Search Results -->
    <div *ngIf="searchInitiated">
        <h2>Your Result :</h2>
        <div *ngIf="searchResults.length > 0; else noResults" class="search-results-container">
            <product-viewlist [productList]="searchResults" [showButton]="false"></product-viewlist>
            <div class="btn-content">
                <button
                    *ngIf="this.totalSearchResults - this.searchResults.length > 0"
                    class="customise-btn"
                    mat-raised-button
                    (click)="onSearchViewMoreClick()"
                >
                    View More
                </button>
            </div>
        </div>

        <ng-template #noResults>
            <div class="no-results-message">
                <h2>No products found for your search.</h2>
            </div>
        </ng-template>
    </div>

    <!-- Product List -->
    <mat-spinner *ngIf="isLoading" class="loading-spinner" [diameter]="50"></mat-spinner>
    <div *ngIf="productList.length > 0; else noProducts">
        <h2>{{ selectedCategory }} :</h2>
        <product-viewlist
            [productList]="productList"
            [showButton]="this.totalProducts - this.productList.length > 0"
            (viewMoreClick)="onViewMoreClick()"
        >
        </product-viewlist>
    </div>

    <ng-template #noProducts>
        <div class="no-results-message" *ngIf="!isLoading">
            <div class="no-results-container">
                <h1>No products found.</h1>
            </div>
        </div>
    </ng-template>

    <div class="filter">
        <span
            class="category"
            [ngClass]="{ selected: selectedCategory === category }"
            (click)="onClickedCategory(category)"
            *ngFor="let category of productCategories; let i = index"
        >
            {{ category }}
        </span>
    </div>
</div>
