<div class="container">
    <canvas #canvasRef class="canvas"></canvas>

    <div class="choice-selector">
        <mat-radio-group aria-labelledby="radio-group-label" class="radio-group" [(ngModel)]="selectedChoice">
            @for (choice of choices; track choice) {
            <mat-radio-button class="radio-button" [value]="choice">{{ choice }}</mat-radio-button>
            }
        </mat-radio-group>
    </div>
</div>
