import { LoginPageService } from "../login-page/login-page.service";
import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { FormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { UserService } from "../services/user/user.service";
import { AuthService } from "../services/auth/auth.service";
import { CartItem } from "@models/cart.model";
import { RouteTrackingService } from "../services/route-tracking/route-tracking.service";

type Timeout = ReturnType<typeof setTimeout>;
@Component({
    selector: "app-login-page",
    templateUrl: "./login-page.component.html",
    styleUrls: ["./login-page.component.scss"],
})
export class LoginPageComponent implements OnInit {
    public mobileNumber: string = "";
    public otp!: number;
    public showOTPField = false;
    public showSubmitButton = false;
    public showResendLink = false;
    public showTimer = false;
    public minutes = 5;
    public seconds = 0;
    public isOTPButtonDisabled = false;
    public loginForm!: FormGroup;
    public displayTimer: string = "";
    public otpSentMessage: string = "";
    public timerInterval: Timeout | undefined;
    public otpSent = false;
    public oldCartItems: CartItem[] = [];

    public isUserlogin = false;
    @ViewChild("otpInput") otpInput!: ElementRef;

    constructor(
        private formBuilder: FormBuilder,
        private loginPageService: LoginPageService,
        private router: Router,
        private cdk: ChangeDetectorRef,
        private userService: UserService,
        private authService: AuthService,
        private routeTrackingService: RouteTrackingService,
    ) {}

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            mobileNumber: ["", [Validators.required, Validators.pattern("^[0-9]{10}$")]],
            otp: [""],
        });

        this.loginForm.get("mobileNumber")?.valueChanges.subscribe((value) => {
            this.loginForm.get("mobileNumber")?.setValue(this.filterNumeric(value), { emitEvent: false });
        });
        this.loginForm.get("otp")?.valueChanges.subscribe((value) => {
            this.loginForm.get("otp")?.setValue(this.filterNumeric(value), { emitEvent: false });
        });

        this.loginForm.get("mobileNumber")?.valueChanges.subscribe(() => {
            this.loginForm.get("otp")?.clearValidators();
            this.loginForm.get("otp")?.setValue("");
            this.loginForm.get("otp")?.updateValueAndValidity();

            this.showOTPField = false;
            this.showSubmitButton = false;
            this.showResendLink = false;
            this.showTimer = false;
        });
    }

    ngOnDestroy(): void {
        clearInterval(this.timerInterval);
    }

    private filterNumeric(value: string): string {
        return value.replace(/\D/g, "");
    }

    public sendOTP(): void {
        this.isOTPButtonDisabled = true;
        if (this.otpSent) {
            return;
        }
        const requestBody = {
            mobileNumber: this.loginForm.value.mobileNumber,
        };
        this.loginPageService.sendOTP(requestBody).subscribe({
            next: (res) => {
                this.setupOTPField();
                this.otpSentMessage = "OTP sent successfully";
                this.showSubmitButton = true;
                this.otpSent = true;
                setTimeout(() => {
                    this.otpSentMessage = "";
                    this.loginForm.get("otp")?.markAsUntouched();
                    this.otpSent = false;
                    setTimeout(() => {
                        this.isOTPButtonDisabled = false;
                    }, 3000);
                }, 3000);
                this.focusOtpInput();
            },
            error: (err) => {
                this.isOTPButtonDisabled = false;
            },
        });
    }

    private focusOtpInput(): void {
        setTimeout(() => {
            this.otpInput.nativeElement.focus();
        }, 0);
    }

    private setupOTPField(): void {
        this.loginForm.get("otp")?.addValidators([Validators.required, Validators.pattern("^[0-9]{6}$")]);
        this.loginForm.get("otp")?.updateValueAndValidity();
        this.cdk.detectChanges();
        this.showOTPField = true;
        this.showSubmitButton = true;
        this.showResendLink = false;
        this.showTimer = true;
        this.startTimer();
        this.isOTPButtonDisabled = true;
    }

    public submitOTP(): void {
        const keysToRemove = ["user", "address"];
        keysToRemove.forEach((key) => {
            localStorage.removeItem(key);
        });
        const otpValue = this.loginForm.value.otp;
        const mobileNumberValue = this.loginForm.value.mobileNumber;

        this.loginPageService.verifyOtp({ otp: otpValue, mobileNumber: mobileNumberValue }).subscribe({
            next: (response) => {
                const token = response;
                localStorage.setItem("token", token);
                this.authService.isLoggedIn.next(true);
                this.showTimer = false;
                this.loginForm.get("otp")?.setErrors(null);

                this.userService.isUserDetailsExist().subscribe((response) => {
                    if (response) {
                        this.routeTrackingService.redirectAfterLogin();
                    } else {
                        this.router.navigate(["/userDetails"]);
                    }
                });
            },
            error: (error) => {
                this.loginForm.get("otp")?.setErrors({ invalidOTP: true });
            },
        });
    }

    public resendOTP(): void {
        const requestBody = {
            mobileNumber: this.loginForm.value.mobileNumber,
        };
        this.loginPageService.resendOtp(requestBody).subscribe(
            () => {
                this.showTimer = true;
                this.minutes = 5;
                this.startTimer();
                this.showResendLink = false;
                this.otpSentMessage = "OTP sent successfully";
                setTimeout(() => {
                    this.otpSentMessage = "";
                }, 3000);
            },
            (error) => {},
        );
    }

    public startTimer(): void {
        if (this.timerInterval) {
            clearInterval(this.timerInterval);
        }

        this.minutes = 5;
        this.seconds = 0;

        this.timerInterval = setInterval(() => {
            if (this.minutes === 0 && this.seconds === 0) {
                clearInterval(this.timerInterval);
                this.showTimer = false;
                this.showResendLink = true;
                return;
            }
            if (this.seconds === 0) {
                this.minutes--;
                this.seconds = 59;
            } else {
                this.seconds--;
            }
        }, 1000);
    }

    get timerDuration(): string {
        const min = this.minutes > 9 ? `${this.minutes}` : `0${this.minutes}`;
        const sec = this.seconds > 9 ? `${this.seconds}` : `0${this.seconds}`;
        return `OTP expires in ${min}:${sec}`;
    }
}
