import { Location } from "@angular/common";
import { Injectable } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { Subject } from "rxjs";
import { filter } from "rxjs/operators";

@Injectable({
    providedIn: "root",
})
export class RouteTrackingService {
    private _previousUrl: string = "";
    private _currentUrl: string = "";
    private _intendedUrl: string = "";

    public urlChanged: Subject<{ previousUrl: string; currentUrl: string; intendedUrl: string }> = new Subject();

    constructor(private router: Router) {
        this._currentUrl = this.router.url;

        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
            // Update route tracking
            this._previousUrl = this._currentUrl;
            this._currentUrl = event.url;

            // Clear intended destination when navigating away from login
            if (event.url !== "/login") {
                this._intendedUrl = "";
            }

            this.urlChanged.next({
                previousUrl: this._previousUrl,
                currentUrl: this._currentUrl,
                intendedUrl: this._intendedUrl,
            });
        });
    }

    // Method to set intended destination for protected routes
    setIntendedUrl(url: string) {
        this._intendedUrl = url;
    }

    // Route history methods
    getPreviousUrl(): string {
        return this._previousUrl;
    }

    getCurrentUrl(): string {
        return this._currentUrl;
    }

    // Redirect after login method
    redirectAfterLogin() {
        if (this._intendedUrl) {
            // Navigate to the intended destination for protected routes
            this.router.navigate([this._intendedUrl]);
            // Clear the intended destination
            this._intendedUrl = "";
        } else if (this._previousUrl) {
            // If no intended destination, use the previous URL
            this.router.navigate([this._previousUrl]);
        } else {
            // Fallback to home if no previous URLs
            this.router.navigate(["/home"]);
        }
    }
}
