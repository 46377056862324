import { ChangeDetectorRef, Component, DoCheck, ElementRef, HostListener, OnInit, ViewChild } from "@angular/core";
import { AuthService } from "src/app/services/auth/auth.service";
import { Observable, Subscription, merge, retry } from "rxjs";
import { UserService } from "src/app/services/user/user.service";
import { NullableRegisteredUserDetails } from "@models/user.model";
import { NavigationEnd, Router } from "@angular/router";
import { HeaderModel, LeftHeaderModel } from "@models/header.model";
import { LocalStorageService } from "../../services/local-storage/local-storage.service";
import { ProductService } from "../../services/product/product.service";
import { appConfig } from "../../../environments/app.config";
@Component({
    selector: "app-header",
    templateUrl: "./header.component.html",
    styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
    public cartCount: number = 0;
    public wishlistCount!: number;
    public rightNavLinks: HeaderModel[] = [];
    public userDetails!: NullableRegisteredUserDetails;
    public isValid: boolean = false;
    public navLinks: LeftHeaderModel[] = [];
    public isMenuOpen = false;
    public searchInput!: string;
    private debounceTimer: any;
    public isHomePage: boolean = false;
    public showProfile = false;
    public isUserLogin!: boolean;
    @ViewChild("searchInput") searchInputElement!: ElementRef;
    public productLogo: string = appConfig.productLogo;

    public accountLink = {
        label: "",
        route: "",
        icon: "account_circle",
    };

    public menuItems = [
        { label: "Profile", action: () => this.navigateToProfile() },
        { label: "My Structures", action: () => this.navigateToMyStructures() },
        { label: "Notifications", action: () => this.navigateToNotifications() },
        { label: "Rewards", action: () => this.navigateToRewards() },
        { label: "Logout", action: () => this.onUserLogout() },
    ];

    constructor(
        private elementRef: ElementRef,
        private authService: AuthService,
        private userService: UserService,
        private router: Router,
        private localStorageService: LocalStorageService,
        private cdr: ChangeDetectorRef,
        private productService: ProductService,
    ) {}

    @ViewChild("navbarLinks") navbarLinks!: ElementRef;
    @HostListener("window:resize", ["$event"])
    @HostListener("document:click", ["$event"])
    onEvent(event: MouseEvent): void {
        if (event.type === "resize" && event.target && (event.target as Window).innerWidth > 768) {
            this.isMenuOpen = false;
        } else if (event.type === "click" && !this.elementRef.nativeElement.contains(event.target)) {
            this.isMenuOpen = false;
        }
    }
    ngOnInit() {
        this.productService.reset.subscribe((reset) => {
            if (reset) {
                this.searchInput = "";
                if (this.searchInputElement) {
                    (this.searchInputElement.nativeElement as HTMLInputElement).value = "";
                }
            }
        });

        this.isHomePage = this.router.url.includes("home");
        this.cdr.detectChanges();
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.setNavLinks();
                this.checkHeader();
                this.isHomePage = this.router.url.includes("home");
                if (this.authService.isUserLoggedIn) {
                    this.getUserDetails();
                }
            }
        });

        const combinedObservable = merge(
            this.localStorageService.cartUpdateObservable,
            this.localStorageService.wishlistCount$,
        );
        combinedObservable.subscribe(() => {
            this.cartCount = this.getCartItemsLength();
            this.wishlistCount = this.getWishlistCount();
            this.setNavLinks();
            this.leftNavLinks();
            this.checkHeader();
        });
        this.userService.dataSubject.subscribe((response) => {
            if (this.authService.isUserLoggedIn) {
                this.getUserDetails();
            }
        });
    }

    ngAfterViewInit() {
        const input = document.getElementById("searchInput") as HTMLInputElement;
        const placeholderText: string = "Search your product...";
        if (input) {
            this.productService.animatePlaceholder(input, placeholderText);
        }
    }

    onSearchClick(inputValue: string): void {
        this.searchInput = inputValue;
        this.productService.setProductName(this.searchInput);
    }

    onSearchInputChange(event: Event): void {
        const input = event.target as HTMLInputElement;
        this.searchInput = input.value;
        clearTimeout(this.debounceTimer);

        // If search input is not empty, initiate search with debounce
        if (this.searchInput.trim() !== "") {
            this.debounceTimer = setTimeout(() => {
                this.productService.setProductName(this.searchInput);
            }, 3000);
        } else {
            // If search input is empty, clear search results and fetch default products
            this.debounceTimer = setTimeout(() => {
                this.productService.setProductName(this.searchInput);
            }, 300);
        }
    }

    public leftNavLinks(): void {
        this.navLinks = [
            { label: "HOME", route: "/home" },
            // { label: "Catalog", route: "/catalog" },
            { label: "DESIGN", route: "/designer" },
            { label: "CONTACT", route: "/contact" },
        ];
    }

    public setNavLinks(): void {
        this.rightNavLinks = [
            { label: "WISHLIST", route: "/wishlist", icon: "favorite", count: this.wishlistCount },
            {
                label: "TRACK ORDER",
                route: "/trackOrder",
                icon: "directions_car",
            },
            {
                label: "CART",
                route: "/cart",
                icon: "shopping_cart",
                count: this.cartCount,
            },
            { label: "LOGIN", route: "/login", icon: "person" },
        ];
    }

    private checkHeader(): void {
        if (this.authService.isUserLoggedIn) {
            // User is logged in
            this.updateNavLoggedIn();
        } else {
            // User is not logged in
            this.updateNavLoggedOut();
        }
    }

    private updateNavLoggedIn(): void {
        this.isUserLogin = true;
        this.rightNavLinks = this.rightNavLinks.filter((link) => link.label !== "LOGIN");
    }

    private updateNavLoggedOut(): void {
        this.isUserLogin = false;
        this.rightNavLinks = this.rightNavLinks.filter(
            (link) => link.icon !== "account_circle" && link.label !== "TRACK ORDER" && link.label !== "WISHLIST",
        );
    }

    public getCartItemsLength(): number {
        const storedData = localStorage.getItem("cartItems");
        if (storedData) {
            const parsedData = JSON.parse(storedData);
            return parsedData.length;
        }
        return 0;
    }

    public getWishlistCount(): number {
        const wishCount = localStorage.getItem("wishListItems");
        if (wishCount) {
            const parsedData = JSON.parse(wishCount);
            return parsedData.length;
        }
        return 0;
    }

    public toggleMenu(): void {
        this.isMenuOpen = !this.isMenuOpen;
    }

    private getUserDetails(): void {
        this.userService.getUserDetails().subscribe((response) => {
            this.userDetails = response;
            if (this.userDetails?.user?.profileImage) {
                this.showProfile = true;
            } else {
                this.showProfile = false;
            }
        });
    }

    public navigateToProfile(): void {
        this.router.navigate(["/profile"]);
    }

    public onUserLogout(): void {
        this.cartCount = 0;
        this.setNavLinks();
        this.authService.logout();
        this.userDetails = null;
        const channel = new BroadcastChannel("logOut");
        channel.postMessage("logoutMessage");
    }

    public navigateToMyStructures(): void {
        this.router.navigate(["/myStructures"]);
    }

    public navigateToNotifications(): void {
        this.router.navigate(["/notifications"]);
    }

    public navigateToRewards(): void {
        this.router.navigate(["/rewards"]);
    }
}
