import { ValidatorFn, AbstractControl, ValidationErrors } from "@angular/forms";

/** A name validator that disallows numbers, special characters, and leading/trailing spaces */
export function nameValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const numbersRegex = /\d/; // Regular expression to detect numbers
        const specialCharacterRegex = /[!@#$%^&*(),.?":{}|<>]/;

        const value = control.value || ""; // In case control.value is null or undefined
        const containsNumbers = numbersRegex.test(value);
        const containsSpecialCharacters = specialCharacterRegex.test(value);
        const hasLeadingOrTrailingSpaces = value.trim() !== value; // Check for leading/trailing spaces

        return containsNumbers || containsSpecialCharacters || hasLeadingOrTrailingSpaces
            ? { forbiddenName: { value: control.value } }
            : null;
    };
}
