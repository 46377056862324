import { ItemStatusHistoryModel } from "./itemStatusHistory.model";
import { Exchange } from "./exchange.model";
import { ProductDetails } from "./product.model";
import { Refund } from "./refund.model";

export interface OrderItem {
    id?: number;
    orderId: number;
    productId: number;
    refundId: number;
    exchangedId: number;
    quantity: number;
    price: number;
    discountPrice: number;
    itemStatus: string;
    singleBlockInfoDirectBuy?: object;
    returnReason?: string;
    exchangeReason?: string;
    createdAt?: Date;
    updatedAt?: Date;
    itemStatusHistory?: ItemStatusHistoryModel[];
    product?: ProductDetails;
    refund?: Refund;
    exchange?: Exchange;
    node?: string;
    hole?: string;
    plane?: string;
    color?: string;
    colorValue?: string;
}

export const Colors = [
    { class: "dark-green-color", value: "#117A65", name: "Dark Green" },
    { class: "green-color", value: "#21BA45", name: "Bright Green" },
    { class: "skyBlue-color", value: "#5DADE2", name: "Sky Blue" },
    { class: "violet-color", value: "#3633BF", name: "Vivid Violet" },
    { class: "orange-color", value: "#CF871B", name: "Burnt Orange" },
    { class: "teal-color", value: "#00B5AD", name: "Aqua Teal" },
    { class: "lightblue-color", value: "#2185D0", name: "Light Blue" },
    { class: "darkblue-color", value: "#3C79B7", name: "Steel Blue" },
    { class: "white-color", value: "#FFFFFF", name: "White" },
    { class: "red-color", value: "#FF0000", name: "Red" },
    { class: "yellow-color", value: "#FF0000", name: "Yellow" },
    { class: "magenta-color", value: "#FF00FF", name: "Magenta" },
    { class: "gray-color", value: "#808080", name: "Gray" },
    { class: "dim-color", value: "#acacac", name: "Dim" },
    { class: "darkGrey-color", value: "#6b6b6b", name: "Dark Grey" },
];
