import { ChangeDetectorRef, Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { GenericDialogComponent } from "../../shared/generic-dialog/generic-dialog.component";
import { CartItemService } from "src/app/services/cart-item/cart-item.service";
import { Router } from "@angular/router";
import { CartDetails, ProductCategories } from "@models/product.model";
import { MatSnackBar } from "@angular/material/snack-bar";
import { CartItem } from "@models/cart.model";
import { LocalStorageService } from "../../services/local-storage/local-storage.service";
import { finalize, forkJoin } from "rxjs";
import { DialogInvokingComponents } from "@models/generic-dialog.model";
import { appConfig } from "../../../environments/app.config";

@Component({
    selector: "app-cart-page",
    templateUrl: "./cart-page.component.html",
    styleUrls: ["./cart-page.component.scss"],
})
export class CartPageComponent {
    public isLoggedInUser = false;
    cartItems: CartItem[] = [];
    products: CartDetails[] = [];
    cartId!: number;
    totalMRP!: number;
    totalDiscount!: number;
    totalAmount!: number;
    public isCheckoutEnabled: boolean = false;
    public minOrderValue = appConfig.minOrderValue;
    public loading: boolean = true;
    public categories = ProductCategories;

    constructor(
        private dialog: MatDialog,
        private cartItemService: CartItemService,
        private router: Router,
        private snackBar: MatSnackBar,
        private localStorageService: LocalStorageService,
        private cartService: CartItemService,
        private cdr: ChangeDetectorRef,
    ) {}

    ngOnInit(): void {
        this.loadCartItems();
    }

    private loadCartItems(): void {
        this.loading = true;
        this.cartItemService.isProcessCart$.subscribe((data) => {
            if (data) {
                this.cartItemService
                    .getCartItems()
                    .pipe(
                        finalize(() => {
                            this.loading = false;
                        }),
                    )
                    .subscribe({
                        next: (items) => {
                            this.cartItems = items;

                            this.products = this.cartItems.map((item) => ({
                                product: item.product!,
                                singleBlockInfo: item.singleBlockInfo!,
                                cartUUID: item.cartUUID,
                            }));

                            this.calculateTotalValues();
                            const inActiveIds = this.products
                                .filter((product) => !product.product.isActive)
                                .map((item) => ({
                                    id: item.product.id,
                                    cartUUID: item.cartUUID,
                                }));

                            if (inActiveIds.length) {
                                this.openDialogUnavailable(inActiveIds);
                            }
                        },
                        error: (error) => {
                            throw error;
                        },
                    });
            }
        });
    }

    private openDialogUnavailable(inActiveIds: { id: number; cartUUID?: string }[]): void {
        const dialogRef = this.dialog.open(GenericDialogComponent, {
            data: {
                componentName: DialogInvokingComponents.RemoveFromCart,
                title: "Remove from Cart",
                content: "Products will be removed from the cart due to being unavailable!",
                firstBtn: "Remove",
            },
            autoFocus: false,
            restoreFocus: false,
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                const removeObservables = inActiveIds.map((item) => this.cartItemService.removeCartItem(item));

                // Use forkJoin to wait for all observables to complete
                forkJoin(removeObservables).subscribe(() => {
                    this.loadCartItems();
                });
            }
        });
    }

    private calculateTotalValues(): void {
        this.cartItemService.calculateTotalMRP(this.cartItems);
        this.cdr.detectChanges();
        this.totalMRP = this.cartItemService.totalMRP;
        this.totalDiscount = this.cartItemService.totalDiscount;
        this.totalAmount = this.cartItemService.totalAmount;
        this.isCheckoutEnabled = this.totalAmount >= appConfig.minOrderValue;
    }

    public onDeleteCartItem(item: CartDetails): void {
        this.openDialog(item);
    }

    private openDialog(item: CartDetails): void {
        const dialogRef = this.dialog.open(GenericDialogComponent, {
            data: {
                componentName: DialogInvokingComponents.RemoveFromCart,
                title: "Remove from Cart",
                image: item.product.images?.imagesUrl[0],
                content: "Are you sure you want to remove this item from cart?",
                firstBtn: "Remove",
            },
            autoFocus: false,
            restoreFocus: false,
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) this.removeProductFromCart(item.product.id, item.cartUUID!);
        });
    }

    private openSnackBar(message: string, action: string): void {
        this.snackBar.open(message, action, {
            horizontalPosition: "center",
            verticalPosition: "bottom",
            duration: 2000,
        });
    }

    public onCheckout(): void {
        this.router.navigate(["checkout"]);
    }

    public backToHome(): void {
        this.router.navigate(["home"]);
    }

    public getProductQtyInCart(productId: number, cartUUID: string): number {
        return this.localStorageService.getCartedQuantity(productId, cartUUID);
    }

    public onClick(value: number, productId: number, cartUUID: string): void {
        const quantityInCart = this.getProductQtyInCart(productId, cartUUID);
        const newQuantity = quantityInCart + value;
        this.updateCartQuantity(productId, newQuantity, cartUUID);
    }

    private updateCartQuantity(productId: number, quantity: number, cartUUID: string): void {
        if (quantity < 1) {
            this.removeProductFromCart(productId, cartUUID);
        } else if (quantity <= 99) {
            this.updateProductQuantityInCart(productId, quantity, cartUUID);
        }
    }

    private removeProductFromCart(id: number, cartUUID: string): void {
        const item = { id, cartUUID };
        this.cartService.removeCartItem(item).subscribe(() => {
            this.loadCartItems();
        });
    }

    private updateProductQuantityInCart(productId: number, quantity: number, cartUUID: string): void {
        this.cartService.updateCartQuantity(productId, quantity, cartUUID).subscribe(() => {
            const item = this.cartItems.find((item) => item.product?.id === productId && item.cartUUID === cartUUID);
            if (item) {
                item.quantity = quantity;
                this.calculateTotalValues();
            }
        });
    }

    public onInputChange(event: Event, productId: number, cartUUID: string): void {
        let inputValue = (event.target as HTMLInputElement).value.trim();
        let quantity = parseInt(inputValue, 10);

        if (isNaN(quantity)) {
            return;
        } else if (quantity < 1) {
            (event.target as HTMLInputElement).value = "1";
            quantity = parseInt((event.target as HTMLInputElement).value, 10);
        } else if (inputValue.length > 2) {
            inputValue = inputValue.slice(0, 2);
            (event.target as HTMLInputElement).value = inputValue;
            return;
        }

        this.updateCartQuantity(productId, quantity, cartUUID);
    }

    public onBlur(event: Event, productId: number, cartUUID: string): void {
        const inputValue = (event.target as HTMLInputElement).value.trim();
        if (!inputValue) {
            (event.target as HTMLInputElement).value = "1";
            const quantity = parseInt((event.target as HTMLInputElement).value, 10);
            this.updateCartQuantity(productId, quantity, cartUUID);
        }
    }

    public onClickProduct(productId: number): void {
        this.router.navigate([`products/${productId}`]);
    }

    public getFacesByType(data: CartDetails, type: string): string[] {
        return this.cartItemService.getFacesByType(data, type);
    }

    public getShapeColorName(data: CartDetails): string {
        return this.cartItemService.getColorName(data);
    }

    public getShapeColorValue(data: CartDetails): string {
        return this.cartItemService.getColorValue(data);
    }
}
