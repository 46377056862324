export interface OtpAttributes {
    id?: number;
    mobileNumber: string;
    otp: number;
    otpExpiringTime: Date;
}

export interface BaseUser {
    name: string;
    email: string;
    userMobileNumber?: string;
    profileImage?: string;
    age?: number;
}
export interface UserDetailsAttributes extends BaseUser {
    id: number;
}

export interface UserDetailsCreationAttributes extends BaseUser {
    id?: number;
}

export interface UserInstance extends UserDetailsAttributes {
    createdAt?: Date;
    updatedAt?: Date;
}

export type NullableUser = UserInstance | null;

export interface BaseAddress {
    userMobileNumber?: string;
    address: string;
    addressType?: string;
    city: string;
    houseNumber?: string;
    state: string;
    pincode: number;
    gpsCoordinate?: {
        latitude: number;
        longitude: number;
    };
    name?: string;
    mobileNumber?: string;
}

export interface AddressDetailsAttributes extends BaseAddress {
    id: number;
}

export interface AddressDetailsCreationAttributes extends BaseAddress {
    id?: number;
}

export interface AddressInstance extends AddressDetailsAttributes {
    createdAt?: Date;
    updatedAt?: Date;
}

export type NullableAddress = AddressInstance | null;
export type NullableAddresses = AddressInstance[] | null;

export interface RegisteredUserDetails {
    id: number;
    mobileNumber: string;
    otp: number;
    otpExpiringTime: Date;
    createdAt: Date;
    updatedAt: Date;
    user: UserInstance;
    addresses: NullableAddresses;
}

export type NullableRegisteredUserDetails = RegisteredUserDetails | null;

export enum UserDetailStatus {
    Add = "Added Successfully",
    Update = "Updated Successfully",
    Delete = "Deleted Successfully",
    DeleteAddressFailed = "Failed to delete address",
    UpdateAddressFailed = "Failed to update address",
    AddAddressFailed = "Failed to add address",
    UpdateEmailFailed = "Failed to update email",
    AddUserDetailsFailed = "Failed to add user details",
    GetDefaultAddressFailed = "Failed to get default address",
    GetAllAddressesFailed = "Failed to get addresses",
    GetUserDetailsFailed = "Failed to get user details",
    UserDetailsCheckFailed = "User details check failed",
    ChangeProfileImage = "Change Profile Photo",
    AddProfileImage = "Add Profile Photo",
    FailedToUpdatePhoto = "Failed to update photo",
}
