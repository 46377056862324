// We have to define all the type of meshes, geometries, materials, and helpers here
export const ShapeType = {
    Mesh: "Mesh",
    LineSegments: "LineSegments",
    Scene: "Scene",
    Geometry: {
        BoxGeometry: "BoxGeometry",
        EdgesGeometry: "EdgesGeometry",
        SphereGeometry: "SphereGeometry",
        CircleGeometry: "CircleGeometry",
        ConeGeometry: "ConeGeometry",
        CylinderGeometry: "CylinderGeometry",
        ExtrudeGeometry: "ExtrudeGeometry",
        PlaneGeometry: "PlaneGeometry",
    },
    Material: {
        MeshBasicMaterial: "MeshBasicMaterial",
    },
};

export enum typesOfShape {
    Cube = "Cube",
    CurvedCube = "Curved Cube",
    HalfCylinder = "Half Cylinder",
    Prism = "Prism",
    SquarePyramid = "Square Pyramid",
    Cone = "Cone",
    Cylinder = "Cylinder",
    HemiSphere = "Hemisphere",
    RightTriangle = "Right Triangle",
    ConcavePrism = "Concave Prism",
    InvalidShape = "Unknown shape type",
}
