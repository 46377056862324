import { NORMAL_EPSILON } from "./face-identifier.constants";

export class NormalUtils {
    static isExactNormal(value: number): boolean {
        return Math.abs(Math.abs(value) - 1) < NORMAL_EPSILON;
    }

    static isZero(value: number): boolean {
        return Math.abs(value) < NORMAL_EPSILON;
    }
}
