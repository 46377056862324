import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { PaytmCallbackData } from "src/models/payment.model";
import { transformPropsToLowerCase } from "src/utils/jsonObject.helper";
import { PaymentService } from "../payment/services/payment/payment.service";
import { AuthService } from "../services/auth/auth.service";
import { CartItemService } from "../services/cart-item/cart-item.service";
import { OrderService } from "../services/order/order.service";

@Component({
    selector: "order-success",
    templateUrl: "./order-success.component.html",
    styleUrls: ["./order-success.component.scss"],
})
export class OrderSuccessComponent implements OnInit {
    public callbackData!: PaytmCallbackData;
    public orderId!: number;

    constructor(
        private router: Router,
        private paymentService: PaymentService,
        private route: ActivatedRoute,
        private authService: AuthService,
        private cartService: CartItemService,
        private cdr: ChangeDetectorRef,
        private orderService: OrderService,
    ) {}

    ngOnInit(): void {
        this.callbackData = transformPropsToLowerCase(history.state);

        this.route.queryParams.subscribe((params) => {
            this.orderId = +params["orderId"];
        });
        this.RemoveAllCartItems(this.orderId);

        const merchantTransactionId = this.route.snapshot.paramMap.get("merchantTransactionId");

        if (merchantTransactionId) {
            this.paymentService.getPhonePePaymentStatus(merchantTransactionId).subscribe((response) => {
                this.callbackData.orderid = response.data!.orderId!.toString();
                this.orderId = +this.callbackData.orderid;
                this.RemoveAllCartItems(this.orderId);
            });
        }
    }

    public RemoveAllCartItems(orderId: number): void {
        this.orderService.checkIsFromCart(orderId).subscribe((response) => {
            if (response.isFromCart) {
                this.cartService.removeAllCartItems().subscribe();
            }
        });
    }

    public onTrackOrder(): void {
        this.router.navigate([`orderDetails/${this.orderId}`]);
    }

    public onContinueShopping(): void {
        this.router.navigate(["home"]);
    }
}
