export const AvailableShapes = {
    Cube: "Cube",
    CurvedCube: "CurvedCube",
    CurvedCube2: "CurvedCube2",
    HalfCylinder: "HalfCylinder",
    Prism: "Prism",
    Plane: "plane",
    SquarePyramid: "SquarePyramid",
    Cone: "Cone",
    Cylinder: "Cylinder",
    HemiSphere: "HemiSphere",
    Outline: "Outline",
    RightTriangle: "RightTriangle",
    Cuboid: "Cuboid",
    ConcavePrism: "ConcavePrism",
};

export function setFaceTypeForShape(shapeName: string): Record<string, string> {
    if (shapeName === AvailableShapes.CurvedCube) {
        return {
            Top: "Curved",
            Bottom: "Plane",
            Front: "Curved",
            Back: "Plane",
            Right: "Plane",
            Left: "Plane",
        };
    } else if (shapeName === AvailableShapes.CurvedCube2) {
        return {
            Top: "Curved",
            Bottom: "Plane",
            Front: "Plane",
            Back: "Plane",
            Right: "Plane",
            Left: "Plane",
        };
    } else if (shapeName === AvailableShapes.Cube) {
        return {
            Top: "Plane",
            Bottom: "Plane",
            Front: "Plane",
            Back: "Plane",
            Right: "Plane",
            Left: "Plane",
        };
    } else if (shapeName === AvailableShapes.HalfCylinder) {
        return {
            Top: "Curved",
            Bottom: "Plane",
            Front: "Curved",
            Back: "Curved",
            Right: "Plane",
            Left: "Plane",
        };
    } else if (shapeName === AvailableShapes.Prism) {
        return {
            Top: "Curved",
            Bottom: "Plane",
            Front: "Curved",
            Back: "Curved",
            Right: "Plane",
            Left: "Plane",
        };
    } else if (shapeName === AvailableShapes.SquarePyramid) {
        return {
            Top: "Curved",
            Bottom: "Plane",
            Front: "Curved",
            Back: "Curved",
            Right: "Curved",
            Left: "Curved",
        };
    } else if (shapeName === AvailableShapes.Cone) {
        return {
            Top: "Curved",
            Bottom: "Plane",
            Front: "Curved",
            Back: "Curved",
            Right: "Curved",
            Left: "Curved",
        };
    } else if (shapeName === AvailableShapes.Cylinder) {
        return {
            Top: "Plane",
            Bottom: "Plane",
            Front: "Curved",
            Back: "Curved",
            Right: "Curved",
            Left: "Curved",
        };
    } else if (shapeName === AvailableShapes.HemiSphere) {
        return {
            Top: "Curved",
            Bottom: "Plane",
            Front: "Curved",
            Back: "Curved",
            Right: "Curved",
            Left: "Curved",
        };
    } else if (shapeName === AvailableShapes.RightTriangle) {
        return {
            Top: "Curved",
            Bottom: "Plane",
            Front: "Curved",
            Back: "Plane",
            Right: "Plane",
            Left: "Plane",
        };
    } else if (shapeName === AvailableShapes.ConcavePrism) {
        return {
            Top: "Curved",
            Bottom: "Plane",
            Front: "Curved",
            Back: "Plane",
            Right: "Plane",
            Left: "Plane",
        };
    }
    if (shapeName === AvailableShapes.ConcavePrism) {
        return {
            Top: "Curved",
            Bottom: "Plane",
            Front: "Curved",
            Back: "Plane",
            Right: "Plane",
            Left: "Plane",
        };
    }
    return {};
}

export const tiltedShapeConfiguration = {
    offsetXforRightAndLeftFace: 18,
    rotationforRightAndLeftFace: 2.02,
    offsetX: 8.5,
    offsetY: 18,
    defaultOffsetY: 9,
};

export enum ValidFacesRange {
    HalfCylinderMinimum = 6,
    HalfCylinderMaximum = 11,
    HemiSphereMinimum = 6,
    HemiSphereMaximum = 7,
    CylinderMinimum = 4,
    CylinderMaximum = 7,
    CurvedCubeMinimum = 6,
    CurvedCubeMaximum = 11,
    CurvedCubeSecondRangeMin = 2,
    CurvedCubeSecondRangeMax = 3,
    SquarePyramidMinimum = 6,
    SquarePyramidMaximum = 7,
    ConeMinimum = 6,
    ConeMaximum = 7,
    PrismFirstRangeMin = 7,
    PrismFirstRangeMax = 11,
    PrismSecondRangeMin = 4,
    PrismSecondRangeMax = 5,
    CubeMinimum = 0,
    CubeMaximum = Infinity,
    RightTriangleFirstMinRange = 2,
    RightTriangleFirstMaxRange = 3,
    RightTriangleSecondMinRange = 6,
    RightTriangleSecondMaxRange = 11,
    ConcavePrismFirstMinRange = 2,
    ConcavePrismFirstMaxRange = 3,
    ConcavePrismSecondMinRange = 6,
    ConcavePrismSecondMaxRange = 7,
}

export enum FaceVertices {
    RightTriangleVerticePoint = 2.5,
    PrismVerticePointcX = 0,
    PrismVerticePointcY = 2.5,
    PrismVerticePointcZ = 2.25,
}

export enum RemoveNode {
    RightTriangleNodeX = 0,
    RightTriangleNodeY = 0,
    RightTriangleNodeZ = 2.55,
    RightTrianglePlaneX = 0.8333333333333333,
    RightTrianglePlaneY = 0.8333333333333333,
    RightTrianglePlaneZ = 2.5,
    PrismNodeX = 0,
    PrismNodeY = 2.6480761051177977,
    PrismNodeZ = 0,
    PrismPlaneX = 0,
    PrismPlaneY = 2.5,
    PrismPlaneZ = 0.75,

    ConcavePrismBackFaceNodeX = 2.55,
    ConcavePrismBackFaceNodeY = 0,
    ConcavePrismBackFaceNodeZ = 0,
    ConcavePrismBackFacePlaneX = 3.25,
    ConcavePrismBackFacePlaneY = 0,
    ConcavePrismBackFacePlaneZ = 0,

    ConcavePrismBottomFaceNodeX = 0,
    ConcavePrismBottomFaceNodeY = 2.55,
    ConcavePrismBottomFaceNodeZ = 0,
    ConcavePrismBottomFacePlaneX = 0,
    ConcavePrismBottomFacePlaneY = 3.25,
    ConcavePrismBottomFacePlaneZ = 0,
}
