export interface Permission {
    permission?: {
        role: string;
        RoleCategory: {
            name: string;
        };
    };
    createdAt?: Date;
    id: number;
    permissionId: number;
    updatedAt?: Date;
    userId: number;
}
export interface RoleCategory {
    id: number;
    name: string;
    createdAt?: Date;
    updatedAt?: Date;
    permission?: UserRoles[];
}
export interface UserRoles {
    id: number;
    role: string;
    categoryId: number;
    createdAt?: Date;
    updatedAt?: Date;
}

export enum UserPermissions {
    User = "Users",
    AddUser = "Add User",
    UserEdit = "User Edit",
    UserView = "User View",
    Product = "Products",
    AddProduct = "Add Product",
    ProductEdit = "Product Edit",
    ProductView = "Product View",
    ReviewPage = "Review",
    Review = "Reviews",
    OrderPage = "Order",
    Order = "Orders",
    StructureReview = "Structure",
    Structure = "Structures",
    CouponPage = "Coupon",
    Coupon = "Coupons",
    Advertisement = "Advertisements",
    AdvertisementPage = "Advertisement",
    FetchFail = "Failed to fetch the data",
}
