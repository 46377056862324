import { Injectable } from "@angular/core";
import * as THREE from "three";
import { ShapeGeneratorService } from "../services/shape-generator/shape-generator.service";
import { AvailableShapes, setFaceTypeForShape } from "src/utils/shape-facetype";
import { Subject } from "rxjs";
import { initialOrientation, Object3DUserData } from "~/src/utils/shape";
import { ActionHelperService } from "../action-helper.service";
import { FaceIdentifierService } from "./face-identifier/face-identifier.service";
import { ShapeValidationService } from "../services/shape-validation.service";
import { Face, FaceType } from "../../../../utils/count-calculator/count-calculator.model";
import { Intersection, Object3D } from "three";

@Injectable({
    providedIn: "root",
})
export class GenerateNewShapes {
    private isNewShapeGeneratedSubject = new Subject<boolean>();
    public isNewShapeGenerated$ = this.isNewShapeGeneratedSubject.asObservable();
    public intersect!: Intersection<Object3D<THREE.Event>>;
    private isCurvedFace = false;

    constructor(
        private shapeGeneratorService: ShapeGeneratorService,
        private faceIdentifierService: FaceIdentifierService,
        private shapeValidationService: ShapeValidationService,
    ) {}

    private markAsGenerated(): void {
        this.isNewShapeGeneratedSubject.next(true);
    }

    public generateNewShapes(position: THREE.Vector3, shape: string, color: THREE.Color): THREE.Mesh {
        let object: THREE.Mesh = new THREE.Mesh();
        let boundingBox = new THREE.Box3(); // To store the bounding box of the object

        // Generate shape based on the provided type
        switch (shape) {
            case AvailableShapes.Cube: //IF USER SELECTED SHAPE IS CUBE
                object = this.shapeGeneratorService.generateShape(AvailableShapes.Cube, color, false, position);
                object.name = AvailableShapes.Cube;
                break;
            case AvailableShapes.Cuboid: //IF USER HIT ON THE HYPOTENUOUS IT SHOULD COME CUBOID
                object = this.shapeGeneratorService.generateShape(AvailableShapes.Cuboid, color, false, position);
                object.name = AvailableShapes.Cuboid;
                break;
            case AvailableShapes.CurvedCube: // IF USER SELECTED SHAPE IS CURVED CUBE
                object = this.shapeGeneratorService.generateShape(AvailableShapes.CurvedCube, color, false, position);
                object.name = AvailableShapes.CurvedCube;
                break;
            case AvailableShapes.CurvedCube2: // IF USER SELECTED SHAPE IS CURVED CUBE 2
                object = this.shapeGeneratorService.generateShape(AvailableShapes.CurvedCube2, color, false, position);
                object.name = AvailableShapes.CurvedCube2;
                break;
            case AvailableShapes.HalfCylinder: //IF USER SELECTED SHAPE IS HALF CYLINDER
                object = this.shapeGeneratorService.generateShape(AvailableShapes.HalfCylinder, color, false, position);
                object.name = AvailableShapes.HalfCylinder;
                break;
            case AvailableShapes.RightTriangle: // IF USER SELECTED SHAPE IS RIGHT TRIANGLE
                object = this.shapeGeneratorService.generateShape(
                    AvailableShapes.RightTriangle,
                    color,
                    false,
                    position,
                );
                object.name = AvailableShapes.RightTriangle;
                break;
            case AvailableShapes.Prism: // IF USER SELECTED SHAPE IS PRISM
                object = this.shapeGeneratorService.generateShape(AvailableShapes.Prism, color, false, position);
                object.name = AvailableShapes.Prism;
                break;
            case AvailableShapes.SquarePyramid: // IF USER SELECTED SHAPE IS SYMMITRICAL PYRAMID
                object = this.shapeGeneratorService.generateShape(
                    AvailableShapes.SquarePyramid,
                    color,
                    false,
                    position,
                );
                object.name = AvailableShapes.SquarePyramid;
                break;
            case AvailableShapes.Cone: // IF USER SELECTED SHAPE IS CONE
                object = this.shapeGeneratorService.generateShape(AvailableShapes.Cone, color, false, position);
                object.name = AvailableShapes.Cone;
                break;
            case AvailableShapes.Cylinder: // IF USER SELECTED SHAPE IS CYLINDER
                object = this.shapeGeneratorService.generateShape(AvailableShapes.Cylinder, color, false, position);
                object.name = AvailableShapes.Cylinder;
                break;
            case AvailableShapes.HemiSphere: // IF USER SELECTED SHAPE IS HEMISPHERE
                object = this.shapeGeneratorService.generateShape(AvailableShapes.HemiSphere, color, false, position);
                object.name = AvailableShapes.HemiSphere;
                break;
            case AvailableShapes.ConcavePrism: // IF USER SELECTED SHAPE IS Curved Cube3
                object = this.shapeGeneratorService.generateShape(AvailableShapes.ConcavePrism, color, false, position);
                object.name = AvailableShapes.ConcavePrism;
                break;
        }

        // Get the bounding box of the object
        object.geometry.computeBoundingBox();
        boundingBox.setFromObject(object);

        // Get the center of the bounding box
        const boundingBoxCenter = boundingBox.getCenter(new THREE.Vector3());
        switch (shape) {
            case AvailableShapes.HalfCylinder:
                boundingBoxCenter.y = boundingBox.max.y;
                break;
            case AvailableShapes.HemiSphere:
                boundingBoxCenter.y = boundingBox.max.y;
                break;
            default:
                break;
        }

        // Calculate the offset to move the object such that its bounding box center aligns with the provided position
        const offset = new THREE.Vector3(
            position.x - boundingBoxCenter.x,
            position.y - boundingBoxCenter.y,
            position.z - boundingBoxCenter.z,
        );

        object.position.add(offset);

        // Mark the object's orientation data
        object.userData[Object3DUserData.orientationData] = initialOrientation;

        if (this.faceIdentifierService.state.frontFace) {
            this.isCurvedFace = this.checkCurvedFace(Face.Back, object);
        } else if (this.faceIdentifierService.state.rightFace) {
            this.isCurvedFace = this.checkCurvedFace(Face.Left, object);
        } else if (this.faceIdentifierService.state.backFace) {
            this.isCurvedFace = this.checkCurvedFace(Face.Front, object);
        } else if (this.faceIdentifierService.state.leftFace) {
            this.isCurvedFace = this.checkCurvedFace(Face.Right, object);
        } else if (this.faceIdentifierService.state.topFace) {
            this.isCurvedFace = this.checkCurvedFace(Face.Bottom, object);
        } else if (this.faceIdentifierService.state.bottomFace) {
            this.isCurvedFace = this.checkCurvedFace(Face.Top, object);
        }

        if (this.isCurvedFace) {
            this.checkValidation(object);
        }

        // Notify that the shape has been generated
        this.markAsGenerated();

        return object;
    }

    private checkCurvedFace(oppositeFace: Face, object: THREE.Mesh): boolean {
        const selectedShapeFaceType = setFaceTypeForShape(object.name);
        if (selectedShapeFaceType[oppositeFace] === FaceType.Curved) {
            return true;
        }
        return false;
    }

    public async checkValidation(object: THREE.Mesh): Promise<void> {
        await this.shapeValidationService.validateAndFlipSequentially(object);
    }
}
