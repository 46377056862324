import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { Coupon } from "@models/coupon.model";

@Injectable({
    providedIn: "root",
})
export class CouponsService {
    constructor(private http: HttpClient) {}
    public checkAndUseCoupon(
        couponCode: string,
        userId: string,
        productIds: number[],
    ): Observable<{
        used: boolean;
        maxAmount: number;
        discountPercentage: number;
        couponCode: string;
    }> {
        return this.http.post<{
            used: boolean;
            maxAmount: number;
            discountPercentage: number;
            couponCode: string;
        }>(`${environment.apiUrlBasePath}/coupons/checkCoupon`, { couponCode, userId, productIds });
    }

    public markCouponAsUsed(
        couponCode: string,
        userId: string,
    ): Observable<{
        used: boolean;
        maxAmount: number;
        discountPercentage: number;
        couponCode: string;
    }> {
        return this.http.post<{
            used: boolean;
            maxAmount: number;
            discountPercentage: number;
            couponCode: string;
        }>(`${environment.apiUrlBasePath}/coupons/markCouponAsUsed`, { couponCode, userId });
    }

    public getCoupons(
        pageIndex: number = environment.dynamicPagination.pageIndex,
        pageSize: number = environment.dynamicPagination.pageSize,
        mobileNumber: number,
    ): Observable<{ count: number; rows: Coupon[] }> {
        return this.http.get<{ count: number; rows: Coupon[] }>(
            `${environment.apiUrlBasePath}/coupons/get?pageIndex=${pageIndex}&pageSize=${pageSize}&userId=${mobileNumber}`,
        );
    }
}
