import { Component } from "@angular/core";
import { ComponentInteractionSrevice } from "src/app/services/component-interaction/component-interaction.service";
import { UpdateShapeColorService } from "src/app/services/update-shape-color/update-shape-color.service";
import { ColorPickerInputConfg } from "@utils/color-constants";
import { AvailableShapes } from "@utils/shape-facetype";
import { InteractionService } from "../../shared/helpers/interaction.service";
import { take } from "rxjs";

interface SeletorsConfigModel {
    inputType: string;
    name: string;
    value: string;
    accessbility: string;
}

@Component({
    selector: "colour-picker",
    templateUrl: "./colour-picker.component.html",
    styleUrls: ["./colour-picker.component.scss"],
})
export class ColourPickerComponent {
    public inputConfs: SeletorsConfigModel[] = [
        {
            inputType: ColorPickerInputConfg.inputType,
            name: ColorPickerInputConfg.name,
            accessbility: ColorPickerInputConfg.accessbilityDarkGreen,
            value: ColorPickerInputConfg.valueAttributeForDarkGreen,
        },
        {
            inputType: ColorPickerInputConfg.inputType,
            name: ColorPickerInputConfg.name,
            accessbility: ColorPickerInputConfg.accessbilityGreen,
            value: ColorPickerInputConfg.valueAttributeForGreen,
        },
        {
            inputType: ColorPickerInputConfg.inputType,
            name: ColorPickerInputConfg.name,
            accessbility: ColorPickerInputConfg.accessbilityYellow,
            value: ColorPickerInputConfg.valueAttributeForYellow,
        },
        {
            inputType: ColorPickerInputConfg.inputType,
            name: ColorPickerInputConfg.name,
            accessbility: ColorPickerInputConfg.accessbilityOlive,
            value: ColorPickerInputConfg.valueAttributeForOlive,
        },
        {
            inputType: ColorPickerInputConfg.inputType,
            name: ColorPickerInputConfg.name,
            accessbility: ColorPickerInputConfg.accessbilityOrange,
            value: ColorPickerInputConfg.valueAttributeForOrange,
        },
        {
            inputType: ColorPickerInputConfg.inputType,
            name: ColorPickerInputConfg.name,
            accessbility: ColorPickerInputConfg.accessbilityTeal,
            value: ColorPickerInputConfg.valueAttributeForTeal,
        },
        {
            inputType: ColorPickerInputConfg.inputType,
            name: ColorPickerInputConfg.name,
            accessbility: ColorPickerInputConfg.accessbilityBlue,
            value: ColorPickerInputConfg.valueAttributeForBlue,
        },
        {
            inputType: ColorPickerInputConfg.inputType,
            name: ColorPickerInputConfg.name,
            accessbility: ColorPickerInputConfg.accessbilityViolet,
            value: ColorPickerInputConfg.valueAttributeForViolet,
        },
        {
            inputType: ColorPickerInputConfg.inputType,
            name: ColorPickerInputConfg.name,
            accessbility: ColorPickerInputConfg.accessbilityWhite,
            value: ColorPickerInputConfg.valueAttributeForWhite,
        },
        {
            inputType: ColorPickerInputConfg.inputType,
            name: ColorPickerInputConfg.name,
            accessbility: ColorPickerInputConfg.accessbilityRed,
            value: ColorPickerInputConfg.valueAttributeForRed,
        },
        {
            inputType: ColorPickerInputConfg.inputType,
            name: ColorPickerInputConfg.name,
            accessbility: ColorPickerInputConfg.accessbilityDarkYellow,
            value: ColorPickerInputConfg.valueAttributeForDarkYellow,
        },
        {
            inputType: ColorPickerInputConfg.inputType,
            name: ColorPickerInputConfg.name,
            accessbility: ColorPickerInputConfg.accessbilityMagenta,
            value: ColorPickerInputConfg.valueAttributeForMagenta,
        },
        {
            inputType: ColorPickerInputConfg.inputType,
            name: ColorPickerInputConfg.name,
            accessbility: ColorPickerInputConfg.accessbilityGray,
            value: ColorPickerInputConfg.valueAttributeForGray,
        },
        {
            inputType: ColorPickerInputConfg.inputType,
            name: ColorPickerInputConfg.name,
            accessbility: ColorPickerInputConfg.accessbilityDim,
            value: ColorPickerInputConfg.valueAttributeForDim,
        },
        {
            inputType: ColorPickerInputConfg.inputType,
            name: ColorPickerInputConfg.name,
            accessbility: ColorPickerInputConfg.accessbilityDarkGrey,
            value: ColorPickerInputConfg.valueAttributeForDarkGrey,
        },
    ];

    constructor(
        private interactionSrv: ComponentInteractionSrevice,
        private updateShapeColorSrv: UpdateShapeColorService,
        private interactionService: InteractionService,
    ) {}

    ngOnInit(): void {}

    public changeColor(selectedColor: any) {
        let color = selectedColor.target?.value;
        this.interactionService.isSelected.pipe(take(1)).subscribe((res) => {
            if (!res) {
                this.interactionSrv.shapeColor(color);
            }
        });
        this.interactionSrv.updateSelectedColor(color);
        this.interactionService.applyUserSelectedColorToSelectedObjects();
        this.interactionSrv.getInterceptInfo2().subscribe((intersects) => {
            if (
                intersects[0].object.name !== AvailableShapes.Plane &&
                intersects[0].object.name !== AvailableShapes.Outline
            ) {
                this.updateShapeColorSrv.updateShapeColor(intersects[0]?.object, selectedColor);
            }
        });
    }
}
