import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AdminModel, LoginModel, PermissionModel, ResponseAdminEdit, ReviewModel } from "@models/admin.model";
import { AdvertisementCreationAttributes, AdvertisementInstance } from "@models/advertisement.model";
import { TableDataResponse } from "@models/data-grid.model";
import { Permission, RoleCategory } from "@models/permission.model";
import { NullableStructureInstance, StructureInstance, StructureUpdateModel } from "@models/structure.model";
import { BehaviorSubject, Observable, Subject, catchError, delay, map, of, tap } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: "root",
})
export class AdminService {
    public permissions: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
    public permissions$: Observable<string[]> = this.permissions.asObservable();
    public categories: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
    public category$: Observable<string[]> = this.categories.asObservable();
    private url = environment.apiUrlBasePath;

    constructor(private readonly http: HttpClient) {}

    public setPermissions(permissions: string[]) {
        this.permissions.next(permissions);
    }

    public roleCategory(rolecategories: string[]) {
        this.categories.next(rolecategories);
    }

    public addAdmin(data: AdminModel): Observable<string> {
        return this.http.post(`${environment.apiUrlBasePath}/admin/add`, data) as Observable<string>;
    }

    public updateAdminUser(id: number, body: AdminModel): Observable<ResponseAdminEdit> {
        return this.http.put<ResponseAdminEdit>(`${environment.apiUrlBasePath}/admin/updateUser/${id}`, body);
    }

    public resetPassword(params: { password: string; uuid: number }): Observable<string> {
        return this.http.post(`${environment.apiUrlBasePath}/admin/resetPassword`, params) as Observable<string>;
    }

    public getPermission(): Observable<RoleCategory[]> {
        return this.http.get(`${environment.apiUrlBasePath}/admin/permissions`) as Observable<RoleCategory[]>;
    }

    public isEmailExist(params: { email: string }): Observable<{ exist: boolean; active: boolean; message: string }> {
        return this.http.post<{ exist: boolean; active: boolean; message: string }>(
            `${environment.apiUrlBasePath}/admin/isEmailExist`,
            params,
        );
    }

    public getAllUsers(
        pageIndex: number = environment.dynamicPagination.pageIndex,
        pageSize: number = environment.dynamicPagination.pageSize,
        name?: string,
    ): Observable<TableDataResponse> {
        let url = "";
        if (name) {
            const encodedUserName = encodeURIComponent(name);
            url = `${environment.apiUrlBasePath}/admin/getAll?pageIndex=${pageIndex}&pageSize=${pageSize}&name=${encodedUserName}`;
        } else {
            url = `${environment.apiUrlBasePath}/admin/getAll?pageIndex=${pageIndex}&pageSize=${pageSize}`;
        }
        return this.http.get<TableDataResponse>(url);
    }

    public getAdminUserDetails(id: number): Observable<AdminModel> {
        return this.http.get<AdminModel>(`${environment.apiUrlBasePath}/admin/getUserDetails/${id}`);
    }

    public updateStatus(params: { id: number; status: boolean }): Observable<string> {
        return this.http.put<string>(`${environment.apiUrlBasePath}/admin/updateStatus`, params);
    }

    public forgotPassword(email: string): Observable<string> {
        return this.http.put<string>(`${environment.apiUrlBasePath}/admin/forgotPassword`, email);
    }

    public getReviews(
        pageIndex: number = environment.dynamicPagination.pageIndex,
        pageSize: number = environment.dynamicPagination.pageSize,
        status: string,
    ): Observable<TableDataResponse> {
        return this.http.get<TableDataResponse>(
            `${environment.apiUrlBasePath}/admin/getReviews?pageIndex=${pageIndex}&pageSize=${pageSize}&status=${status}`,
        );
    }

    public updateReviewStatus(params: {
        id: number;
        productId: number;
        status: string;
        comment?: string;
    }): Observable<string> {
        return this.http.put<string>(`${environment.apiUrlBasePath}/admin/updateReviewStatus`, params);
    }

    public login(data: LoginModel): Observable<string | boolean | { token: string; permissions: Permission[] }> {
        return this.http.post<string | boolean>(`${this.url}/admin/valid`, data).pipe(
            tap((response: string | boolean | { token: string; permissions: Permission[] }) => {
                if (response && typeof response === "object" && "token" in response) {
                    localStorage.setItem("adminToken", response.token);
                    this.setUserPermissions().subscribe();
                }
            }),
        );
    }

    public setUserPermissions(): Observable<string[]> {
        return this.getPermissions().pipe(
            map((response) => {
                const permissions = response
                    .map((item: Permission) => item?.permission?.role)
                    .filter((role: string | undefined) => !!role) as string[];

                const roleCategories = response
                    .map((item: Permission) => item?.permission?.RoleCategory.name)
                    .filter((name: string | undefined) => !!name) as string[];
                const uniqueRoleCategories = Array.from(new Set(roleCategories));

                this.roleCategory(uniqueRoleCategories);
                this.setPermissions(permissions);
                return permissions;
            }),
            catchError((error) => {
                return of([]); // Return an empty array or some default value in case of error
            }),
        );
    }

    public getPermissions(): Observable<Permission[]> {
        return this.http.get(`${environment.apiUrlBasePath}/admin/userPermissions`) as Observable<Permission[]>;
    }

    public getAllStructures(
        pageIndex: number = environment.dynamicPagination.pageIndex,
        pageSize: number = environment.dynamicPagination.pageSize,
        status?: string,
    ): Observable<TableDataResponse> {
        return this.http.get<TableDataResponse>(
            `${environment.apiUrlBasePath}/admin/allStructure?pageIndex=${pageIndex}&pageSize=${pageSize}&status=${status}`,
        );
    }

    public updateStructureData(structureId: number, structure: StructureUpdateModel): Observable<string> {
        return this.http.patch<string>(`${environment.apiUrlBasePath}/admin/${structureId}/update`, structure);
    }

    public getStructureDetails(id: number): Observable<{ structureData: NullableStructureInstance }> {
        return this.http.get<{ structureData: NullableStructureInstance }>(
            `${environment.apiUrlBasePath}/admin/getStructureDetails?id=${id}`,
        );
    }

    public addAdvertisements(adsBody: AdvertisementCreationAttributes[]): Observable<{ message: string }> {
        return this.http.post<{ message: string }>(`${environment.apiUrlBasePath}/admin/advertisements`, adsBody);
    }

    public updateAdvertisement(id: number, adsBody: AdvertisementCreationAttributes): Observable<{ message: string }> {
        return this.http.put<{ message: string }>(`${environment.apiUrlBasePath}/admin/advertisements/${id}`, adsBody);
    }

    public getAdvertisements(status: string, pageIndex: number, pageSize: number): Observable<TableDataResponse> {
        return this.http.get<TableDataResponse>(
            `${environment.apiUrlBasePath}/admin/advertisements?pageIndex=${pageIndex}&pageSize=${pageSize}&status=${status}`,
        );
    }

    public getAdvertisement(id: number): Observable<AdvertisementInstance> {
        return this.http.get<AdvertisementInstance>(`${environment.apiUrlBasePath}/admin/advertisements/${id}`);
    }

    public deleteAdvertisement(id: number): Observable<{ message: string }> {
        return this.http.delete<{ message: string }>(`${environment.apiUrlBasePath}/admin/advertisements/${id}`);
    }
}
