import {
    Component,
    OnInit,
    ElementRef,
    ViewChild,
    AfterViewInit,
    OnDestroy,
    OnChanges,
    SimpleChanges,
    Input,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatIcon, MatIconModule } from "@angular/material/icon";

@Component({
    selector: "app-viewer360",
    templateUrl: "./viewer360.component.html",
    styleUrls: ["./viewer360.component.scss"],
})
export class Viewer360Component implements AfterViewInit, OnDestroy, OnInit {
    @ViewChild("canvas") private canvasRef!: ElementRef;
    @Input() glbURL!: string;
    public isLoading = false;

    private scene!: THREE.Scene;
    private camera!: THREE.PerspectiveCamera;
    private renderer!: THREE.WebGLRenderer;
    private controls: any;
    private model: THREE.Group | undefined;
    private resizeListener: () => void; // Store the resize listener for later removal
    public glbUrl: string = "";

    constructor() {
        this.resizeListener = this.resizeCanvasToDisplaySize.bind(this); // Bind this to the method
    }
    ngOnInit(): void {}

    ngAfterViewInit(): void {
        this.initThreeJs();
    }

    ngOnDestroy(): void {
        window.removeEventListener("resize", this.resizeListener);
    }

    private initThreeJs(): void {
        if (!this.canvasRef) console.log("Canvas reference not found!");
        this.scene = new THREE.Scene();
        const container = this.canvasRef.nativeElement.parentElement;
        const containerWidth = container.clientWidth;
        const containerHeight = container.clientHeight;
        this.camera = new THREE.PerspectiveCamera(75, containerWidth / containerHeight, 0.1, 10000);
        this.camera.position.set(650, 650, 650);

        this.renderer = new THREE.WebGLRenderer({
            canvas: this.canvasRef.nativeElement,
            antialias: true,
        });
        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.setSize(containerWidth, containerHeight, false);
        this.renderer.setClearColor(0x000000, 0);
        this.isLoading = true;
        const loader = new GLTFLoader();

        loader.load(
            this.glbURL,
            (gltf) => {
                this.model = gltf.scene;
                this.scene.add(this.model);
                this.isLoading = false;
            },
            undefined,
            (error) => {
                console.error("Error loading .glb model", error);
                this.isLoading = false;
            },
        );
        this.controls = new OrbitControls(this.camera, this.renderer.domElement);
        this.controls.enableZoom = true;
        this.controls.maxDistance = 1600;
        this.animate();
    }

    private resizeCanvasToDisplaySize(): void {
        const container = this.canvasRef.nativeElement.parentElement;
        const width = container.clientWidth;
        const height = container.clientHeight;
        this.renderer.setSize(width, height, false);
        this.camera.aspect = width / height;
        this.camera.updateProjectionMatrix();
    }

    private animate(): void {
        requestAnimationFrame(() => this.animate());
        this.resizeCanvasToDisplaySize();
        this.controls.update();
        this.renderer.render(this.scene, this.camera);
    }
    zoomIn(): void {
        if (this.camera.fov > 10) {
            this.camera.fov -= 5;
            this.camera.updateProjectionMatrix();
        }
    }

    // Zoom Out function
    zoomOut(): void {
        if (this.camera.fov < 120) {
            this.camera.fov += 5;
            this.camera.updateProjectionMatrix();
        }
    }
}
