import { Component, EventEmitter, Input, Output } from "@angular/core";
import { AbstractControl, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { indianPincodeValidator } from "src/app/shared/indian-pincode-validator.directive";
import { nameValidator } from "src/app/shared/name-validator.directive";
import { AddressDetailsCreationAttributes } from "@models/user.model";
import { noSpacesValidator } from "../../shared/shared/no-leading-space-validator.directive";
import { debounceTime } from "rxjs";
import { indianPhoneNumberValidator } from "../../shared/phone-number-validator.directive";

@Component({
    selector: "app-address",
    templateUrl: "./address.component.html",
    styleUrls: ["./address.component.scss"],
})
export class AddressComponent {
    public addressForm!: FormGroup;
    // @Input() location!: AddressDetailsAttributes;
    @Output() addressData = new EventEmitter<AddressDetailsCreationAttributes>();
    @Input() selectedAddress!: AddressDetailsCreationAttributes;
    @Input() showNameAndMobile: boolean = false;
    @Input() isInDialog: boolean = false;

    constructor(private formBuilder: FormBuilder) {}

    ngOnInit(): void {
        this.addressForm = this.formBuilder.group({
            name: [
                { value: "", disabled: !this.showNameAndMobile },
                [Validators.required, nameValidator(), noSpacesValidator()],
            ],
            mobileNumber: [
                { value: "", disabled: !this.showNameAndMobile },
                [Validators.required, indianPhoneNumberValidator()],
            ],
            address: ["", [Validators.required, noSpacesValidator()]],
            houseNumber: ["", [Validators.required, noSpacesValidator()]],
            city: ["", [Validators.required, noSpacesValidator()]],
            state: ["", [Validators.required, noSpacesValidator()]],
            pincode: ["", [Validators.required, indianPincodeValidator()]],
            addressType: ["", Validators.required],
        });

        // this.addressForm.valueChanges.subscribe(data => {
        //   // if (this.addressForm.valid && this.location && this.location.gpsCoordinate) {
        //   //   const addressData = { ...data, gpsCoordinate: { latitude: this.location.gpsCoordinate.latitude, longitude: this.location.gpsCoordinate.longitude } };
        //   //   this.addressData.emit(addressData);
        //   // }

        //   if (this.addressForm.valid) {
        //     const addressData = { ...data, id: this.selectedAddress?.id };
        //     this.addressData.emit(addressData);
        //   }
        // });

        this.subscribeToFormChanges();

        if (this.addressForm) {
            this.patchForm();
        }
    }

    private subscribeToFormChanges(): void {
        this.addressForm.valueChanges.pipe(debounceTime(300)).subscribe((data) => {
            this.trimSpaces();

            if (this.addressForm.valid) {
                const addressData = { ...data, id: this.selectedAddress?.id };
                this.addressData.emit(addressData);
            }
        });
    }

    private trimSpaces(): void {
        const controlNames = ["name", "address", "houseNumber", "city", "state"];
        controlNames.forEach((controlName) => {
            const control = this.addressForm.get(controlName);
            if (control && control.value) {
                control.setValue(control.value.trim());
            }
        });
    }

    public onValidNumber(event: Event): void {
        const inputElement = event.target as HTMLInputElement;
        const value = inputElement.value;

        // Remove any non-numeric characters
        inputElement.value = value.replace(/[^0-9]/g, "");
    }

    // ngOnChanges(changes: SimpleChanges): void {
    //   if (this.addressForm) {
    //     this.patchForm();
    //   }
    // }

    private patchForm(): void {
        this.addressForm.patchValue({
            name: this.selectedAddress?.name,
            mobileNumber: this.selectedAddress?.mobileNumber,
            address: this.selectedAddress?.address,
            houseNumber: this.selectedAddress?.houseNumber,
            city: this.selectedAddress?.city,
            state: this.selectedAddress?.state,
            pincode: this.selectedAddress?.pincode,
            addressType: this.selectedAddress?.addressType,
        });
    }

    public isInvalid(controlName: string, validatorName?: string): boolean {
        const contorl = this.addressForm.get(controlName);
        if (validatorName) {
            return contorl!.errors?.[validatorName];
        }
        return contorl!.invalid && contorl!.touched;
    }

    // public receiveLocationData(location: AddressDetailsAttributes): void {
    //   this.location = location;
    // }
}
